<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">防疫预警配置</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="200px" class="demo-uForm">
                <el-form-item label="是否必须上传健康码"  prop="ISUPHEALTH">
                    <el-radio v-model="postInfo.ISUPHEALTH" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISUPHEALTH" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="未上传健康码时间（分钟）" prop="HEALTHDURA">
                    <el-input v-model="postInfo.HEALTHDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否必须上传核酸证明"  prop="ISUPPROVE">
                    <el-radio v-model="postInfo.ISUPPROVE" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISUPPROVE" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="未上传核酸证明时间（分钟）" prop="PROVEDURA">
                    <el-input v-model="postInfo.PROVEDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否必须上传行程码"  prop="ISUPTRIP">
                    <el-radio v-model="postInfo.ISUPTRIP" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISUPTRIP" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="未上传行程码时间（分钟）" prop="TRIPDURA">
                    <el-input v-model="postInfo.TRIPDURA" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

        </div>
    </div>
</template>
<script>
export default {
  name: "AW",
  data() {
    var ISUPHEALTH = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择是否必须上传健康码"));
      } else {
        callback();
      }
    };
    var ISUPPROVE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择是否必须上传核酸证明"));
      } else {
        callback();
      }
    };
    var ISUPTRIP = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择是否必须上传行程码"));
      } else {
        callback();
      }
    };

    var PROVEDURA = (rule, value, callback) => {
      if (value === "") {
            callback(new Error("请输入未上传核酸证明时间（分钟）"));
        }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('时间只能是数字'));
        }else if(value<0){
            callback(new Error('时间不能小于0分钟'));
        } else {
            callback();
      }
    };
    var TRIPDURA = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入未上传行程码时间（分钟）"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('时间只能是数字'));
        }else if(value<0){
            callback(new Error('时间不能小于0分钟'));
        }  else {
        callback();
      }
    };
    var HEALTHDURA = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入未上传健康码时间（分钟）"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('时间只能是数字'));
        }else if(value<0){
            callback(new Error('时间不能小于0分钟'));
        }  else {
        callback();
      }
    };

    return {
        canDo:true,
        
        doType:"",
        postInfo:{},
        postrules: {
            ISUPHEALTH: [{ validator: ISUPHEALTH, trigger: "blur" }],
            ISUPPROVE: [{ validator: ISUPPROVE, trigger: "blur" }],
            ISUPTRIP: [{ validator: ISUPTRIP, trigger: "blur" }],
            PROVEDURA: [{ validator: PROVEDURA, trigger: "blur" }],
            TRIPDURA: [{ validator: TRIPDURA, trigger: "blur" }],
            HEALTHDURA: [{ validator: HEALTHDURA, trigger: "blur" }],
        },

        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("VirConfig/GetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                if(response.data.DATA.length>0){
                    var  d=response.data.DATA[0]
                    this.postInfo={
                        SNOWID:d.SNOWID,
                        ISUPHEALTH:d.ISUPHEALTH.toString(),
                        ISUPPROVE:d.ISUPPROVE.toString(),
                        ISUPTRIP:d.ISUPTRIP.toString(),
                        PROVEDURA:d.PROVEDURA,
                        TRIPDURA:d.TRIPDURA,
                        HEALTHDURA:d.HEALTHDURA,
                    }
                    this.doType="2"
                } else{
                    this.doType="1"
                    this.postInfo={
                        ISUPHEALTH:"",
                        ISUPPROVE:"",
                        ISUPTRIP:"",
                        PROVEDURA:"",
                        TRIPDURA:"",
                        HEALTHDURA:"",
                    }
                }
            } 
            })
            .catch((error) => {
                this.hideLoading()
        });
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("VirConfig/Insert", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("VirConfig/Update", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>