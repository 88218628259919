<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">点位管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-select v-model="tid" placeholder="类型" filterable clearable class="iww">
                    <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="pid" placeholder="项目" filterable clearable class="iww">
                    <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="uid" placeholder="责任人" filterable clearable class="iww">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-button type="primary" @click="searchWF">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del" v-show="false">批量删除</el-button>
                <el-button type="success" @click="addWF" class="fr" v-if="role.add">添加</el-button>
                <el-button type="primary" @click="downloadTemplet" class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet" class="fr mr10" v-if="role.add">导入模板</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60" fixed></el-table-column>
                    <el-table-column prop="NAME" label="名称" width="200" fixed></el-table-column>
                    <el-table-column prop="CLEANOBJECTTYPE" label="类型" widht="60" fixed>
                        <template slot-scope="scope">
                            <span v-if="scope.row.CLEANOBJECTTYPE">{{ scope.row.CLEANOBJECTTYPE.NAME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CODE" label="层级码" widht="60" fixed></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目" widht="60" fixed></el-table-column>
                    <el-table-column prop="USERNAME" label="责任人" widht="60" fixed></el-table-column>
                    <el-table-column prop="ADDRESS" label="地址" width="200" fixed></el-table-column>
                    <el-table-column prop="BSM" label="图斑编号" width="80"></el-table-column>
                    <el-table-column prop="DLBM" label="地类编码" width="100"></el-table-column>
                    <el-table-column prop="DLMC" label="地类名称" width="100"></el-table-column>
                    <el-table-column prop="QSDWMC" label="权属单位名称" width="150"></el-table-column>
                    <el-table-column prop="ZLDWMC" label="坐落单位名称" width="150"></el-table-column>
                    <el-table-column prop="TBMJ" label="永久基本农田保护图斑面积" width="80"></el-table-column>
                    <el-table-column prop="ZZSXDM" label="种植属性代码" width="150"></el-table-column>
                    <el-table-column prop="ZZSXMC" label="种植属性名称" width="150"></el-table-column>
                    <el-table-column prop="AREA" label="耕地保护目标面积" widht="50"></el-table-column>
                    <el-table-column prop="LENGTH" label="长度" widht="50"></el-table-column>
                    <el-table-column prop="SCHECKTIMES" label="上午巡查次数"></el-table-column>
                    <el-table-column prop="ECHECKTIMES" label="下午巡查次数"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期" width="160"></el-table-column>
                    <el-table-column label="操作" width="130" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editWF(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">编辑</el-button>
                            <el-button @click="delWF(scope.row)" type="text" size="small"
                                style="color:#F56C6C;margin-right: 10px;" v-if="role.del">删除</el-button>
                            <!-- <el-button @click="baiduArea(scope.row)" type="text" size="small" style="color:#409EFF">百度地图区域</el-button> -->
                            <span v-if="role.edit"><el-button @click="tianArea(scope.row)" type="text" size="small"
                                    style="color:#409EFF"
                                    v-if="scope.row.CLEANOBJECTTYPE.ISHAVEZB == 1">区域</el-button></span>

                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" top="30px" :close-on-press-escape="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"
                        placeholder="村名+类型+图斑编号，如：光明村-XC1234"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="CLEANOBJECTTYPEID">
                    <el-select v-model="postInfo.CLEANOBJECTTYPEID" placeholder="类型" filterable style="width:100%">
                        <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所镇村">
                    <el-select v-model="sCode" placeholder="选择所" filterable style="width:32%;margin-right: 2%;"
                        @change="sChange">
                        <el-option v-for="item in sList" :key="item.CODE" :label="item.NAME" :value="item.CODE"></el-option>
                    </el-select>
                    <el-select v-model="zCode" placeholder="选择镇" filterable style="width:32%;margin-right: 2%;"
                        @change="zChange">
                        <el-option v-for="item in zList" :key="item.CODE" :label="item.NAME" :value="item.CODE"></el-option>
                    </el-select>
                    <el-select v-model="cCode" placeholder="选择村" filterable style="width:32%;">
                        <el-option v-for="item in cList" :key="item.CODE" :label="item.NAME" :value="item.CODE"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目" prop="PROJECTID">
                    <el-select v-model="postInfo.PROJECTID" placeholder="项目" filterable style="width:100%">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="责任人" prop="USERID">
                    <el-select v-model="postInfo.USERID" placeholder="责任人" filterable style="width:100%">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地址" prop="ADDRESS">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="耕地保护目标面积">
                    <el-input v-model="postInfo.AREA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="长度">
                    <el-input v-model="postInfo.LENGTH" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上午巡查次数" prop="SCHECKTIMES">
                    <el-input v-model.number="postInfo.SCHECKTIMES" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="下午巡查次数" prop="ECHECKTIMES">
                    <el-input v-model.number="postInfo.ECHECKTIMES" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <div style="overflow:hidden;margin-bottom:20px">
                        <el-input v-model="positionLon" autocomplete="off" placeholder="经度" style="width:44%;"></el-input>
                        <el-input v-model="positionLat" autocomplete="off" placeholder="纬度" style="width:44%;"
                            class="ml20"></el-input>
                        <el-button type="primary" @click="setPositionLonLat()" class="fr ml20">确定</el-button>
                    </div>
                    <TianMap class="map" ref="tmap" :width="mapWidth" :height="mapHeight" style="float:left" :move="false"
                        :draw="true" @mapFinish="tianmapFinishA" v-if="this.postModal"></TianMap>
                </el-form-item>
                <el-form-item label="图斑编号" prop="BSM">
                    <el-input v-model="postInfo.BSM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地类编码" prop="DLBM">
                    <el-input v-model="postInfo.DLBM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地类名称" prop="DLMC">
                    <el-input v-model="postInfo.DLMC" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权属单位名称" prop="QSDWMC">
                    <el-input v-model="postInfo.QSDWMC" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落单位名称" prop="ZLDWMC">
                    <el-input v-model="postInfo.ZLDWMC" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="永久基本农田保护图斑面积" prop="TBMJ">
                    <el-input v-model.number="postInfo.TBMJ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="种植属性" prop="ZZSXDM">
                    <el-select v-model="postInfo.ZZSXDM" placeholder="种植属性" filterable style="width:100%"
                        @change="zzsxChange">
                        <el-option v-for="item in zzsx" :key="item.code" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="种植属性代码" prop="ZZSXDM">
                    <el-input v-model="postInfo.ZZSXDM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="种植属性名称" prop="ZZSXMC">
                    <el-input v-model="postInfo.ZZSXMC" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="现场图片">
                    <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" multiple
                        accept="image/png,image/jpeg," :show-file-list="false" :http-request="uploadImgFun"
                        :before-upload="beforeAvatarUpload">
                        <el-button type="primary" size="mini">点击添加</el-button>
                    </el-upload>
                    <div class="imgFileList">
                        <div v-for="(item, index) in imgFiles" :key="item.url">
                            <div class="imgItem" v-if="item.show">
                                <el-image :src="item.url" :preview-src-list="[item.url]"
                                    style="width: 100px; height: 100px"></el-image>
                                <img src="../../../public/statics/mobile/img/delIcon.png" class="delIcon"
                                    @click="delIcon(index)">
                            </div>
                        </div>

                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button @click="cancelWfForm()" class="fr ml20">取消</el-button>
                    <el-button type="primary" @click="submitWFForm()" class="fr ml20">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="百度地图区域" :visible.sync="baidumapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <BaiDuMap class="map" ref="baidumap" :width="mapWidth" :height="mapHeight" @mapFinish="baidumapFinish"
                @setPolygonCount="setBDPolygonCount"></BaiDuMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startBDDraw()" class="fl mr20">绘制区域</el-button>
                <el-button type="danger" @click="delBDDraw()" class="fl mr20">删除区域</el-button>
                <!-- <el-button type="danger" @click="clearDraw()" class="fl mr20">清空绘制</el-button> -->
                <el-button @click="baidumapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitBaiduForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="天地图区域" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"
                :move="false" :draw="true" v-if="this.tianmapModal"></TianMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startTDraw()" class="fl">绘制区域</el-button>
                <el-button type="danger" @click="delTDraw()" class="fl mr20">删除区域</el-button>
                <el-input placeholder="请在此处粘贴坐标字符串" autocomplete="off" v-model="polygonStr" style="width:620px;"
                    class="fl mr20" @keyup.enter.native="setMapPolygon()"><i slot="suffix"
                        class="el-input__icon el-icon-finished" style="cursor: pointer;"
                        @click="setMapPolygon()"></i></el-input>
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitTianForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px" :append-to-body="true">
            <el-upload ref="upload" class="upload-demo" drag :multiple="false" :limit="1" accept=".xls,.xlsx" action=""
                :http-request="uploadFun">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
var pattern = /^[1-9][0-9]*$/
var h = document.body.clientHeight
import { chownSync } from "fs";
import BaiDuMap from "../../components/BaiDuMap"
import TianMap from "../../components/OpenLayers"
export default {
    name: "WindFieldManage",
    components: {
        BaiDuMap,
        TianMap,
    },
    data() {
        var NAME = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        // var AREA=(rule, value, callback) => {
        //     if(typeof value != 'number' && isNaN(value)){
        //          callback(new Error('面积只能是数字'));
        //     }else if(value<=0){
        //         callback(new Error('面积必须大于0'));
        //     }else {
        //       callback();
        //     }
        // };
        var ADDRESS = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地址"));
            } else {
                callback();
            }
        };
        // var LENGTH=(rule, value, callback) => {
        //   if(typeof value != 'number' && isNaN(value)){
        //          callback(new Error('长度只能是数字'));
        //     }else if(value<=0){
        //         callback(new Error('长度必须大于0'));
        //     }else {
        //       callback();
        //     }
        // };
        var CLEANOBJECTTYPEID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择类型"));
            } else {
                callback();
            }
        };
        var PROJECTID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择项目"));
            } else {
                callback();
            }
        };
        var USERID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择责任人"));
            } else {
                callback();
            }
        };
        var BAIDULAT = (rule, value, callback) => {
            if (!this.marker) {
                callback(new Error("请添加标记然后将标记拖拽至巡查对象位置"));
            } else {
                callback();
            }
        };
        var SCHECKTIMES = (rule, value, callback) => {
            if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('上午巡查次数只能是数字'));
            } else if (!/^\+?(0|[1-9][0-9]*)$/.test(value)) {
                callback(new Error('只能输入大于等于0的整数'));
            } else {
                callback();
            }
        };
        var ECHECKTIMES = (rule, value, callback) => {
            if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('下午巡查次数只能是数字'));
            } else if (!/^\+?(0|[1-9][0-9]*)$/.test(value)) {
                callback(new Error('只能输入大于等于0的整数'));
            } else {
                callback();
            }
        };
        var BSM = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入图斑编号"));
            }else if (!/^[0-9]{4}/.test(value)) {
                callback(new Error("图斑编号只能四位数字"));
            } else {
                callback();
            }
        };
        var DLBM = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地类编码"));
            } else {
                callback();
            }
        };
        var DLMC = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地类名称"));
            } else {
                callback();
            }
        };
        var QSDWMC = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入权属单位名称"));
            } else {
                callback();
            }
        };
        var ZLDWMC = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入坐落单位名称"));
            } else {
                callback();
            }
        };
        var TBMJ = (rule, value, callback) => {
            if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('图斑面积只能是数字'));
            } else {
                callback();
            }
        };
        var ZZSXDM = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择种植属性代码"));
            } else {
                callback();
            }
        };
        var ZZSXMC = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入种植属性名称"));
            } else {
                callback();
            }
        };
        return {
            szc: [],

            sList: [],
            sCode: "",
            zList: [],
            zCode: "",
            cList: [],
            cCode: "",


            projectList: [],
            pid: "",
            typeList: [],
            tid: "",
            userList: "",
            uid: "",

            canDo: true,

            name: "",


            showPagination: false,

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                NAME: [{ validator: NAME, trigger: "blur" }],
                ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
                // AREA: [{ validator: AREA, trigger: "blur" }],
                // LENGTH: [{ validator: LENGTH, trigger: "blur" }],
                CLEANOBJECTTYPEID: [{ validator: CLEANOBJECTTYPEID, trigger: "blur" }],
                PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
                USERID: [{ validator: USERID, trigger: "blur" }],
                BAIDULAT: [{ validator: BAIDULAT, trigger: "blur" }],
                SCHECKTIMES: [{ validator: SCHECKTIMES, trigger: "blur" }],
                ECHECKTIMES: [{ validator: ECHECKTIMES, trigger: "blur" }],
                BSM: [{ validator: BSM, trigger: "blur" }],
                DLBM: [{ validator: DLBM, trigger: "blur" }],
                DLMC: [{ validator: DLMC, trigger: "blur" }],
                QSDWMC: [{ validator: QSDWMC, trigger: "blur" }],
                ZLDWMC: [{ validator: ZLDWMC, trigger: "blur" }],
                TBMJ: [{ validator: TBMJ, trigger: "blur" }],
                ZZSXDM: [{ validator: ZZSXDM, trigger: "blur" }],
                ZZSXMC: [{ validator: ZZSXMC, trigger: "blur" }],
            },

            zzsx: [
                { name: '种植粮食作物', code: "LS" },
                { name: '种植非粮食作物', code: "FLS" },
                { name: '未耕种', code: "WG" },
                { name: '种植粮食作物与非粮食作物', code: "LYFL" },
            ],

            //地图
            cleanItem: null,
            mapWidth: 1060,
            mapHeight: 600,
            polygonStr: "",
            //百度
            baidumapModal: false,
            baidumap: null,
            BDPOLYGON: 0,
            baidumarker: null,


            //天地图
            tianmapModal: false,
            tianmap: null,
            TPOLYGON: 0,
            tianmarker: null,
            role: { add: false, edit: false, del: false },
            chooseFileModal: false,

            positionLon: 0,
            positionLat: 0,

            //图片
            imgFiles: [],
            doImgCount: 0,
            okImgIndex: 0,

            allIds: [],
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PAGEINDEX
            this.name = getQueryData.NAME
            this.pid = getQueryData.PROJECTID
            this.tid = getQueryData.CLEANOBJECTTYPEID
            this.uid = getQueryData.USERID
        }
        this.showPagination = true
        this.loadItem = null
        this.getCodeList()
        this.getPostList()
        this.getProjectList()
        this.getUser()
        this.getTypeList()
        this.coolLeftHeight = h - 60
        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {
        zzsxChange(e) {
            if (e == "") {
                this.postInfo.ZZSXMC = ""
            } else {
                for (var i = 0; i < this.zzsx.length; i++) {
                    if (this.zzsx[i].code == e) {
                        this.postInfo.ZZSXMC = this.zzsx[i].name
                        break;
                    }
                }
            }
        },
        //获取层级码列表
        getCodeList() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Region/GetRegionList", md5_data)
                .then((response) => {
                    //console.log(response)
                    if (response.data.SUCCESS) {
                        this.szc = response.data.DATA
                    }
                })
        },
        initCodeList(code) {
            var list = this.szc
            var slist = []
            var scode = ""
            var zlist = []
            var zcode = ""
            var clist = []
            var ccode = ""
            if (code != undefined && code != null && code != "") {
                scode = code.substring(0, 1)
                zcode = code.substring(0, 4)
                ccode = code
                for (var x = 0; x < list.length; x++) {
                    var xd = list[x]
                    if (xd.CODE.length == 1) {
                        slist.push(xd)
                    } else if (xd.CODE.length == 4 && xd.CODE.substring(0, 1) == scode) {
                        zlist.push(xd)
                    } else if (xd.CODE.length == 7 && xd.CODE.substring(0, 4) == zcode) {
                        clist.push(xd)
                    }
                }
            } else {
                for (var i = 0; i < list.length; i++) {
                    var a = list[i]
                    if (a.CODE.length == 1) {
                        slist.push(a)
                        if (scode == "") {
                            scode = a.CODE
                        }
                    }
                }
                if (scode != "") {
                    for (var j = 0; j < list.length; j++) {
                        var b = list[j]
                        var bks = b.CODE.substring(0, 1)
                        if (b.CODE.length == 4 && bks == scode) {
                            zlist.push(b)
                            if (zcode == "") {
                                zcode = b.CODE
                            }
                        }
                    }
                }
                if (zcode != "") {
                    for (var k = 0; k < list.length; k++) {
                        var c = list[k]
                        var cks = c.CODE.substring(0, 4)
                        if (c.CODE.length == 7 && cks == zcode) {
                            clist.push(c)
                            if (ccode == "") {
                                ccode = c.CODE
                            }
                        }
                    }
                }

            }

            this.sList = slist
            this.sCode = scode
            this.zList = zlist
            this.zCode = zcode
            this.cList = clist
            this.cCode = ccode
            this.setProjectByCode()
        },
        sChange(e) {
            var list = this.szc
            var zlist = []
            var zcode = ""
            var clist = []
            var ccode = ""
            for (var j = 0; j < list.length; j++) {
                var b = list[j]
                var bks = b.CODE.substring(0, 1)
                if (b.CODE.length == 4 && bks == e) {
                    zlist.push(b)
                    if (zcode == "") {
                        zcode = b.CODE
                    }
                }
            }
            if (zcode != "") {
                for (var k = 0; k < list.length; k++) {
                    var c = list[k]
                    var cks = c.CODE.substring(0, 4)
                    if (c.CODE.length == 7 && cks == zcode) {
                        clist.push(c)
                        if (ccode == "") {
                            ccode = c.CODE
                        }
                    }
                }
            }
            this.zList = zlist
            this.zCode = zcode
            this.cList = clist
            this.cCode = ccode
            this.setProjectByCode()
        },
        zChange(e) {
            var list = this.szc
            var clist = []
            var ccode = ""
            for (var k = 0; k < list.length; k++) {
                var c = list[k]
                var cks = c.CODE.substring(0, 4)
                if (c.CODE.length == 7 && cks == e) {
                    clist.push(c)
                    if (ccode == "") {
                        ccode = c.CODE
                    }
                }
            }
            this.cList = clist
            this.cCode = ccode
        },
        setProjectByCode() {
            var s = this.sCode
            var plist = this.projectList
            for (var i = 0; i < plist.length; i++) {
                if (plist[i].CODE && plist[i].CODE == s) {
                    this.postInfo.PROJECTID = plist[i].SNOWID
                    break;
                }
            }
        },

        baidumapFinish() {
            this.baidumap = this.$refs.baidumap.getMap()
        },
        tianmapFinishA() {
            this.tianmap = this.$refs.tmap.map
        },
        tianmapFinish() {
            this.tianmap = this.$refs.tianmap.map
        },
        getProjectList() {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    //console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].PARENTID == '0') {
                                this.projectList.push(list[i])
                            }
                        }
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.canDo = true
                });
        },
        getTypeList() {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("CleanObjectType/GetList", md5_data)
                .then((response) => {
                    //console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        this.typeList = response.data.DATA
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.canDo = true
                });
        },
        getUser() {
            var md5_data = this.comjs.requestDataEncrypt({ ISQUIT: "0" }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ ISQUIT: "0" })).toUpperCase())
            this.$http.post("User/GetUserList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.userList = response.data.DATA
                    }
                })
        },
        mapFinish() {
            this.map = this.$refs.map.getMap()
        },
        //获取巡查对象列表
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
                PROJECTID: this.pid,
                CLEANOBJECTTYPEID: this.tid,
                USERID: this.uid,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanObject/GetCleanObjectList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        //搜索项目
        searchWF() {
            this.PageIndex = 1
            this.getPostList()
        },

        //点击添加
        addWF() {
            this.tianmap=null
            this.initCodeList("")
            this.postInfo = {
                NAME: "",
                AREA: "",
                ADDRESS: "",
                LENGTH: "",
                USERID: "",
                BAIDULON: 0,
                BAIDULAT: 0,
                LON: 0,
                LAT: 0,
                CLEANOBJECTTYPEID: "",
                PROJECTID: "",
                SCHECKTIMES: "",
                ECHECKTIMES: "",
                TYPE: "0",
                ZB: "",
                ZBBAIDU: "",
                BSM: '',
                DLBM: '',
                DLMC: '',
                QSDWMC: '',
                ZLDWMC: '',
                TBMJ: '',
                ZZSXDM: '',
                ZZSXMC: '',
                CODE: "",
            }
            this.doTypeStr = "添加点位"
            this.doType = "1"
            this.postModal = true

            this.imgFiles = []
            this.doImgCount = 0
            this.okImgIndex = 0
            setTimeout(() => {
                if (this.tianmap) {
                    this.$refs.tmap.clearDraw()
                }
                var t = this.$refs.tmap.getMapTarget()
                var c = t.view.getCenter()
                this.postInfo.LON = c[0]
                this.postInfo.LAT = c[1]
                this.positionLon = c[0]
                this.positionLat = c[1]
                this.initMarker(c)
            }, 500)
        },
        //执行添加项目
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("CleanObject/InsertCleanObject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.setFileImgs(response.data.DATA)
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        //点击编辑巡查对象
        editWF(item) {
            this.tianmap=null
            var uid = ""
            if (item.USERID) {
                uid = item.USERID
            }
            this.initCodeList(item.CODE)
            this.imgFiles = []
            this.doImgCount = 0
            this.okImgIndex = 0
            this.postInfo = {
                CLEANOBJECTSNOWID: item.SNOWID,
                NAME: item.NAME,
                AREA: item.AREA,
                ADDRESS: item.ADDRESS,
                LENGTH: item.LENGTH,
                USERID: uid,
                BAIDULAT: item.BAIDULAT,
                BAIDULON: item.BAIDULON,
                LON: 0,
                LAT: 0,
                CLEANOBJECTTYPEID: item.CLEANOBJECTTYPEID,
                PROJECTID: item.PROJECTID,
                SCHECKTIMES: item.SCHECKTIMES,
                ECHECKTIMES: item.ECHECKTIMES,
                BSM: item.BSM,
                DLBM: item.DLBM,
                DLMC: item.DLMC,
                QSDWMC: item.QSDWMC,
                ZLDWMC: item.ZLDWMC,
                TBMJ: item.TBMJ,
                ZZSXDM: item.ZZSXDM,
                ZZSXMC: item.ZZSXMC,
                TYPE: "0",
                CODE: item.CODE,
                CODES: item.CODES,
                CODEXZ: item.CODEXZ,
                ZB:null,
            }
            this.getItemZB(item.SNOWID)
            for (var i = 0; i < item.PHOTOLIST.length; i++) {
                this.imgFiles.push({
                    url: item.PHOTOLIST[i].PATH, file: null, id: item.PHOTOLIST[i].SNOWID, show: true
                })
            }
            this.doTypeStr = "编辑点位"
            this.doType = "2"
            this.postModal = true
            setTimeout(() => {
                if (this.tianmap) {
                    this.$refs.tmap.clearDraw()
                }
                var c = []
                var t = this.$refs.tmap.getMapTarget()
                if (item.LON != 0 && item.LAT != 0) {
                    c = [item.LON, item.LAT]
                    t.view.setCenter(c)
                } else {
                    c = t.view.getCenter()
                }
                this.postInfo.LON = c[0]
                this.postInfo.LAT = c[1]
                this.positionLon = c[0]
                this.positionLat = c[1]
                this.initMarker(c)
            }, 500)
        },
      getItemZB(id) {
        this.canDo = false
        var md5_data = this.comjs.requestDataEncrypt({ CLEANOBJECTSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ CLEANOBJECTSNOWID: id })).toUpperCase())
        this.$http.post("CleanObject/GetCleanObjectZBByCleanObjectID", md5_data)
            .then((response) => {
              this.canDo = true
              this.hideLoading()
              if (response.data.SUCCESS && response.data.DATA) {
                this.postInfo.ZB=response.data.DATA.ZBSTR
              }
            })
      },
        //执行编辑巡查对象请求
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            console.log(this.postInfo)
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("CleanObject/UpdateCleanObject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("编辑成功")
                        this.postModal = false
                        this.baidumapModal = false
                        this.tianmapModal = false
                        this.setFileImgs(this.postInfo.CLEANOBJECTSNOWID)
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //点击删除项目
        delWF(item) {
            if (this.canDo) {
                this.$confirm('确定删除点位对象：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已删除")
                });
            }

        },
        //执行删除项目请求
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt({ CLEANOBJECTSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ CLEANOBJECTSNOWID: id })).toUpperCase())
            this.$http.post("CleanObject/DeleteCleanObject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //页码切换
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        //确定提交项目
        submitWFForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        this.postInfo.AREA = parseFloat(this.postInfo.AREA)
                        this.postInfo.LENGTH = parseFloat(this.postInfo.LENGTH)
                        this.postInfo.SCHECKTIMES = parseInt(this.postInfo.SCHECKTIMES)
                        this.postInfo.ECHECKTIMES = parseInt(this.postInfo.ECHECKTIMES)
                        this.postInfo.CODE = this.cCode
                        this.postInfo.CODEXZ = this.zCode
                        this.postInfo.CODES = this.sCode
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }

                    } else {
                        return false;
                    }
                });
            }
        },

        initMarker(point) {
            this.$refs.tmap.appendDrawPoint(point, true, this.sendChangePoint)
        },
        sendChangePoint(point) {
            this.postInfo.LON = point[0]
            this.postInfo.LAT = point[1]
            this.positionLon = point[0]
            this.positionLat = point[1]
        },

        setPositionLonLat() {

            var lon = /^-?((0|[1-9]\d?|1[1-7]\d)(\.\d{1,20})?|180(\.0{1,20})?)?$/;
            var lat = /^-?((0|[1-8]\d|)(\.\d{1,20})?|90(\.0{1,20})?)?$/;
            var lonRe = new RegExp(lon);
            var latRe = new RegExp(lat);

            var llon = parseFloat(this.positionLon)
            var llat = parseFloat(this.positionLat)
            if (lonRe.test(llon) && latRe.test(llat)) {
                this.postInfo.LON = llon
                this.postInfo.LAT = llat
                var t = this.$refs.tmap.getMapTarget()
                var c = [llon, llat]
                t.view.setCenter(c)
                this.$refs.tmap.clearDraw()
                this.initMarker(c)
            } else {
                this.$message.error("经纬度格式不正确")
            }
        },


        //区域
        getProjectZB(id, type) {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({ CLEANOBJECTSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ CLEANOBJECTSNOWID: id })).toUpperCase())
            this.$http.post("CleanObject/GetCleanObjectZBByCleanObjectID", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS && response.data.DATA) {
                        var d = response.data.DATA
                        if (d) {
                            if (type == "1" && d.ZBSTRBAIDU && d.ZBSTRBAIDU != "") {
                                this.setProjectBDZB(d.ZBSTRBAIDU)
                                var center = new window.BMap.Point(this.postInfo.BAIDULON, this.postInfo.BAIDULAT);
                                this.baidumap.panTo(center)
                            } else if (type == "2" && d.ZBSTR && d.ZBSTR != "") {
                                this.setProjectTDZB(d.ZBSTR)
                                this.$refs.tianmap.moveTo([this.postInfo.LON, this.postInfo.LAT])
                            }
                            this.postInfo.ZB = d.ZBSTR
                            this.postInfo.ZBBAIDU = d.ZBSTRBAIDU
                        }
                    }
                })
                .catch((error) => {
                  console.log(error)
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        /**
         * 百度地图区域
         */
        baiduArea(item) {
            this.postInfo = {
                CLEANOBJECTSNOWID: item.SNOWID,
                NAME: item.NAME,
                AREA: item.AREA,
                ADDRESS: item.ADDRESS,
                LENGTH: item.LENGTH,
                USERID: item.USERID,
                BAIDULAT: item.BAIDULAT,
                BAIDULON: item.BAIDULON,
                LON: item.LON,
                LAT: item.LAT,
                CLEANOBJECTTYPEID: item.CLEANOBJECTTYPEID,
                PROJECTID: item.PROJECTID,
                SCHECKTIMES: item.SCHECKTIMES,
                ECHECKTIMES: item.ECHECKTIMES,
                TYPE: "0",
                ZB: "",
                ZBBAIDU: "",
            }
            this.baidumapModal = true
            this.BDPOLYGON = 0
            if (this.baidumap) {
                this.$refs.baidumap.clearDraw()
            }

            setTimeout(() => {
                this.getProjectZB(item.SNOWID, "1")
            }, 500)

        },
        setProjectBDZB(zb) {
            var zbItem = this.$refs.baidumap.getBDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.baidumap.appendPolygon(zbItem.arr[i], oids)
                    if (i == 0) {
                        //this.baidumap.panTo(zbItem.arr[i][0])
                    }
                }
            } else {
                var oid = new Date().getTime().toString()
                this.$refs.baidumap.appendPolygon(zbItem.arr, oid)
                // this.baidumap.panTo(zbItem.arr[0])
            }
        },
        //开始绘制
        startBDDraw() {
            this.$refs.baidumap.openDraw()
        },
        setBDPolygonCount(num) {
            this.BDPOLYGON = num
        },
        //删除绘制
        delBDDraw() {
            var ps = this.$refs.baidumap.getCheckPolygons()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for (var i = 0; i < ps.length; i++) {
                        this.$refs.baidumap.removeDrawPolygon(ps[i])
                    }
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }
        },
        submitBaiduForm() {
            if (this.canDo) {
                if (this.BDPOLYGON > 0) {
                    this.postInfo.ZBBAIDU = this.getBDDrawPolygonsWKTStr()
                    var center = this.getBDDrawPolyginsCenter()
                    this.postInfo.BAIDULON = center[0]
                    this.postInfo.BAIDULAT = center[1]
                    console.log(this.postInfo)
                    this.sendEdit()
                } else {
                    this.$message.error("请先绘制区域")
                }
            }
        },
        getBDDrawPolygonsWKTStr() {
            var list = this.$refs.baidumap.getDrawPolygons()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getPath()

                    var str = this.$refs.baidumap.getWKTByBDPolygon(path)
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },
        getBDDrawPolyginsCenter() {
            var list = this.$refs.baidumap.getDrawPolygons()
            var litem = list[0].getPath()
            var p = this.$refs.baidumap.getTurfPolygonByBD(litem)
            var center = this.$refs.baidumap.getPolygonCenter(p)
            return center.geometry.coordinates
        },



        /**
     * 天地图
     */
        tianArea(item) {
            this.tianmap=null
            this.postInfo = {
                CLEANOBJECTSNOWID: item.SNOWID,
                NAME: item.NAME,
                AREA: item.AREA,
                ADDRESS: item.ADDRESS,
                LENGTH: item.LENGTH,
                USERID: item.USERID,
                BAIDULAT: item.BAIDULAT,
                BAIDULON: item.BAIDULON,
                LON: item.LON,
                LAT: item.LAT,
                CLEANOBJECTTYPEID: item.CLEANOBJECTTYPEID,
                PROJECTID: item.PROJECTID,
                SCHECKTIMES: item.SCHECKTIMES,
                ECHECKTIMES: item.ECHECKTIMES,
                BSM: item.BSM,
                DLBM: item.DLBM,
                DLMC: item.DLMC,
                QSDWMC: item.QSDWMC,
                ZLDWMC: item.ZLDWMC,
                TBMJ: item.TBMJ,
                ZZSXDM: item.ZZSXDM,
                ZZSXMC: item.ZZSXMC,
                CODE: item.CODE,
                CODES: item.CODES,
                CODEXZ: item.CODEXZ,
                TYPE: "0",
                ZB: "",
                ZBBAIDU: "",
            }
            this.tianmapModal = true
            this.polygonStr = ""


            setTimeout(() => {
                //console.log(this.tianmap)
                if (this.tianmap) {
                    this.$refs.tianmap.clearDraw()
                    var c = []
                    var t = this.$refs.tianmap.getMapTarget()
                    if (item.LON != 0 && item.LAT != 0) {
                        c = [item.LON, item.LAT]
                        t.view.setCenter(c)
                    }
                }
                this.getProjectZB(item.SNOWID, "2")
            }, 500)

        },
        setProjectTDZB(zb) {
            if (zb.indexOf("MULTIPOLYGON") != -1 || zb.indexOf("POLYGON") != -1) {
                var zbItem = this.$refs.tianmap.getTDPathByWKTStr(zb)
                console.log(zbItem)
                if (zbItem.isMulti) {
                    for (var i = 0; i < zbItem.arr.length; i++) {
                        var oids = new Date().getTime().toString() + i
                        this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                        // if (i == 0) {
                        //     var t = this.$refs.tianmap.getMapTarget()
                        //     t.view.setCenter(zbItem.arr[i][zbItem.arr[i].length-2])
                        // }
                    }
                } else {
                    var oid = new Date().getTime().toString()
                    var paths=[]
                    if(zbItem.isGroup){
                        paths = zbItem.arr
                    }else{
                        paths = [zbItem.arr]
                    }
                    this.$refs.tianmap.appendFeature('Polygon', oid,paths , false, null)
                    // this.tianmap.moveTo(zbItem.arr[0])
                }
            }
        },
        startTDraw() {
            this.$refs.tianmap.drawPolygon(null, null)
        },
        setMapPolygon() {
            var str = this.polygonStr
            if (str.indexOf("MULTIPOLYGON") != -1 || str.indexOf("POLYGON") != -1) {
                var zbItem = this.$refs.tianmap.getTDPathByWKTStr(this.polygonStr)
                if (zbItem.arr.length > 0) {
                    this.$confirm('本次操作会覆盖原先的坐标区域，确定继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.tianmap.clearDraw()
                        if (zbItem.isMulti) {
                            for (var i = 0; i < zbItem.arr.length; i++) {
                                var oids = new Date().getTime().toString() + i
                                this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                                if (i == 0) {
                                    this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                                }
                            }
                        } else {
                            var oid = new Date().getTime().toString()
                            this.$refs.tianmap.appendFeature('Polygon', oid, [zbItem.arr], false, null)
                            this.$refs.tianmap.moveTo(zbItem.arr[0])
                        }
                    })
                } else {
                    this.$message.error("请输入正确的坐标字符串")
                }
            } else {
                this.$message.error("请输入正确的坐标字符串")
            }

        },
        delTDraw() {
            var ps = this.$refs.tianmap.getCheckDrawFeatures()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs.tianmap.delCheckDrawFeatures()
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }


        },
        submitTianForm() {
            var feas = this.$refs.tianmap.getAllDrawFeatures()
            if (feas.length > 0) {
                this.postInfo.ZB = this.getTDDrawPolygonsWKTStr()
                var center = this.getTDDrawPolyginsCenter()
                this.postInfo.LON = center[0]
                this.postInfo.LAT = center[1]
                this.sendEdit()
            } else {
                this.$message.error("请先绘制区域")
            }
        },
        getTDDrawPolygonsWKTStr() {
            var list = this.$refs.tianmap.getAllDrawFeatures()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getGeometry().getCoordinates()
                    var str = this.$refs.tianmap.getWKTByTDPolygon(path[0])
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },
        getTDDrawPolyginsCenter() {
            var list = this.$refs.tianmap.getAllDrawFeatures()
            var litem = list[0].getGeometry().getCoordinates()
            var p = this.$refs.tianmap.getTurfPolygon(litem)
            var center = this.$refs.tianmap.getPolygonCenter(p)
            return center.geometry.coordinates
        },
        downloadTemplet() {
            window.open("file/点位要素模板.xlsx")
        },
        uploadTemplet() {
            this.chooseFileModal = true
        },
        uploadFun(f) {
            this.showLoading("请稍后...")
            var fd = new FormData();
            fd.append("file", f.file);
            this.$upload.post("CleanObject/UploadPatrolExcel?token=" + this.$store.state.token, fd)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.hideLoading()

                        this.chooseFileModal = false
                        this.PageIndex = 1
                        this.getPostList()
                        if (response.data.DATA && response.data.DATA != "") {
                            this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                window.open(response.data.DATA)
                            })
                        } else {
                            this.$message.success("导入完成")
                        }
                    } else {
                        this.hideLoading()
                        this.$message.error("上传失败")
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                });
        },


        //图片
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 20;

            if (!isJPG) {
                this.$message.error('图片只能是 JPG/PNG  格式!');
            } else if (!isLt2M) {
                this.$message.error('图片大小不能超过 20MB!');
            } else {
                var _URL = window.URL || window.webkitURL;
                this.imgFiles.push({ url: _URL.createObjectURL(file), file: file, id: "", show: true })
            }
            return isJPG && isLt2M;
        },
        uploadImgFun(file) {
            //console.log(file)
        },
        delIcon(index) {
            var nl = []
            for (var i = 0; i < this.imgFiles.length; i++) {
                var f = this.imgFiles[i]
                if (i === index) {
                    f.show = false
                }
                nl.push(f)
            }
            this.imgFiles = nl
        },
        setFileImgs(id) {
            var adds = []
            var dels = []
            for (var i = 0; i < this.imgFiles.length; i++) {
                var d = this.imgFiles[i]
                if (d.id == "" && d.show) {
                    this.doImgCount++
                    adds.push(d.file)
                }
                if (d.id != "" && !d.show) {
                    this.doImgCount++
                    dels.push(d.id)
                }
            }
            if (this.doImgCount == 0) {
                this.getPostList()
            } else {
                for (var j = 0; j < adds.length; j++) {
                    this.addFileImg(adds[j], id)
                }
                for (var k = 0; k < dels.length; k++) {
                    this.delFileImg(dels[k])
                }
            }
        },
        addFileImg(file, id) {
            var fd = new FormData();
            fd.append("file", file);
            this.$upload.post("CleanObject/UploadCleanObjectPhoto?CLEANOBJECTSNOWID=" + id + "&token=" + this.$store.state.token, fd)
                .then((response) => {
                    this.okImgIndex++
                    if (this.okImgIndex == this.doImgCount) {
                        this.getPostList()
                    }
                })
        },
        delFileImg(id) {
            var md5_data = this.comjs.requestDataEncrypt({ FILESNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ FILESNOWID: id })).toUpperCase())
            this.$http.post("CleanObject/DeleteCleanObjectPhoto", md5_data)
                .then((response) => {
                    this.okImgIndex++
                    if (this.okImgIndex == this.doImgCount) {
                        this.getPostList()
                    }
                })
        },
        handleSelectionChange(e) {
            this.allIds = e
        },
        delMore() {
            if (this.allIds.length == 0) {
                this.$message.error("请至少选择一项")
            } else {
                this.$confirm('确定要删除选中的内容?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var ids = []
                    for (var i = 0; i < this.allIds.length; i++) {
                        ids.push(this.allIds[i].SNOWID)
                    }
                    this.delMoreFun(ids)
                })
            }
        },
        delMoreFun(ids) {
            //
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.nowMachineBox {
    width: 400px;
    height: 600px;
    position: relative;
    float: left;
    overflow: hidden;
    margin-left: 10px;
    background-color: #f4f4f5;
}

.nowMachineBox .mlTitle {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 12px;
}

.nowMachineBox .machineListBox {
    height: 520px;
    overflow-y: scroll;
}

.nowMachineBox .machineListNull p {
    color: #adadad;
    text-align: center;
}

.nowMachineBox .addMachineBox {
    height: 40px;
}

.nowMachineBox .addMachineBox .addBtn {
    width: 100%;
    border-radius: 0;
}

.machineListOut {
    overflow: hidden;
    padding: 20px 0px;
}

.machineListOut .machineItem {
    overflow: hidden;
    height: 40px;
    cursor: pointer;
    border-bottom: 1px solid #ADADAD;
}

.machineListOut .machineItem .machineItemImg {
    width: 30px;
    height: 30px;
    float: left;
    display: block;
    margin: 5px;
}

.machineListOut .machineItem .machineItemTxt {
    width: 150px;
    height: 40px;
    color: #666;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    float: left;
    margin-left: 10px;
}

.machineListOut .machineItem .removeIcon {
    float: left;
    color: #FF0036;
}

.el-tree {
    background: transparent;
}

.custom-tree-node {
    width: 100%;
}

.imgFileList {
    overflow: hidden;
    margin: 10px 0;
}

.imgFileList .imgItem {
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 10px;
    position: relative;
}

.imgFileList .imgItem .delIcon {
    width: 16px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}
</style>