<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">角色管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                            <el-button @click="userPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="role.edit">用户</el-button>
                            <!-- <el-button @click="projectPost(scope.row)" type="text" size="small" style="color:#409EFF">项目</el-button> -->
                            <el-button @click="menuPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="role.edit">菜单</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- :height="dialogMaxHeigh" -->
        <el-dialog title="绑定系统用户" :visible.sync="sysUserModal" width="600px">
            <el-table :data="sysUser" stripe style="width: 100%" @selection-change="userSelectionChange" ref="sysUser">
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                <el-table-column prop="OPENID" label="OPENID"></el-table-column>
            </el-table>
            <div style="overflow:hidden;margin-top:20px;">
                <el-button type="primary" @click="saveSysUser()">保存</el-button>
                <el-button @click="hideSysUserModal">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="绑定项目" :visible.sync="projectModal" width="600px">
            <el-table :data="projectList" stripe style="width: 100%" @selection-change="projectSelectionChange" ref="project">
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column prop="NAME" label="名称"></el-table-column>
                <el-table-column prop="ADDRESS" label="地址"></el-table-column>
            </el-table>
            <div style="overflow:hidden;margin-top:20px;">
                <el-button type="primary" @click="saveProject()">保存</el-button>
                <el-button @click="hideProjectModal">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="绑定菜单" :visible.sync="menuModal" width="600px">
            <el-tree  :props="props"  :data="menuList" ref="tree" node-key="SNOWID" default-expand-all  show-checkbox check-strictly :default-checked-keys="checkMenuList"  :expand-on-click-node="false" @check-change="menuChecked" >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="treeName">{{ node.label }}</span>
                <span v-if="data.PARENTID!='0'">
                    <span style="margin-left: 50px;overflow: hidden;visibility: hidden;" :id="data.SNOWID+'_checkbox'">
                        <div style="display: inline-block;margin-right:10px;" v-for="item in sysOper" :key="item.SNOWID">
                            <input type="checkBox" :id="data.SNOWID+'_'+item.SNOWID" style="vertical-align: sub;margin-right: 3px;">
                            <label style="vertical-align: middle;" :for="data.SNOWID+'_'+item.SNOWID">{{item.NAME}}</label>
                        </div>
                    </span>  
                </span>
                
              </span>
            </el-tree>
            <div style="overflow:hidden;margin-top:20px;">
                <el-button type="primary" @click="saveMenu()">保存</el-button>
                <el-button @click="hideMenuModal">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    return {
        dialogMaxHeigh:0,
        canDo:true,
        showPagination:false,
        name:"",
        c:["1","2"],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
        },


        sysOper:[],
        

        postItem:null,
        sysUser:[],
        checkSysUser:[],
        sysUserModal:false,

        projectList:[],
        checkProjectList:[],
        projectModal:false,

        menuList:[],
        checkMenuList:[],
        menuModal:false,

        props: {
            label: 'NAME',
            children: 'ListMenu',
            // isLeaf: 'leaf'
        },
        allIds:[],
        role:{add:false,edit:false,del:false},
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex=getQueryData.PAGEINDEX
        this.name = getQueryData.NAME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.dialogMaxHeigh = h -300
    this.getSysOper()
    this.role=this.setPageRoles(this.$route.path)
        setTimeout(()=>{
            this.role=this.setPageRoles(this.$route.path)
        },3000)
  },
  methods: {
    eventFn(e) {
        e.stopPropagation()
    },
    getSysOper(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("SysOper/GetAllSysOper?token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var nl=[]
                    var list =response.data.DATA
                    for(var i=0;i<list.length;i++){
                        if(list[i].ISDEFAULT != 1){
                            nl.push(list[i])
                        }
                        
                    }
                    this.sysOper=nl
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Role/GetRoleList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
        }
        this.doTypeStr="添加角色"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Role/InsertRole", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            ROLESNOWID:item.SNOWID,
            NAME:item.NAME,
        }
        this.doTypeStr="修改角色"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Role/UpdateRole", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除角色：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({ROLESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ROLESNOWID:id})).toUpperCase())
        this.$http.post("Role/DeleteRole", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    //绑定人员
    userPost(item){
        this.sysUserModal=true
        this.postItem=item
        this.checkSysUser=[]
        for(var i=0;i<item.SYSUSERLIST.length;i++){
            this.checkSysUser.push(item.SYSUSERLIST[i].SNOWID)
        }
        this.getSysUsers()
    },
    getSysUsers(){
        this.canDo=false
        console.log(this.checkSysUser)
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("SysUser/GetSysUserList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.sysUser=response.data.DATA
                    this.$nextTick(()=>{
                            let rows=[]
                            for(var i=0;i<this.checkSysUser.length;i++){
                                var c=this.checkSysUser[i]
                                for(var j=0;j<this.sysUser.length;j++){
                                    var s=this.sysUser[j]
                                    if(c==s.SNOWID){
                                        rows.push(s)
                                    }
                                }
                            }
                            rows.forEach(row=>{
                                this.$refs.sysUser.toggleRowSelection(row,true)
                            })
                    })
                } 
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    userSelectionChange(list){
        this.checkSysUser=[]
        for(var i=0;i<list.length;i++){
            this.checkSysUser.push(list[i].SNOWID)
        }
    },
    saveSysUser(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                ROLESNOWID:this.postItem.SNOWID,
                SYSUSERSNOWIDLIST:this.checkSysUser, 
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Role/BindRoleSysUser", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已绑定")
                        this.hideSysUserModal()
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
    },
    hideSysUserModal(){
        this.sysUserModal=false
        this.$refs.sysUser.clearSelection()
    },

    //绑定项目
    projectPost(item){
        this.projectModal=true
        this.postItem=item
        this. checkProjectList=[]
        for(var i=0;i<item.PROJECTLIST.length;i++){
            this.checkProjectList.push(item.PROJECTLIST[i].SNOWID)
        }
        this.getProject()
    },
    getProject(){
        this.canDo=false
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.projectList=response.data.DATA
                    this.$nextTick(()=>{
                            let rows=[]
                            for(var i=0;i<this.checkProjectList.length;i++){
                                var c=this.checkProjectList[i]
                                for(var j=0;j<this.projectList.length;j++){
                                    var s=this.projectList[j]
                                    if(c==s.SNOWID){
                                        rows.push(s)
                                    }
                                }
                            }
                            rows.forEach(row=>{
                                this.$refs.project.toggleRowSelection(row,true)
                            })
                    })


                } 
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    projectSelectionChange(list){
        this.checkProjectList=[]
        for(var i=0;i<list.length;i++){
            this.checkProjectList.push(list[i].SNOWID)
        }
    },
    saveProject(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                ROLESNOWID:this.postItem.SNOWID,
                PROJECTSNOWIDLIST:this.checkProjectList, 
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Role/BindRoleProject", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已绑定")
                        this.hideProjectModal()
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
    },
    hideProjectModal(){
        this.projectModal=false
        this.$refs.project.clearSelection()
    },

    //绑定菜单
    menuPost(item){
        this.menuModal=true
        this.postItem=item
        this. checkMenuList=[]
        for(var i=0;i<item.MENULIST.length;i++){
            var mid=item.MENULIST[i].SNOWID
            this.checkMenuList.push(mid)
        }   
        this.getMenu()
    },
    initMenuChecked(){
        var item =this.postItem
        for(var i=0;i<item.MENULIST.length;i++){
            var mid=item.MENULIST[i].SNOWID
            for(var j=0;j<item.MENULIST[i].SYSOPERLIST.length;j++){
                //console.log(item.MENULIST[i].SYSOPERLIST[j])
                if(item.MENULIST[i].SYSOPERLIST[j].ISDEFAULT != 1){
                    var sid=item.MENULIST[i].SYSOPERLIST[j].SNOWID
                    document.getElementById(mid+"_"+sid).checked=true
                }
            }
            if(document.getElementById(mid+'_checkbox')){
                document.getElementById(mid+'_checkbox').style.visibility="visible"
            }
        }   
    },
    menuChecked(d,m){
        var  mid=d.SNOWID
        var v='hidden'
        if(m){
            v='visible'
        }
        if(document.getElementById(mid+'_checkbox')){
            document.getElementById(mid+'_checkbox').style.visibility=v
        }
    },
    getMenu(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Menu/GetMenuTree?ParentID=0&token="+this.$store.state.token, md5_data)
        .then((response) => {
            //console.log(response)
            this.hideLoading()
            this.canDo=true
            if(response.data.SUCCESS){   
                this.menuList=response.data.DATA
                this.$nextTick(()=>{
                    this.initMenuChecked()
                })
            }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
        })
        .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
        });
    },

    hideMenuModal(){
        this.menuModal=false
    },
    saveMenu(){
        if(this.canDo){
            var  c=this.$refs.tree.getCheckedKeys()
            var  mc=[]
            for(var i=0;i<c.length;i++){
                var sl=[]
                var mobg={
                    SNOWID:c[i],
                    SYSOPERLIST:[]
                }
                for(var j=0;j<this.sysOper.length;j++){
                    var id=c[i]+"_"+this.sysOper[j].SNOWID
                    var f=document.getElementById(id)
                    if(f && f.checked){
                        sl.push({SNOWID:this.sysOper[j].SNOWID})
                    }
                }
                mobg.SYSOPERLIST=sl
                mc.push(mobg)
            }

            this.canDo=false
            this.showLoading("请稍后")
            var params={
                ROLESNOWID:this.postItem.SNOWID,
                MENULIST:mc, 
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Role/BindRoleMenuSysOper", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已绑定")
                        this.hideMenuModal()
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });

        }  
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
</style>