<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">车辆保险管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入保险名称" class="iw" ></el-input>
                <el-select v-model="carId" placeholder="车辆"  filterable  clearable class="iwu">
                        <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="typeId" placeholder="保险类型"  filterable  clearable class="iwu">
                        <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  :v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                <el-button type="primary" @click="downloadTemplet"  class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr mr10" v-if="role.add">导入模板</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="保险名称"></el-table-column>
                    <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
                    <el-table-column prop="CARINSURANCETYPENAME" label="保险类型"></el-table-column>
                    <el-table-column prop="COMPANY" label="承保单位"></el-table-column>
                    <el-table-column prop="PRICE" label="保险金额"></el-table-column>
                    <el-table-column prop="BEGINTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="保险名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车辆" prop="CARID">
                    <el-select  v-model="postInfo.CARID" placeholder="点击选择车辆" filterable style="width:100%">
                            <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="保险类型" prop="CARINSURANCETYPEID">
                    <el-select  v-model="postInfo.CARINSURANCETYPEID" placeholder="点击选择车辆" filterable style="width:100%">
                            <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="承保单位" prop="COMPANY">
                    <el-input v-model="postInfo.COMPANY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="保险金额" prop="PRICE">
                    <el-input v-model="postInfo.PRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开始时间" prop="BEGINTIMES">
                    <el-date-picker v-model="postInfo.BEGINTIMES" type="datetime" placeholder="选择开始时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="ENDTIMES">
                    <el-date-picker v-model="postInfo.ENDTIMES" type="datetime" placeholder="选择结束时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CARINSURANCE",
  data() {
    var CARID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆"));
      } else {
        callback();
      }
    };
    var NAME= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入保险名称"));
      } else {
        callback();
      }
    };
    var PRICE= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入保险金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('保险金额只能是数字'));
        }else if(value<=0){
            callback(new Error('保险金额必须大于0'));
        }else {
          callback();
        }
    };
    var CARINSURANCETYPEID= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择保险类型"));
      } else {
        callback();
      }
    };
    var COMPANY= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入承保单位"));
      } else {
        callback();
      }
    };
    var BEGINTIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var ENDTIMES = (rule, value, callback) => {
    var ss=this.postInfo.BEGINTIMES.getTime()
      if (value === "") {
        callback(new Error("请选择结束时间"));
      }else if(this.postInfo.BEGINTIMES && this.postInfo.BEGINTIMES!="" && value.getTime()<=ss){
        callback(new Error("结束时间必须大于开始时间"));
      } else {
        callback();
      }
    };
    
    return {
        canDo:true,
        showPagination:false,
        name:"",
        typeId:"",
        typeList:[],

        carList:[],
        carId:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            CARID: [{ validator: CARID, trigger: "blur" }],
            NAME: [{ validator: NAME, trigger: "blur" }],
            PRICE: [{ validator: PRICE, trigger: "blur" }],
            CARINSURANCETYPEID: [{ validator: CARINSURANCETYPEID, trigger: "blur" }],
            COMPANY: [{ validator: COMPANY, trigger: "blur" }],
            ENDTIMES: [{ validator: ENDTIMES, trigger: "blur" }],
            BEGINTIMES: [{ validator: BEGINTIMES, trigger: "blur" }],
        },
        role:{add:false,edit:false,del:false},
        allIds:[],
        chooseFileModal:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PAGEINDEX
        this.carId= getQueryData.CARID
        this.name= getQueryData.NAME
        this.typeId= getQueryData.CARINSURANCETYPEID
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getCarList()
    this.getTypeList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },2000)
  },
  methods: {
    getCarList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.carList=response.data.DATA
                } 
            })
    },
    getTypeList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("CarInsuranceType/GetAllCarInsuranceType", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.typeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CARINSURANCETYPEID:this.typeId,
            CARID:this.carId,
            NAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarInsurance/GetCarInsuranceList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            CARID:"",
            CARINSURANCETYPEID:"",
            PRICE:"",
            COMPANY:"",
            ENDTIMES:"",
            BEGINTIMES:"",

        }
        this.doTypeStr="添加车辆保险"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            NAME:this.postInfo.NAME,
            CARID:this.postInfo.CARID,
            CARINSURANCETYPEID:this.postInfo.CARINSURANCETYPEID,
            PRICE:this.postInfo.PRICE,
            COMPANY:this.postInfo.COMPANY,
            ENDTIME:this.comjs.getDateStrByDate(this.postInfo.ENDTIMES),
            BEGINTIME:this.comjs.getDateStrByDate(this.postInfo.BEGINTIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarInsurance/InsertCarInsurance", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CARINSURANCESNOWID:item.SNOWID,
            NAME:item.NAME,
            CARID:item.CARID,
            CARINSURANCETYPEID:item.CARINSURANCETYPEID,
            PRICE:item.PRICE,
            COMPANY:item.COMPANY,
            ENDTIMES:new Date(item.ENDTIME),
            BEGINTIMES:new Date(item.BEGINTIME),
        }
        this.doTypeStr="修改车辆保险"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CARINSURANCESNOWID:this.postInfo.CARINSURANCESNOWID,
            NAME:this.postInfo.NAME,
            CARID:this.postInfo.CARID,
            CARINSURANCETYPEID:this.postInfo.CARINSURANCETYPEID,
            PRICE:this.postInfo.PRICE,
            COMPANY:this.postInfo.COMPANY,
            ENDTIME:this.comjs.getDateStrByDate(this.postInfo.ENDTIMES),
            BEGINTIME:this.comjs.getDateStrByDate(this.postInfo.BEGINTIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarInsurance/UpdateCarInsurance", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除车辆保险：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CARINSURANCESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CARINSURANCESNOWID:id})).toUpperCase())
        this.$http.post("CarInsurance/DeleteCarInsurance", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    downloadTemplet(){
      window.open("file/车辆保险模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("CarInsurance/UploadExcel?token="+this.$store.state.token, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
            
             this.chooseFileModal=false
             this.PageIndex=1
             this.getPostList()
             if(response.data.DATA && response.data.DATA!=""){
                this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        window.open(response.data.DATA)
                    })
             }else{
                this.$message.success("导入完成")
             }
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>