<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">车辆管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入车牌号" class="iw" ></el-input>
                <el-select v-model="pid" placeholder="项目"  filterable  clearable class="iwu">
                    <el-option v-for="item in project" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="ctid" placeholder="类型"  filterable  clearable class="iwu">
                    <el-option v-for="item in carType" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="uid" placeholder="驾驶员"  filterable  clearable class="iwu">
                    <el-option v-for="item in user" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="isOnline" placeholder="在线状态"  filterable  clearable class="iwu">
                        <el-option label="在线" value="1" ></el-option>
                        <el-option label="离线" value="0" ></el-option>
                </el-select>
                <el-button type="primary" @click="downloadTemplet"  class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr mr10" v-if="role.add">导入模板</el-button>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                  <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                               发动机编号：{{props.row.ENGINENO}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              车架号：{{props.row.FRAMENUMBER}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              项目处编号：{{props.row.PROJECTNUMBER}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              市级平台分类代码：{{props.row.LJFLTYPE}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              GPS编号：{{props.row.GPSNO}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              SIM号码：{{props.row.SIMNUMBER}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              SIM号码：{{props.row.SIMNUMBER}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              IP：{{props.row.IP}}
                            </div>
                            <div style="font-size:14px;padding:0 20px;overflow:hidden">
                              车辆定位授权码:{{props.row.VKEY}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="PLATENUMBER" label="车牌号" fixed="left"  width="120"></el-table-column>
                    <el-table-column prop="CARTYPENAME" label="车辆类型" width="100"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目" width="100"></el-table-column>
                    <el-table-column prop="DRIVERNAME" label="驾驶员" width="100"></el-table-column>
                    <el-table-column prop="ISLOGIN" label="在线状态" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISLOGIN==1" style="color:#67C23A">在线</span>
                            <span v-else style="color:#909399">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="GPSTIME" label="最后一次GPS时间"   width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GPSTIME!='1900-01-01 00:00:00'">{{scope.row.GPSTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="VIDEONUM" label="监控数量" width="100"></el-table-column>
                    <el-table-column prop="DEVIDNO" label="设备编号" width="140"></el-table-column>
                    <el-table-column prop="PRICE" label="保险金额" width="100"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期" width="160"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="120" fixed="right">
                        <template slot-scope="scope">
                          <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                          <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                          <el-button @click="JKPost(scope.row)" type="text" size="small" style="color:#409EFF">监控</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="车牌号" prop="PLATENUMBER">
                    <el-input v-model="postInfo.PLATENUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车辆类型" prop="CARTYPEID">
                    <el-select v-model="postInfo.CARTYPEID" placeholder="车辆类型"  filterable  style="width:100%">
                        <el-option v-for="item in carType" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车辆定位类型">
                    <el-select v-model="postInfo.LOCATIONTYPEID" placeholder="车辆类型"  filterable  style="width:100%">
                        <el-option v-for="item in locationList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电子围栏">
                    <el-select v-model="postInfo.ELECREGIONID" placeholder="电子围栏"  filterable clearable style="width:100%">
                        <el-option v-for="item in elecList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目" prop="PROJECTID">
                    <el-select v-model="postInfo.PROJECTID" placeholder="项目"  filterable  style="width:100%">
                        <el-option v-for="item in project" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="驾驶员" prop="DRIVERID">
                    <el-select v-model="postInfo.DRIVERID" placeholder="驾驶员"  filterable  style="width:100%">
                        <el-option v-for="item in user" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目处编号">
                    <el-input v-model="postInfo.PROJECTNUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发动机编号">
                    <el-input v-model="postInfo.ENGINENO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车架号">
                    <el-input v-model="postInfo.FRAMENUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="市级平台分类代码">
                    <el-input v-model="postInfo.LJFLTYPE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="GPS编号">
                    <el-input v-model="postInfo.GPSNO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="SIM号码">
                    <el-input v-model="postInfo.SIMNUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="定位ID">
                    <el-input v-model="postInfo.LOCATIONID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车辆定位授权码">
                    <el-input v-model="postInfo.VKEY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="IP">
                    <el-input v-model="postInfo.IP" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="监控数量">
                    <el-input v-model.number="postInfo.VIDEONUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="设备编号">
                    <el-input v-model="postInfo.DEVIDNO" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="保险金额" prop="PRICE">
                    <el-input v-model="postInfo.PRICE" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="备注">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="jkModal" width="1200px" :append-to-body="true" top="50px">
          <JK :style="{height:dialogMaxHeigh+'px'}" :CARID="jkCarId" ref="jk"></JK>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import JK from "../../components/CarCameraManage"

export default {
  name: "PostManage",
  components:{
    JK,
  },
  data() {
    var PLATENUMBER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车牌号"));
      } else {
        callback();
      }
    };
    var CARTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆类型"));
      } else {
        callback();
      }
    };
    var PROJECTID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择项目"));
      } else {
        callback();
      }
    };

    var DRIVERID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择驾驶员"));
      } else {
        callback();
      }
    };
    var PROJECTNUMBER=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入项目处编号"));
      } else {
        callback();
      }
    };
    var ENGINENO=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入发动机编号"));
      } else {
        callback();
      }
    };
    var FRAMENUMBER=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车架号"));
      } else {
        callback();
      }
    };
    var LJFLTYPE=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入市级平台分类代码"));
      } else {
        callback();
      }
    };
    var LOCATIONTYPEID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆定位类型"));
      } else {
        callback();
      }
    };
    
    var GPSNO=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入GPS编号"));
      } else {
        callback();
      }
    };
    var SIMNUMBER=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入SIM号码"));
      } else {
        callback();
      }
    };
    var LOCATIONID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入定位ID"));
      } else {
        callback();
      }
    };
    var VKEY=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车辆定位授权码"));
      } else {
        callback();
      }
    };
    var IP=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入IP"));
      } else {
        callback();
      }
    };
    var VIDEONUM= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入监控数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('监控数量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var DEVIDNO=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入设备编号"));
      } else {
        callback();
      }
    };
    var PRICE=(rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入保险金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('保险金额只能是数字'));
        }else if(value<0){
            callback(new Error('保险金额不能小于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        showPagination:false,
        name:"",
        
        elecList:[],
        project:[],
        pid:"",
        user:[],
        uid:"",
        carType:[],
        ctid:"",

        isOnline:"",

        locationList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            PLATENUMBER: [{ validator: PLATENUMBER, trigger: "blur",required:true, }],
            CARTYPEID: [{ validator: CARTYPEID, trigger: "blur" ,required:true,}],
            LOCATIONTYPEID:[{ validator: LOCATIONTYPEID, trigger: "blur" }],
            PROJECTID: [{ validator: PROJECTID, trigger: "blur" ,required:true,}],
            DRIVERID: [{ validator: DRIVERID, trigger: "blur" ,required:true,}],
            // PROJECTNUMBER:[{ validator: PROJECTNUMBER, trigger: "blur" ,required:true,}],
            // ENGINENO:[{ validator: ENGINENO, trigger: "blur" ,required:true,}],
            // FRAMENUMBER:[{ validator: FRAMENUMBER, trigger: "blur" ,required:true,}],
            // LJFLTYPE:[{ validator: LJFLTYPE, trigger: "blur" ,required:true,}],
            // GPSNO:[{ validator: GPSNO, trigger: "blur" ,required:true,}],
            // SIMNUMBER:[{ validator: SIMNUMBER, trigger: "blur" ,required:true,}],
            // LOCATIONID:[{ validator: LOCATIONID, trigger: "blur",required:true, }],
            // VKEY:[{ validator: VKEY, trigger: "blur" ,required:true,}],
            // IP:[{ validator: IP, trigger: "blur" ,required:true,}],
            // VIDEONUM:[{ validator: VIDEONUM, trigger: "blur" ,required:true,}],
            // DEVIDNO:[{ validator: DEVIDNO, trigger: "blur" ,required:true,}],
            // PRICE:[{ validator: PRICE, trigger: "blur" ,required:true,}],
            
        },

        dialogMaxHeigh:0,
        jkModal:false,
        jkCarId:"",
        chooseFileModal:false,
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex = getQueryData.PAGEINDEX
        this.ctid = getQueryData.CARTYPEID
        this.pid = getQueryData.PROJECTID
        this.uid = getQueryData.DRIVERID
        this.name = getQueryData.PLATENUMBER
        this.isOnline = getQueryData.ISLOGIN
    }
    this.showPagination=true
    this.dialogMaxHeigh = h-200
    this.loadItem=null
    this.getElecList()
    this.getPostList()
    this.getProject()
    this.getUser()
    this.getCarType()
    this.getLocationTypeList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getLocationTypeList(){
        var params={
            PageIndex:1,
            PageSize:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarLocationType/GetCarLocationTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.locationList=response.data.DATA
                }
            })
    },
    getElecList(){
      var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("ElecRegion/GetElecRegionList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.elecList=response.data.DATA
                } 
            })
    },
    getProject(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        if(list[i].PARENTID=='0'){
                            this.project.push(list[i])
                        }
                    }
                } 
            })
    },
    getUser(){
        var md5_data =this.comjs.requestDataEncrypt({ISQUIT:"0"}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ISQUIT:"0"})).toUpperCase())
        this.$http.post("User/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.user=response.data.DATA
                } 
            })
    },
    getCarType(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("CarType/GetCarTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.carType=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CARTYPEID:this.ctid,
            PROJECTID:this.pid,
            DRIVERID:this.uid,
            PLATENUMBER:this.name,
            ISLOGIN:this.isOnline,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            ENGINENO:"",
            SIMNUMBER:"",
            BZ:"",
            FRAMENUMBER:"",
            LJFLTYPE:"",
            CARTYPEID:"",
            GPSNO:"",
            LOCATIONID:"",
            IP:"",
            PLATENUMBER:"",
            VIDEONUM:"",
            DEVIDNO:"",
            PRICE:"",
            VKEY:"",
            PROJECTID:"",
            DRIVERID:"",
            PROJECTNUMBER:"",
            ELECREGIONID:"",
            LOCATIONTYPEID:"",
        }
        this.doTypeStr="添加车辆"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Car/InsertCar", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CARSNOWID:item.SNOWID,
            ENGINENO:item.ENGINENO,
            SIMNUMBER:item.SIMNUMBER,
            BZ:item.BZ,
            FRAMENUMBER:item.FRAMENUMBER,
            LJFLTYPE:item.LJFLTYPE,
            CARTYPEID:item.CARTYPEID,
            GPSNO:item.GPSNO,
            LOCATIONID:item.LOCATIONID,
            IP:item.IP,
            PLATENUMBER:item.PLATENUMBER,
            VIDEONUM:item.VIDEONUM,
            DEVIDNO:item.DEVIDNO,
            PRICE:item.PRICE,
            VKEY:item.VKEY,
            PROJECTID:item.PROJECTID,
            DRIVERID:item.DRIVERID,
            PROJECTNUMBER:item.PROJECTNUMBER,
            ELECREGIONID:item.ELECREGIONID,
            LOCATIONTYPEID:item.LOCATIONTYPEID,
        }
        this.doTypeStr="修改车辆"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Car/UpdateCar", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除车辆：'+item.PLATENUMBER+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CARSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CARSNOWID:id})).toUpperCase())
        this.$http.post("Car/DeleteCar", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.PRICE = parseFloat(this.postInfo.PRICE)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    downloadTemplet(){
      window.open("file/车辆模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("Car/UploadExcel?token="+this.$store.state.token, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.chooseFileModal=false
             this.PageIndex=1
             this.getPostList()
             if(response.data.DATA && response.data.DATA!=""){
                this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        window.open(response.data.DATA)
                    })
             }else{
                this.$message.success("导入完成")
             }
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    JKPost(item){
      this.showLoading("请稍后")
      this.jkCarId=item.SNOWID
      this.jkModal=true
      
      setTimeout(()=>{
        this.$refs.jk.getPostList()
        this.hideLoading()
      },2000) 
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>