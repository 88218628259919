<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">appKey配置</p>
        </div>
        <div class="tableBox" style="top:50px;overflow: auto;">
            <KEY :TOKEN="tokenstr"  ROTE="1" ref="key"></KEY>
        </div>
    </div>
</template>
<script>
var h=document.body.clientHeight
import KEY from "../../components/AppKey"

export default {
  name: "AppKey",
  components:{
    KEY,
  },

  data() {
    return {
        tokenstr:"",
        dialogMaxHeigh:0,
    };
  },
  mounted() {
    this.tokenstr=this.$store.state.token
    this.dialogMaxHeigh = h -300
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>