<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">项目管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iww"></el-input>
                <el-select v-model="pid" placeholder="父项目" filterable clearable class="iww">
                    <el-option v-for="item in objectList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-button type="primary" @click="searchWF">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del" v-show="false">批量删除</el-button>
                <el-button type="success" @click="addWF" class="fr" v-if="role.add">添加</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="LEVEL" label="级别"></el-table-column>
                    <el-table-column prop="CODE" label="层级码"></el-table-column>
                    <el-table-column prop="PARENTID" label="父项目">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PARENTID == '0'">无</span>
                            <span v-else>{{ scope.row.PARENTNAME }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ADDRESS" label="地址" width="300"></el-table-column>
                    <el-table-column prop="AREA" label="面积"></el-table-column>
                    <el-table-column prop="PREFIX" label="工号前缀"></el-table-column>
                    <el-table-column prop="BZ" label="简介"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期" width="160"></el-table-column>
                    <el-table-column label="操作" width="220" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editWF(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">编辑</el-button>
                            <el-button @click="delWF(scope.row)" type="text" size="small" style="color:#F56C6C"
                                v-if="role.del">删除</el-button>
                            <!-- <el-button @click="baiduArea(scope.row)" type="text" size="small" style="color:#409EFF">百度地图区域</el-button> -->
                            <el-button @click="tianArea(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="role.edit">区域</el-button>
                            <el-button @click="warnings(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">预警类型设置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" top="30px" :close-on-press-escape="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="ADDRESS">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积" prop="AREA">
                    <el-input v-model="postInfo.AREA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="级别" prop="LEVEL">
                    <el-input v-model.number="postInfo.LEVEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="工号前缀" prop="PREFIX">
                    <el-input v-model.number="postInfo.PREFIX" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="层级码" prop="CODE">
                    <el-select v-model="postInfo.CODE" placeholder="选择层级码" filterable style="width:100%">
                        <el-option v-for="item in codeList" :key="item.CODE" :label="item.NAME"
                            :value="item.CODE"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="BZ">
                    <el-input v-model="postInfo.BZ" autocomplete="off" type="textarea" class="xmjj"></el-input>
                </el-form-item>
                <el-form-item label="父项目">
                    <el-select v-model="postInfo.PARENTID" placeholder="选择父项目" filterable style="width:100%">
                        <el-option label="无" value="0"></el-option>
                        <el-option v-for="item in objectList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="区域" prop="POLYGON">
                    <Map class="map" ref="map" :width="mapWidth" :height="mapHeight" style="float:left" @mapFinish="mapFinish" @setPolygonCount="setPolygonCount"></Map>
                </el-form-item> -->
                <el-form-item>
                    <el-button @click="postModal = false" class="fr ml20">取消</el-button>
                    <el-button type="primary" @click="submitWFForm()" class="fr ml20">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="百度地图区域" :visible.sync="baidumapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <BaiDuMap class="map" ref="baidumap" :width="mapWidth" :height="mapHeight" @mapFinish="baidumapFinish"
                @setPolygonCount="setBDPolygonCount"></BaiDuMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startBDDraw()" class="fl mr20">绘制区域</el-button>
                <el-button type="danger" @click="delBDDraw()" class="fl mr20">删除区域</el-button>
                <!-- <el-button type="danger" @click="clearDraw()" class="fl mr20">清空绘制</el-button> -->
                <el-button @click="baidumapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitBaiduForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="天地图区域" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"
                :move="false" :draw="true"></TianMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startTDraw()" class="fl ">绘制区域</el-button>
                <el-button type="danger" @click="delTDraw()" class="fl mr10">删除区域</el-button>
                <el-input placeholder="请在此处粘贴坐标字符串" autocomplete="off" v-model="polygonStr" style="width:620px;"
                    class="fl mr20" @keyup.enter.native="setMapPolygon()"><i slot="suffix"
                        class="el-input__icon el-icon-finished" style="cursor: pointer;"
                        @click="setMapPolygon()"></i></el-input>
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitTianForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="预警类型设置" :visible.sync="warningModal" width="1000px" top="120px" :close-on-press-escape="false">
            <el-form label-width="100px" class="demo-uForm">
                <el-form-item label="预警类型">
                    <el-select v-model="warningList" placeholder="选择预警类型" filterable multiple style="width:100%">
                        <el-option v-for="item in warningTypeList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="warningModal = false" class="fr ml20">取消</el-button>
                    <el-button type="primary" @click="submitWarning()" class="fr ml20">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var pattern = /^[1-9][0-9]*$/
var h = document.body.clientHeight
import BaiDuMap from "../../components/BaiDuMap"
import TianMap from "../../components/OpenLayers"
export default {
    name: "ProjectManage",
    components: {
        BaiDuMap,
        TianMap,
    },
    data() {
        var NAME = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var AREA = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入面积'));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('面积只能是数字'));
            } else if (value <= 0) {
                callback(new Error('面积必须大于0'));
            } else {
                callback();
            }
        };
        var ADDRESS = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地址"));
            } else {
                callback();
            }
        };

        var PREFIX = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入工号前缀"));
            } else {
                callback();
            }
        };
        var CODE = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择层级码"));
            } else {
                callback();
            }
        };
        var LEVEL = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入级别'));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('级别只能是数字'));
            } else if (!/^\+?(0|[1-9][0-9]*)$/.test(value)) {
                callback(new Error('只能输入大于等于0的整数'));
            } else {
                callback();
            }
        };

        return {
            objectList: [],
            pid: "",
            showPagination: false,
            canDo: true,

            name: "",

            codeList: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                NAME: [{ validator: NAME, trigger: "blur" }],
                AREA: [{ validator: AREA, trigger: "blur" }],
                ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
                LEVEL: [{ validator: LEVEL, trigger: "blur" }],
                PREFIX: [{ validator: PREFIX, trigger: "blur" }],
                CODE: [{ validator: CODE, trigger: "blur" }],
            },

            mapWidth: 1060,
            mapHeight: 600,

            //百度地图
            baidumapModal: false,
            baidumap: null,
            BDPOLYGON: 0,


            //天地图
            tianmapModal: false,
            tianmap: null,
            TPOLYGON: 0,
            polygonStr: "",
            //预警类型
            projectItem: null,
            warningTypeList: [],
            warningList: [],
            warningModal: false,
            allIds: [],
            role: { add: false, edit: false, del: false }

        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PAGEINDEX
            this.name = getQueryData.NAME
            this.pid = getQueryData.PARENTID
        }
        this.showPagination = true
        this.loadItem = null
        this.getCodeList()
        this.getPostList()
        this.getObjectList()
        this.coolLeftHeight = h - 60
        this.getWarningTypeList()
        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {
        //获取层级码列表
        getCodeList() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Region/GetRegionList", md5_data)
                .then((response) => {
                    //console.log(response)
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        var nl = []
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].CODE.length == 1) {
                                nl.push(list[i])
                            }
                        }
                        this.codeList = nl
                    }
                })
        },
        //预警类型设置
        getWarningTypeList() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("WarningType/GetWarningTypeList?token=" + this.$store.state.token, md5_data)
                .then((response) => {
                    //console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        this.warningTypeList = response.data.DATA
                    }
                })
        },
        warnings(item) {
            console.log(item)
            this.projectItem = item
            this.warningList = item.WARNINGTYPEIDLIST
            this.warningModal = true
        },
        submitWarning() {
            if (this.canDo) {
                this.showLoading("请稍后")
                var params = {
                    PROJECTID: this.projectItem.SNOWID,
                    WARNINGTYPEIDLIST: this.warningList,
                }
                var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("Project/BindProjectWarningType", md5_data)
                    .then((response) => {
                        this.hideLoading()
                        if (response.data.SUCCESS) {
                            this.$message.success("设置成功")
                            this.warningModal = false
                            this.getPostList()
                        } else {
                            this.errorRequest(response.data.DATA, response.data.MSG, true)
                        }
                    })
                    .catch((error) => {
                        this.hideLoading()
                    });
            }
        },
        getObjectList() {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    //console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        this.objectList = response.data.DATA
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.canDo = true
                });
        },
        baidumapFinish() {
            this.baidumap = this.$refs.baidumap.getMap()
        },
        tianmapFinish() {
            this.tianmap = this.$refs.tianmap.map
        },
        //获取项目列表
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
                PARENTID: this.pid,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        //搜索项目
        searchWF() {
            this.PageIndex = 1
            this.getPostList()
        },

        //点击添加项目
        addWF() {
            this.postInfo = {
                NAME: "",
                AREA: "",
                ADDRESS: "",
                LEVEL: "",
                PARENTID: "0",
                POLYGON: 0,
                ZBBAIDU: "",
                ZB: "",
                BZ: "",
                PREFIX: "",
                CODE: ""
            }
            this.doTypeStr = "添加项目"
            this.doType = "1"
            this.postModal = true
        },
        //执行添加项目
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("Project/InsertProject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                        this.getObjectList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        getPidListById(id) {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Menu/GetAllParentMenuID?MenuID=" + id + "&token=" + this.$store.state.token, md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postInfo.PARENTID = response.data.DATA
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //点击编辑项目
        editWF(item) {
            this.postInfo = {
                PROJECTSNOWID: item.SNOWID,
                NAME: item.NAME,
                AREA: item.AREA,
                ADDRESS: item.ADDRESS,
                LEVEL: item.LEVEL,
                ZB: item.ZB,
                BZ: item.BZ,
                ZBBAIDU: item.ZBBAIDU,
                PREFIX: item.PREFIX,
                CODE: item.CODE
            }
            this.doTypeStr = "编辑项目"
            this.doType = "2"
            this.postModal = true

        },
        //执行编辑项目请求
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$newHttp.post("Project/UpdateProject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.getPostList()
                        this.getObjectList()
                        this.$message.success("编辑成功")
                        this.postModal = false
                        this.baidumapModal = false
                        this.tianmapModal = false
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //点击删除项目
        delWF(item) {
            if (this.canDo) {
                this.$confirm('确定删除项目：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已删除")
                });
            }

        },
        //执行删除项目请求
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt({ PROJECTSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ PROJECTSNOWID: id })).toUpperCase())
            this.$http.post("Project/DeleteProject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                        this.getObjectList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //页码切换
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        //确定提交项目
        submitWFForm() {
            if (this.canDo) {

                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        this.postInfo.AREA = parseFloat(this.postInfo.AREA)
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }

                    } else {
                        return false;
                    }
                });
            }
        },

        getProjectZB(id, type) {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({ PROJECTSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ PROJECTSNOWID: id })).toUpperCase())
            this.$http.post("Project/GetProjectZBByProjectID", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS && response.data.DATA) {
                        var d = response.data.DATA
                        if (d) {
                            if (type == "1" && d.ZBSTRBAIDU && d.ZBSTRBAIDU != "") {
                                this.setProjectBDZB(d.ZBSTRBAIDU)
                            } else if (type == "2" && d.ZBSTR && d.ZBSTR != "") {
                                this.setProjectTDZB(d.ZBSTR)
                            }
                            this.postInfo.ZB = d.ZBSTR
                            this.postInfo.ZBBAIDU = d.ZBSTRBAIDU
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        /**
         * 百度地图区域
         */
        baiduArea(item) {
            this.postInfo = item
            this.postInfo.PROJECTSNOWID = item.SNOWID
            this.baidumapModal = true
            this.BDPOLYGON = 0
            if (this.baidumap) {
                this.$refs.baidumap.clearDraw()
            }
            setTimeout(() => {
                this.getProjectZB(item.SNOWID, "1")
            }, 500)
        },
        setProjectBDZB(zb) {
            var zbItem = this.$refs.baidumap.getBDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.baidumap.appendPolygon(zbItem.arr[i], oids)
                    if (i == 0) {
                        this.baidumap.panTo(zbItem.arr[i][0])
                    }
                }
            } else {
                var oid = new Date().getTime().toString()
                this.$refs.baidumap.appendPolygon(zbItem.arr, oid)
                this.baidumap.panTo(zbItem.arr[0])
            }
        },
        //开始绘制
        startBDDraw() {
            this.$refs.baidumap.openDraw()
        },
        setBDPolygonCount(num) {
            this.BDPOLYGON = num
        },
        //删除绘制
        delBDDraw() {
            var ps = this.$refs.baidumap.getCheckPolygons()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for (var i = 0; i < ps.length; i++) {
                        this.$refs.baidumap.removeDrawPolygon(ps[i])
                    }
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }
        },
        submitBaiduForm() {
            if (this.canDo) {
                if (this.BDPOLYGON > 0) {
                    this.postInfo.ZBBAIDU = this.getBDDrawPolygonsWKTStr()
                    this.sendEdit()
                } else {
                    this.$message.error("请先绘制区域")
                }
            }
        },
        getBDDrawPolygonsWKTStr() {
            var list = this.$refs.baidumap.getDrawPolygons()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getPath()

                    var str = this.$refs.baidumap.getWKTByBDPolygon(path)
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },

        /**
         * 天地图
         */
        tianArea(item) {
            // tianmapModal:false,
            // tianmap:null,
            this.postInfo = item
            this.postInfo.PROJECTSNOWID = item.SNOWID
            this.tianmapModal = true
            this.polygonStr = ""
            if (this.tianmap) {
                this.$refs.tianmap.clearDraw()
            }
            setTimeout(() => {
                this.getProjectZB(item.SNOWID, "2")
            }, 500)
        },
        setProjectTDZB(zb) {
            var zbItem = this.$refs.tianmap.getTDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                    if (i == 0) {
                        this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                    }
                }
            } else {
                var paths = []
                if (zbItem.isGroup) {
                    paths = zbItem.arr
                } else {
                    paths = [zbItem.arr]
                }
                var oid = new Date().getTime().toString()
                this.$refs.tianmap.appendFeature('Polygon', oid, paths, false, null)
                this.$refs.tianmap.moveTo(zbItem.arr[0])
            }
        },

        startTDraw() {
            this.$refs.tianmap.drawPolygon(null, null)
        },
        setMapPolygon() {
            var str = this.polygonStr
            if (str.indexOf("MULTIPOLYGON") != -1 || str.indexOf("POLYGON") != -1) {
                var zbItem = this.$refs.tianmap.getTDPathByWKTStr(this.polygonStr)
                console.log(zbItem)
                if (zbItem.arr.length > 0) {
                    this.$confirm('本次操作会覆盖原先的坐标区域，确定继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.tianmap.clearDraw()
                        if (zbItem.isMulti) {
                            for (var i = 0; i < zbItem.arr.length; i++) {
                                var oids = new Date().getTime().toString() + i
                                this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                                if (i == 0) {
                                    this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                                }
                            }
                        } else {
                            var oid = new Date().getTime().toString()
                            this.$refs.tianmap.appendFeature('Polygon', oid, [zbItem.arr], false, null)
                            this.$refs.tianmap.moveTo(zbItem.arr[0])
                        }
                    })
                } else {
                    this.$message.error("请输入正确的坐标字符串")
                }
            } else {
                this.$message.error("请输入正确的坐标字符串")
            }

        },
        delTDraw() {
            var ps = this.$refs.tianmap.getCheckDrawFeatures()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs.tianmap.delCheckDrawFeatures()
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }
        },
        submitTianForm() {
            var feas = this.$refs.tianmap.getAllDrawFeatures()
            if (feas.length > 0) {
                this.postInfo.ZB = this.getTDDrawPolygonsWKTStr()
                this.sendEdit()
            } else {
                this.$message.error("请先绘制区域")
            }
        },
        getTDDrawPolygonsWKTStr() {
            var list = this.$refs.tianmap.getAllDrawFeatures()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getGeometry().getCoordinates()
                    var str = this.$refs.tianmap.getWKTByTDPolygon(path[0])
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },
        handleSelectionChange(e) {
            this.allIds = e
        },
        delMore() {
            if (this.allIds.length == 0) {
                this.$message.error("请至少选择一项")
            } else {
                this.$confirm('确定要删除选中的内容?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var ids = []
                    for (var i = 0; i < this.allIds.length; i++) {
                        ids.push(this.allIds[i].SNOWID)
                    }
                    this.delMoreFun(ids)
                })
            }
        },
        delMoreFun(ids) {
            //
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.nowMachineBox {
    width: 400px;
    height: 600px;
    position: relative;
    float: left;
    overflow: hidden;
    margin-left: 10px;
    background-color: #f4f4f5;
}

.nowMachineBox .mlTitle {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 12px;
}

.nowMachineBox .machineListBox {
    height: 520px;
    overflow-y: scroll;
}

.nowMachineBox .machineListNull p {
    color: #adadad;
    text-align: center;
}

.nowMachineBox .addMachineBox {
    height: 40px;
}

.nowMachineBox .addMachineBox .addBtn {
    width: 100%;
    border-radius: 0;
}

.machineListOut {
    overflow: hidden;
    padding: 20px 0px;
}

.machineListOut .machineItem {
    overflow: hidden;
    height: 40px;
    cursor: pointer;
    border-bottom: 1px solid #ADADAD;
}

.machineListOut .machineItem .machineItemImg {
    width: 30px;
    height: 30px;
    float: left;
    display: block;
    margin: 5px;
}

.machineListOut .machineItem .machineItemTxt {
    width: 150px;
    height: 40px;
    color: #666;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    float: left;
    margin-left: 10px;
}

.machineListOut .machineItem .removeIcon {
    float: left;
    color: #FF0036;
}

.el-tree {
    background: transparent;
}

.custom-tree-node {
    width: 100%;
}

.xmjj .el-textarea__inner {
    height: 100px !important;
}</style>
