<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">车辆类型</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                <el-button type="primary" @click="editAll" class="fr" v-if="role.edit">批量修改时间距离</el-button>    
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="ICONURL" label="图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.ICONURL" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="NAME" label="名称" width="260"></el-table-column>
                    <el-table-column prop="LIMITSPEED" label="限速"></el-table-column>
                    <el-table-column prop="NOWORKLIMITSPEED" label="非工作限速"></el-table-column>
                    <el-table-column prop="DELAYBEHIND" label="迟到早退工作时间后推多少分钟"></el-table-column>
                    <el-table-column prop="DELAYFRONT" label="迟到早退工作时间前推多少分钟"></el-table-column>
                    <el-table-column prop="STAYDIS" label="停留超时距离（米）"></el-table-column>
                    <el-table-column prop="STAYDURA" label="停留超时时长（分钟）"></el-table-column>
                    <el-table-column prop="OUTREGIONDIS" label="超出电子围栏距离（米）"></el-table-column>
                    <el-table-column prop="OUTREGIONDURA" label="超出电子围栏时长（分钟）"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1000px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="限速" prop="LIMITSPEED">
                    <el-input v-model.number="postInfo.LIMITSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="非工作限速" prop="NOWORKLIMITSPEED">
                    <el-input v-model.number="postInfo.NOWORKLIMITSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="迟到早退工作时间后推多少分钟" prop="DELAYBEHIND">
                    <el-input v-model.number="postInfo.DELAYBEHIND" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="迟到早退工作时间前推多少分钟" prop="DELAYFRONT">
                    <el-input v-model.number="postInfo.DELAYFRONT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="停留超时距离（米）" prop="STAYDIS">
                    <el-input v-model.number="postInfo.STAYDIS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="停留超时时长（分钟）" prop="STAYDURA">
                    <el-input v-model.number="postInfo.STAYDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超出电子围栏距离（米）" prop="OUTREGIONDIS">
                    <el-input v-model.number="postInfo.OUTREGIONDIS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超出电子围栏时长（分钟）" prop="OUTREGIONDURA">
                    <el-input v-model.number="postInfo.OUTREGIONDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图标" prop="ICON">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFun"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="批量修改时间和距离" :visible.sync="allModal" width="1000px">
            <el-form :model="allInfo" status-icon :rules="allrules"  ref="aForm" label-width="300px" class="demo-uForm">
                <el-form-item label="限速" prop="LIMITSPEED">
                    <el-input v-model.number="allInfo.LIMITSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="非工作限速" prop="NOWORKLIMITSPEED">
                    <el-input v-model.number="allInfo.NOWORKLIMITSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="迟到早退工作时间后推多少分钟" prop="DELAYBEHIND">
                    <el-input v-model.number="allInfo.DELAYBEHIND" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="迟到早退工作时间前推多少分钟" prop="DELAYFRONT">
                    <el-input v-model.number="allInfo.DELAYFRONT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="停留超时距离（米）" prop="STAYDIS">
                    <el-input v-model.number="allInfo.STAYDIS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="停留超时时长（分钟）" prop="STAYDURA">
                    <el-input v-model.number="allInfo.STAYDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超出电子围栏距离（米）" prop="OUTREGIONDIS">
                    <el-input v-model.number="allInfo.OUTREGIONDIS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超出电子围栏时长（分钟）" prop="OUTREGIONDURA">
                    <el-input v-model.number="allInfo.OUTREGIONDURA" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitAllForm()">提交</el-button>
                    <el-button @click="allModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("字段不能为空"));
      } else {
        callback();
      }
    };
    var NUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('字段不能为空'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('字段只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var ICON = (rule, value, callback) => {
        if (value == 0) {
            callback(new Error("请选择图片"));
        } else {
            callback();
        }
      };
    return {
        canDo:true,
        showPagination:false,
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            DELAYBEHIND: [{ validator: NUM, trigger: "blur" }],
            DELAYFRONT: [{ validator: NUM, trigger: "blur" }],
            STAYDIS: [{ validator: NUM, trigger: "blur" }],
            STAYDURA: [{ validator: NUM, trigger: "blur" }],
            OUTREGIONDIS: [{ validator: NUM, trigger: "blur" }],
            OUTREGIONDURA: [{ validator: NUM, trigger: "blur" }],
            ICON: [{ validator: ICON, trigger: "blur" }],
            LIMITSPEED :[{ validator: NUM, trigger: "blur" }],
            NOWORKLIMITSPEED:[{ validator: NUM, trigger: "blur" }],
        },

        imageUrl:"",
        fileItem:null,
        role:{add:false,edit:false,del:false},

        
        allIds:[],
        allModal:false,

        allInfo:{},
        allrules: {
            DELAYBEHIND: [{ validator: NUM, trigger: "blur" }],
            DELAYFRONT: [{ validator: NUM, trigger: "blur" }],
            STAYDIS: [{ validator: NUM, trigger: "blur" }],
            STAYDURA: [{ validator: NUM, trigger: "blur" }],
            OUTREGIONDIS: [{ validator: NUM, trigger: "blur" }],
            OUTREGIONDURA: [{ validator: NUM, trigger: "blur" }],
            LIMITSPEED :[{ validator: NUM, trigger: "blur" }],
            NOWORKLIMITSPEED:[{ validator: NUM, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex = getQueryData.PageIndex
        this.name = getQueryData.NAME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            NAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarType/GetCarTypeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            LIMITSPEED:"",
            NOWORKLIMITSPEED:"",
            DELAYBEHIND:"",
            DELAYFRONT:"",
            STAYDIS:"",
            STAYDURA:"",
            OUTREGIONDIS:"",
            OUTREGIONDURA:"",
            ICONURL:"",
            ICON:0,
        }
        this.imageUrl=""
        this.fileItem=null
        this.doTypeStr="添加车辆类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CarType/InsertCarType", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    var data=response.data.DATA
                    this.addImg(data,"1")
                } else {
                    this.hideLoading()
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CARTYPESNOWID:item.SNOWID,
            NAME:item.NAME,
            LIMITSPEED:item.LIMITSPEED,
            NOWORKLIMITSPEED:item.NOWORKLIMITSPEED,
            DELAYBEHIND:item.DELAYBEHIND,
            DELAYFRONT:item.DELAYFRONT,
            STAYDIS:item.STAYDIS,
            STAYDURA:item.STAYDURA,
            ICONURL:item.ICONURL,
            OUTREGIONDIS:item.OUTREGIONDIS,
            OUTREGIONDURA:item.OUTREGIONDURA,
            ICON:1,
        }
        this.imageUrl=item.ICONURL
        this.fileItem=null
        this.doTypeStr="修改车辆类型"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CarType/UpdateCarType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(this.fileItem){
                        this.addImg(this.postInfo,"2")
                    }else{
                        this.$message.success("已修改")
                        this.postModal=false
                        this.getPostList();
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CARTYPESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CARTYPESNOWID:id})).toUpperCase())
        this.$http.post("CarType/DeleteCarType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    delete this.postInfo.ICON
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrl = _URL.createObjectURL(file);
            this.postInfo.ICON=1
            this.fileItem=file
        }
        return isJPG && isLt2M;
    },
    uploadFun(file){
        //console.log(file)
    },
    addImg(item,type){
        var fd = new FormData();
        fd.append("file", this.fileItem);
        var fname=this.fileItem.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(type=="1"){
                        this.$message.success("已添加")
                    }else{
                        this.$message.success("已修改")
                    }
                    this.imageUrl=""
                    this.updateImgUrl(item,response.data.DATA)
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    updateImgUrl(item,url){
        var sid=""
        if(item.CARTYPESNOWID != undefined){
            sid = item.CARTYPESNOWID
        }else if(item.SNOWID != undefined){
            sid = item.SNOWID
        }
        var params={
            CARTYPESNOWID:sid,
            NAME:item.NAME,
            LIMITSPEED:item.LIMITSPEED,
            DELAYBEHIND:item.DELAYBEHIND,
            DELAYFRONT:item.DELAYFRONT,
            STAYDIS:item.STAYDIS,
            STAYDURA:item.STAYDURA,
            OUTREGIONDIS:item.OUTREGIONDIS,
            OUTREGIONDURA:item.OUTREGIONDURA,
            ICONURL:url,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarType/UpdateCarType", md5_data)
        .then((response) => {
            //console.log(response)
            this.hideLoading()
            this.canDo=true
            if(response.data.SUCCESS){   
                this.postModal=false
                this.getPostList()
            }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
        })
        .catch((error) => {
            this.$message.error("请求出错")
            this.canDo=true
        });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    editAll(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            var d=this.allIds[0]
            console.log(d)
            this.allInfo={
                LIMITSPEED:d.LIMITSPEED,
                NOWORKLIMITSPEED:d.NOWORKLIMITSPEED,
                DELAYBEHIND:d.DELAYBEHIND,
                DELAYFRONT:d.DELAYFRONT,
                STAYDIS:d.STAYDIS,
                STAYDURA:d.STAYDURA,
                OUTREGIONDIS:d.OUTREGIONDIS,
                OUTREGIONDURA:d.OUTREGIONDURA,
            }
            this.allModal=true
        }
    },
    submitAllForm(){
        if (this.canDo) {
            this.$refs.aForm.validate((valid) => {
                if (valid) {
                    this.sendAllFun()
                } else {
                    return false;
                }
            });
        }
    },
    sendAllFun(){
        this.canDo=false
        this.showLoading("请稍后")
        var ids=[]
        for(var i=0;i<this.allIds.length;i++){
            ids.push(this.allIds[i].SNOWID)
        }
        this.allInfo.CARTYPESNOWIDLIST = ids
        var md5_data =this.comjs.requestDataEncrypt(this.allInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.allInfo)).toUpperCase())
        this.$http.post("CarType/UpdateCarTypeList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.allModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
    
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>