import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

//管理员后台页面
import AdminCompanyManage from '../views/AdminPages/AdminCompanyManage.vue'
import AdminUserManage from '../views/AdminPages/AdminUserManage.vue'
import AdminCountManage from '../views/AdminPages/AdminCountManage.vue'

//角色权限
import AdminManage from '../views/RolePower/AdminManage.vue'
import RoleManage from '../views/RolePower/RoleManage.vue'
import ProjectManage from '../views/RolePower/ProjectManage.vue'
import MenuManage from '../views/RolePower/MenuManage.vue'
import AdministrativeDistrict from '../views/RolePower/AdministrativeDistrict.vue'


//人员
import UserType from '../views/UserManage/UserType.vue'
import UserManage from '../views/UserManage/UserManage.vue'
import UserWorkTimeManage from '../views/UserManage/UserWorkTimeManage.vue'
import PositionManage from '../views/UserManage/PositionManage.vue'
import RestManage from '../views/UserManage/RestManage.vue'

//车辆
import CarType from '../views/CarManage/CarType.vue'
import CarManage from '../views/CarManage/CarManage.vue'
import CarWorkTimeManage from '../views/CarManage/CarWorkTimeManage.vue'
import CarOilManage from '../views/CarManage/CarOilManage.vue'
import CarInsuranceManage from '../views/CarManage/CarInsuranceManage.vue'
import CarCameraManage from '../views/CarManage/CarCameraManage.vue'
import CarRepairManage from '../views/CarManage/CarRepairManage.vue'
import CarMaintainManage from '../views/CarManage/CarMaintainManage.vue'
//系统设置
import OperLogManage from '../views/SystemManage/OperLogManage.vue'
import AntiWarning from '../views/SystemManage/AntiWarning.vue'
import ElecRegionManage from '../views/SystemManage/ElecRegionManage.vue'
import WarningManage from '../views/SystemManage/WarningManage.vue'
import SysConfig from '../views/SystemManage/SysConfig.vue'
import ExportTable from '../views/SystemManage/ExportTable.vue'
import RequestManage from '../views/SystemManage/RequestManage.vue'
//巡查对象
import BaiDuMapDemo from '../views/Cleaning/BaiDuMapDemo.vue'
import CleanObjectManage from '../views/Cleaning/CleanObjectManage.vue'
import CleanObjectType from '../views/Cleaning/CleanObjectType.vue'
import CleanObjectCamera from '../views/Cleaning/CleanObjectCamera.vue'
import CleanPlan from '../views/Cleaning/CleanPlan.vue'

//分组
import GroupManage from '../views/Group/GroupManage.vue'


//督查
import ProblemType from '../views/Problem/ProblemType.vue'
import ProblemList from '../views/Problem/ProblemList.vue'
import ProblemLevel from '../views/Problem/ProblemLevel.vue'
import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      //管理员页面
      {
        path: '/AdminCompanyManage',
        name: 'AdminCompanyManage',
        components: {AdminCompanyManage}
      },
      {
        path: '/AdminUserManage',
        name: 'AdminUserManage',
        components: {AdminUserManage}
      },
      {
        path: '/AdminCountManage',
        name: 'AdminCountManage',
        components: {AdminCountManage}
      },
      //角色权限
      {
        path: '/AdminManage',
        name: 'AdminManage',
        components: {AdminManage}
      },
      {
        path: '/MenuManage',
        name: 'MenuManage',
        components: {MenuManage}
      },
      {
        path: '/ProjectManage',
        name: 'ProjectManage',
        components: {ProjectManage}
      },
      {
        path: '/AdministrativeDistrict',
        name: 'AdministrativeDistrict',
        components: {AdministrativeDistrict}
      },
      {
        path: '/RoleManage',
        name: 'RoleManage',
        components: {RoleManage}
      },
      //人员
      {
        path: '/UserType',
        name: 'UserType',
        components: {UserType}
      },
      {
        path: '/UserManage',
        name: 'UserManage',
        components: {UserManage}
      },
      {
        path: '/UserWorkTimeManage',
        name: 'UserWorkTimeManage',
        components: {UserWorkTimeManage}
      },
      {
        path: '/PositionManage',
        name: 'PositionManage',
        components: {PositionManage}
      },
      {
        path: '/RestManage',
        name: 'RestManage',
        components: {RestManage}
      },
      //车辆 
      {
        path: '/CarType',
        name: 'CarType',
        components: {CarType}
      },
      {
        path: '/CarManage',
        name: 'CarManage',
        components: {CarManage}
      },
      {
        path: '/CarWorkTimeManage',
        name: 'CarWorkTimeManage',
        components: {CarWorkTimeManage}
      },
      {
        path: '/CarOilManage',
        name: 'CarOilManage',
        components: {CarOilManage}
      },
      {
        path: '/CarInsuranceManage',
        name: 'CarInsuranceManage',
        components: {CarInsuranceManage}
      },
      {
        path: '/CarCameraManage',
        name: 'CarCameraManage',
        components: {CarCameraManage}
      },
      {
        path: '/CarRepairManage',
        name: 'CarRepairManage',
        components: {CarRepairManage}
      },
      {
        path: '/CarMaintainManage',
        name: 'CarMaintainManage',
        components: {CarMaintainManage}
      },
      //系统设置
      {
        path: '/OperLogManage',
        name: 'OperLogManage',
        components: {OperLogManage}
      },
      {
        path: '/AntiWarning',
        name: 'AntiWarning',
        components: {AntiWarning}
      },
      {
        path: '/ElecRegionManage',
        name: 'ElecRegionManage',
        components: {ElecRegionManage}
      },
      {
        path: '/WarningManage',
        name: 'WarningManage',
        components: {WarningManage}
      },
      {
        path: '/SysConfig',
        name: 'SysConfig',
        components: {SysConfig}
      },
      {
        path: '/ExportTable',
        name: 'ExportTable',
        components: {ExportTable}
      },
      {
        path: '/RequestManage',
        name: 'RequestManage',
        components: {RequestManage}
      },
      
      //巡查对象
      {
        path: '/CleanObjectManage',
        name: 'CleanObjectManage',
        components: {CleanObjectManage}
      },
      {
        path: '/BaiDuMapDemo',
        name: 'BaiDuMapDemo',
        components: {BaiDuMapDemo}
      },
      {
        path: '/CleanObjectType',
        name: 'CleanObjectType',
        components: {CleanObjectType}
      },
      {
        path: '/CleanObjectCamera',
        name: 'CleanObjectCamera',
        components: {CleanObjectCamera}
      },
      {
        path: '/CleanPlan',
        name: 'CleanPlan',
        components: {CleanPlan}
      },
      //分组
      {
        path: '/GroupManage',
        name: 'GroupManage',
        components: {GroupManage}
      },
      //督查
      {
        path: '/ProblemType',
        name: 'ProblemType',
        components: {ProblemType}
      },
      {
        path: '/ProblemList',
        name: 'ProblemList',
        components: {ProblemList}
      },
      {
        path: '/ProblemLevel',
        name: 'ProblemLevel',
        components: {ProblemLevel}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
