<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">分组管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-select v-model="pid" placeholder="项目" filterable clearable class="iw">
                    <el-option v-for="item in project" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del" v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>



            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="groupCheck">
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                    <el-table-column prop="ADDRESS" label="地址"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目"></el-table-column>
                    <el-table-column prop="BINDUSER" label="已绑人员">
                        <template slot-scope="scope">
                            <span>{{ scope.row.BINDUSER.length }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small" style="color:#F56C6C"
                                v-if="role.del">删除</el-button>
                            <el-button @click="bindUserPost(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="role.edit">人员</el-button>
                            <!-- <el-button @click="positionPost(scope.row)" type="text" size="small" style="color:#409EFF">位置</el-button> -->
                            <el-button @click="zbPost(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="role.edit">坐标</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="ADDRESS">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="项目" prop="PROJECTID">
                    <el-select v-model="postInfo.PROJECTID" placeholder="项目" filterable style="width:100%">
                        <el-option v-for="item in project" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="分组绑定人员" :visible.sync="userTableModal" width="1200px" top="50px">
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                <el-button type="danger" @click="unbindPost" class="fr">批量解绑</el-button>
                <el-button type="success" @click="addBindUser" class="fr mr20">添加</el-button>
            </div>
            <el-table :data="userTable" border stripe style="width: 100%" @selection-change="userCheck"
                :height="dialogMaxHeigh">
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column prop="USERNAME" label="姓名"></el-table-column>
                <el-table-column prop="USERTYPE" label="类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.USERTYPE == 0" style="color:#E6A23C;">项目经理</span>
                        <span v-else-if="scope.row.USERTYPE == 1" style="color:#409EFF;">督查员</span>
                        <span v-else-if="scope.row.USERTYPE == 2" style="color:#67C23A;">巡查员</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ISRECEIVEWARNING" label="是否接受预警">
                    <template slot-scope="scope">
                        <span v-if="scope.row.ISRECEIVEWARNING == 0" style="color:#F56C6C;">不接受</span>
                        <span v-else style="color:#67C23A;">接受</span>
                    </template>
                </el-table-column>
                <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="jsPost(scope.row)" type="text" size="small" style="color:#67C23A"
                            v-if="scope.row.ISRECEIVEWARNING == 0">接受预警</el-button>
                        <el-button @click="jjPost(scope.row)" type="text" size="small" style="color:#F56C6C"
                            v-else>不接受预警</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="分组绑定用户" :visible.sync="bindUserModal" width="800px">
            <el-form :model="userInfo" status-icon :rules="userrules" ref="bForm" label-width="100px" class="demo-uForm">
                <el-form-item label="用户" prop="USERIDLIST">
                    <el-select v-model="userInfo.USERIDLIST" placeholder="选择用户" filterable multiple style="width:100%">
                        <el-option v-for="item in userSelectList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="GROUPUSERTYPE">
                    <el-select v-model="userInfo.GROUPUSERTYPE" placeholder="选择用户类型" filterable multiple style="width:100%">
                        <el-option label="巡查员" value="2"></el-option>
                        <el-option label="督查员" value="1"></el-option>
                        <el-option label="项目经理" value="0"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitUserForm()">提交</el-button>
                    <el-button @click="bindUserModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="天地图区域" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"
                :move="false" :draw="true"></TianMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startTDraw()" class="fl">绘制区域</el-button>
                <el-button type="danger" @click="delTDraw()" class="fl mr20">删除区域</el-button>
                <el-input placeholder="请在此处粘贴坐标字符串" autocomplete="off" v-model="polygonStr" style="width:620px;"
                    class="fl mr20" @keyup.enter.native="setMapPolygon()"><i slot="suffix"
                        class="el-input__icon el-icon-finished" style="cursor: pointer;"
                        @click="setMapPolygon()"></i></el-input>
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitTianForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="分组位置" :visible.sync="tianmapPositionModal" width="1100px" :close-on-press-escape="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"
                :move="false" :draw="true"></TianMap>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
import TianMap from "../../components/OpenLayers"
export default {
    name: "PostManage",
    components: {
        TianMap,
    },
    data() {
        var DISPLAYNAME = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入分组名称"));
            } else {
                callback();
            }
        };
        var ADDRESS = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入地址"));
            } else {
                callback();
            }
        };
        var PROJECTID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择项目"));
            } else {
                callback();
            }
        };

        var USERIDLIST = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择用户"));
            } else {
                callback();
            }
        };
        var GROUPUSERTYPE = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择用户类型"));
            } else {
                callback();
            }
        };

        USERIDLIST
        return {
            canDo: true,
            showPagination: false,
            name: "",
            pid: "",

            project: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
                ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
                PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
            },
            checkGroups: [],
            dialogMaxHeigh: 0,

            groupInfo: null,
            userTable: [],
            userTableModal: false,
            checkUsers: [],

            bindUserModal: false,
            userSelectList: [],
            userInfo: {},
            userrules: {
                USERIDLIST: [{ validator: USERIDLIST, trigger: "blur" }],
                GROUPUSERTYPE: [{ validator: GROUPUSERTYPE, trigger: "blur" }],
            },
            checkBindUsers: [],


            //天地图
            tianmapPositionModal: false,
            mapWidth: 1060,
            mapHeight: 600,
            tianmapModal: false,
            tianmap: null,
            TPOLYGON: 0,
            polygonStr: "",
            role: { add: false, edit: false, del: false }
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PageIndex
            this.name = getQueryData.NAME
            this.pid = getQueryData.PROJECTID
        }
        this.showPagination = true
        this.dialogMaxHeigh = h - 300
        this.loadItem = null
        this.getPostList()
        this.getProject()
        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {
        groupCheck(e) {
            var nl = []
            for (var i = 0; i < e.length; i++) {
                nl.push(e[i].SNOWID)
            }
            this.checkGroups = nl
        },
        getProject() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].PARENTID == '0') {
                                this.project.push(list[i])
                            }
                        }
                    }
                })
        },
        getPostList() {
            this.checkGroups = []
            this.showLoading("请稍后")
            var params = {
                PageIndex: this.PageIndex,
                PageSize: this.PageSize,
                NAME: this.name,
                PROJECTID: this.pid,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Group/GetGroupList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        addPost() {
            this.postInfo = {
                DISPLAYNAME: "",
                PROJECTID: "",
                ADDRESS: "",
                LON: "0",
                LAT: "0",
            }
            this.doTypeStr = "添加分组"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("Group/InsertGroup", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            this.postInfo = {
                SNOWID: item.SNOWID,
                DISPLAYNAME: item.DISPLAYNAME,
                PROJECTID: item.PROJECTID,
                ADDRESS: item.ADDRESS,
                LAT: item.LAT,
                LON: item.LON,
            }
            this.doTypeStr = "修改分组"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("Group/UpdateGroup", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList();
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除分组：' + item.DISPLAYNAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel([item.SNOWID])
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(ids) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt({ GROUPIDLIST: ids }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ GROUPIDLIST: ids })).toUpperCase())
            this.$http.post("Group/DeleteGroup", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        delMore() {
            if (this.checkGroups.length == 0) {
                this.$message.error("请至少选择一个分组")
            } else {
                if (this.canDo) {
                    this.$confirm('确定删除选中分组？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.sendDel(this.checkGroups)
                    }).catch(() => {
                        this.$message.info("已取消")
                    });
                }
            }
        },


        //绑定用户
        bindUserPost(item) {
            this.groupInfo = item
            this.getGroupBindUsers()
            this.userTableModal = true
            this.checkUsers = []
        },
        getGroupBindUsers() {
            this.canDo = false
            this.showLoading("请稍后")
            var params = {
                GROUPID: this.groupInfo.SNOWID,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Group/GetBindGroupUserList", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.userTable = response.data.DATA
                    }
                })
        },
        addBindUser() {
            this.userInfo = {
                USERIDLIST: [],
                GROUPUSERTYPE: "",
            }
            this.getUserByProjects()
            this.bindUserModal = true
        },
        getUserByProjects() {
            var params = {
                PROJECTID: this.groupInfo.PROJECTID,
                ISQUIT: "0",
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("User/GetUserList", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var nl = []
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            var has = false
                            for (var j = 0; j < this.userTable.length; j++) {
                                if (list[i].SNOWID == this.userTable[j].USERID) {
                                    has = true
                                    break;
                                }
                            }
                            if (!has) {
                                nl.push(list[i])
                            }
                        }
                        this.userSelectList = nl
                    }
                })
        },
        submitUserForm() {
            if (this.canDo) {
                this.$refs.bForm.validate((valid) => {
                    if (valid) {
                        this.sendBindUsers()
                    } else {
                        return false;
                    }
                });
            }
        },
        sendBindUsers() {
            this.canDo = false
            this.showLoading("请稍后")
            var params = {
                GROUPID: this.groupInfo.SNOWID,
                USERIDLIST: this.userInfo.USERIDLIST,
                GROUPUSERTYPE: parseInt(this.userInfo.GROUPUSERTYPE),
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Group/BindGroupUser", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已绑定")
                        this.bindUserModal = false
                        this.getGroupBindUsers()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        jsPost(e) {
            if (this.canDo) {
                this.canDo = false
                this.showLoading("请稍后")
                var params = {
                    SNOWID: e.SNOWID,
                    ISRECEIVEWARNING: 1,
                }
                var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("Group/UpdateWarningReceiveType", md5_data)
                    .then((response) => {
                        this.canDo = true
                        this.hideLoading()
                        if (response.data.SUCCESS) {
                            this.$message.success("操作成功")
                            this.getGroupBindUsers()
                        } else {
                            this.errorRequest(response.data.DATA, response.data.MSG, true)
                        }
                    })
                    .catch((error) => {
                        this.$message.error("请求出错")
                        this.hideLoading()
                        this.canDo = true
                    });
            }
        },
        jjPost(e) {
            if (this.canDo) {
                this.canDo = false
                this.showLoading("请稍后")
                var params = {
                    SNOWID: e.SNOWID,
                    ISRECEIVEWARNING: 0,
                }
                var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("Group/UpdateWarningReceiveType", md5_data)
                    .then((response) => {
                        this.canDo = true
                        this.hideLoading()
                        if (response.data.SUCCESS) {
                            this.$message.success("操作成功")
                            this.getGroupBindUsers()
                        } else {
                            this.errorRequest(response.data.DATA, response.data.MSG, true)
                        }
                    })
                    .catch((error) => {
                        this.$message.error("请求出错")
                        this.hideLoading()
                        this.canDo = true
                    });
            }
        },
        userCheck(e) {
            var nl = []
            for (var i = 0; i < e.length; i++) {
                nl.push(e[i].USERID)
            }
            this.checkBindUsers = nl
        },
        unbindPost() {
            if (this.checkBindUsers.length == 0) {
                this.$message.error("请至少选择一个用户")
            } else {
                if (this.canDo) {
                    this.$confirm('确定解绑选中用户？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.sendUnBind(this.checkBindUsers)
                    }).catch(() => {
                        this.$message.info("已取消")
                    });
                }
            }
        },
        sendUnBind(ids) {
            this.canDo = false
            this.showLoading("请稍后")
            var params = {
                GROUPID: this.groupInfo.SNOWID,
                USERIDLIST: ids,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Group/UnBindGroupUser", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已解绑")
                        this.getGroupBindUsers()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        tianmapFinish() {
            this.tianmap = this.$refs.tianmap.map
        },
        //位置
        positionPost(item) {
            this.groupInfo = item
            this.tianmapPositionModal = true
            setTimeout(() => {
                if (this.tianmap) {
                    this.$refs.tianmap.clearDraw()
                }
                var t = this.$refs.tianmap.getMapTarget()
                var c = t.view.getCenter()
                var point = c
                if (item.LAT != 0 && item.LON != 0) {
                    point = [item.LON, item.LAT]
                }
                this.initMarker(point)
            }, 500)
        },
        initMarker(point) {
            this.$refs.tianmap.appendDrawPoint(point, true, this.sendChangePoint)
        },
        sendChangePoint(point) {
            console.log(point)
            // var str='POINT('+point[0]+' '+point[1]+')'
            // var params={
            //     SNOWID:this.groupInfo.SNOWID,
            //     ZB:str,
            // }
            // var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            // this.$http.post("Group/UpdateGroupZB", md5_data)
            //     .then((response) => {
            //         if (response.data.SUCCESS) {
            //           //
            //         } 
            //     })
        },

        //区域
        zbPost(item) {
            this.groupInfo = item
            this.tianmapModal = true
            this.polygonStr = ""
            if (this.tianmap) {
                this.$refs.tianmap.clearDraw()
            }
            if (item.ZB && item.ZB.ZBSTR != "") {
                setTimeout(() => {
                    this.setProjectTDZB(item.ZB.ZBSTR)
                }, 500)
            }
        },
        setProjectTDZB(zb) {
            var zbItem = this.$refs.tianmap.getTDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                    if (i == 0) {
                        this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                    }
                }
            } else {
                var paths = []
                if (zbItem.isGroup) {
                    paths = zbItem.arr
                } else {
                    paths = [zbItem.arr]
                }
                var oid = new Date().getTime().toString()
                this.$refs.tianmap.appendFeature('Polygon', oid, paths, false, null)
                this.$refs.tianmap.moveTo(zbItem.arr[0])
            }
        },

        startTDraw() {
            this.$refs.tianmap.drawPolygon(null, null)
        },
        setMapPolygon() {
            var str = this.polygonStr
            if (str.indexOf("MULTIPOLYGON") != -1 || str.indexOf("POLYGON") != -1) {
                var zbItem = this.$refs.tianmap.getTDPathByWKTStr(this.polygonStr)
                if (zbItem.arr.length > 0) {
                    this.$confirm('本次操作会覆盖原先的坐标区域，确定继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.tianmap.clearDraw()
                        if (zbItem.isMulti) {
                            for (var i = 0; i < zbItem.arr.length; i++) {
                                var oids = new Date().getTime().toString() + i
                                this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                                if (i == 0) {
                                    this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                                }
                            }
                        } else {
                            var oid = new Date().getTime().toString()
                            this.$refs.tianmap.appendFeature('Polygon', oid, [zbItem.arr], false, null)
                            this.$refs.tianmap.moveTo(zbItem.arr[0])
                        }
                    })
                } else {
                    this.$message.error("请输入正确的坐标字符串")
                }
            } else {
                this.$message.error("请输入正确的坐标字符串")
            }

        },
        delTDraw() {
            var ps = this.$refs.tianmap.getCheckDrawFeatures()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs.tianmap.delCheckDrawFeatures()
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }
        },
        submitTianForm() {
            var feas = this.$refs.tianmap.getAllDrawFeatures()
            if (feas.length > 0) {
                if (this.groupInfo.ZB) {
                    var edatas = {
                        SNOWID: this.groupInfo.ZB.SNOWID,
                        ZB: this.getTDDrawPolygonsWKTStr(),
                    }
                    this.editGroupZB(edatas)
                } else {
                    var adatas = {
                        SNOWID: this.groupInfo.SNOWID,
                        ZB: this.getTDDrawPolygonsWKTStr(),
                    }
                    this.addGroupZB(adatas)
                }
            } else {
                this.$message.error("请先绘制区域")
            }
        },
        getTDDrawPolygonsWKTStr() {
            var list = this.$refs.tianmap.getAllDrawFeatures()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getGeometry().getCoordinates()
                    console.log(path)
                    var str = this.$refs.tianmap.getWKTByTDPolygon(path[0])
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            console.log(pgStr)
            return pgStr
        },
        addGroupZB(datas) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(datas, this.$store.state.token, this.$md5(this.comjs.sortAsc(datas)).toUpperCase())
            this.$http.post("Group/InsertGroupZB", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已设置")
                        this.tianmapModal = false
                        this.getPostList();
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editGroupZB(datas) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(datas, this.$store.state.token, this.$md5(this.comjs.sortAsc(datas)).toUpperCase())
            this.$http.post("Group/UpdateGroupZB", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已设置")
                        this.tianmapModal = false
                        this.getPostList();
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style ></style>