<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">操作日志管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="cdname" placeholder="请输入菜单名称" class="iw" ></el-input>
                <el-input v-model="czname" placeholder="请输入操作名称" class="iw" ></el-input>
                <el-date-picker v-model="cxtime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  class="iww"></el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="SYSUSERNAME" label="系统管理员"></el-table-column>
                    <el-table-column prop="SYSOPERNAME" label="操作名称"></el-table-column>
                    <el-table-column prop="OPERTIME" label="操作时间"></el-table-column>
                    <el-table-column prop="MENUNAME" label="菜单名称"></el-table-column>
                    <el-table-column prop="ISBACKED" label="是否回滚">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISBACKED==1" style="color:#67C23A">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BACKTIME" label="回滚时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISBACKED==1">{{scope.row.BACKTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="itemPost(scope.row)" type="text" size="small" style="color:#409EFF">操作项</el-button>
                        <el-button @click="backPost(scope.row)"  type="text" size="small" style="color:#E6A23C" v-if="role.edit">回滚</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="操作项集合" :visible.sync="itemModal" width="1200px" top="50px" >
                <el-table :data="itemList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="SNOWID" label="操作详细条目ID"></el-table-column>
                    <el-table-column prop="ENTITYNAME" label="实体名称"></el-table-column>
                    <el-table-column prop="ENTITYID" label="实体ID"></el-table-column>
                    <el-table-column prop="SYSOPERNAME" label="操作名称"></el-table-column>
                    <el-table-column prop="ISBACKED" label="是否回滚">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISBACKED==1" style="color:#67C23A">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BACKTIME" label="回滚时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISBACKED==1">{{scope.row.BACKTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OLDENTITYJSONSTR" label="实体旧数据" width="400"></el-table-column>
                    <el-table-column prop="NEWENTITYJSONSTR" label="实体新数据" width="400"></el-table-column>
                </el-table>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <!-- <el-form-item label="菜单名称">
                    <el-input v-model="postInfo.MENUNAME" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="操作名称">
                    <el-input v-model="postInfo.SYSOPERNAME" autocomplete="off" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="开始时间" prop="OPERSTARTTIMES">
                    <el-date-picker v-model="postInfo.OPERSTARTTIMES" type="datetime" placeholder="选择开始时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="OPERENDTIMES">
                    <el-date-picker v-model="postInfo.OPERENDTIMES" type="datetime" placeholder="选择结束时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PeroLogManage",
  data() {
    var MENUNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入菜单名称"));
      } else {
        callback();
      }
    };
    var SYSOPERNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入操作名称"));
      } else {
        callback();
      }
    };
    var OPERSTARTTIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var OPERENDTIMES = (rule, value, callback) => {
    var ss=this.postInfo.OPERSTARTTIMES.getTime()
      if (value === "") {
        callback(new Error("请选择结束时间"));
      }else if(this.postInfo.OPERSTARTTIMES && this.postInfo.OPERSTARTTIMES!="" && value.getTime()<=ss){
        callback(new Error("结束时间必须大于开始时间"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        showPagination:false,
        cdname:"",
        czname:"",
        cxtime:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            OPERSTARTTIMES: [{ validator: OPERSTARTTIMES, trigger: "blur" }],
            OPERENDTIMES: [{ validator: OPERENDTIMES, trigger: "blur" }],
            // MENUNAME: [{ validator: MENUNAME, trigger: "blur" }],
            // SYSOPERNAME: [{ validator: SYSOPERNAME, trigger: "blur" }],
        },

        dialogMaxHeigh:0,
        itemList:[],
        itemModal:false,

        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PAGEINDEX
        this.cdname=getQueryData.MENUNAME
        this.czname=getQueryData.SYSOPERNAME
    }
    this.showPagination=true
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MENUNAME:this.cdname,
            SYSOPERNAME:this.czname,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("OperLogGroup/GetOperLogGroupList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    backPost(item){
        this.postInfo={
            OPERLOGGROUPSNOWID:item.SNOWID,
            OPERSTARTTIMES:"",
            OPERENDTIMES:"",
            // MENUNAME:item.MENUNAME,
            // SYSOPERNAME:item.SYSOPERNAME,
        }
        this.doTypeStr="日志回滚"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            OPERLOGGROUPSNOWID:this.postInfo.OPERLOGGROUPSNOWID,
            // MENUNAME:this.postInfo.MENUNAME,
            // SYSOPERNAME:this.postInfo.SYSOPERNAME,
            OPERSTARTTIME:this.comjs.getDateStrByDate(this.postInfo.OPERSTARTTIMES),
            OPERENDTIME:this.comjs.getDateStrByDate(this.postInfo.OPERENDTIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("OperLogGroup/CallBackOperLogGroup", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    itemPost(item){
        if(item.ITEMS.length>0){
            this.itemList=item.ITEMS
            this.itemModal=true
        }else{
            this.$message.error("暂无操作项")
        }
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>