<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">车辆保养管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-select @change="selectCarType" v-model="carTypeId" placeholder="选择车辆类型" filterable
                   style="margin-right: 12px">
          <el-option v-for="item in carTypeList" :key="item.SNOWID" :label="item.NAME"
                     :value="item.SNOWID"></el-option>
        </el-select>
        <el-select v-model="carId" placeholder="车辆" filterable clearable class="iwu">
          <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER"
                     :value="item.SNOWID"></el-option>
        </el-select>
        <el-input
            class="iwu" style="margin-right: 12px"
            v-model="money"
            placeholder="请输入使用金额"
            @input="digitalVerification"
        ></el-input>
        <el-button type="primary" @click="searchPost">查询</el-button>
        <el-button type="danger" @click="delMore" class="fr" v-if="role.del" v-show="false">批量删除</el-button>
        <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>

      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="60"></el-table-column>
          <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
          <el-table-column prop="MONEY" label="使用金额"></el-table-column>
          <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
          <el-table-column prop="UPDATETIME" label="更新日期"></el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">
                编辑
              </el-button>
              <el-button @click="delPost(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="role.del">
                删除
              </el-button>
              <el-button @click="imgPost(scope.row)" type="text" size="small" style="color: #409EFF;">图片</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                       :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px" :before-close="close">
      <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
        <el-form-item label="车辆类型" prop="CARTYPE">
          <el-select @change="selectCarType($event,'dialogCarList')" v-model="postInfo.CARTYPEID"
                     placeholder="点击选择车辆类型" filterable
                     style="width:100%">
            <el-option v-for="item in dialogCarTypeList" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆" prop="CARID">
          <el-select v-model="postInfo.CARID" placeholder="点击选择车辆" filterable style="width:100%">
            <el-option v-for="item in dialogCarList" :key="item.SNOWID" :label="item.PLATENUMBER"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用金额" prop="MONEY">
          <el-input v-model="postInfo.MONEY" placeholder="请输入使用金额" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="时间" prop="REPAIRTIME">
          <el-date-picker type="datetime" placeholder="选择时间" v-model="postInfo.REPAIRTIME"
                          style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="车辆保养图片" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true" :before-close="imgClose">
      <div style="overflow:hidden">
        <div class="fileBox">
          <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
            <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
            <div class="fileBtn">
              <span @click="downFile(item.FILEPATH)" style="color:#409EFF">下载</span>
              <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
            </div>
          </div>
          <div v-if="fileList.length==0" class="noFile">还未上传附件，请在下方上传。</div>
        </div>
        <div style="overflow:hidden;">
          <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "CAROIL",
  data() {
    var CARID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆"));
      } else {
        callback();
      }
    };
    var CARTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆类型"));
      } else {
        callback();
      }
    };
    var MONEY = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入使用金额'));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('使用金额只能是数字'));
      } else if (value <= 0) {
        callback(new Error('使用金额必须大于0'));
      } else {
        callback();
      }
    };
    var REPAIRTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    return {
      canDo: true,
      showPagination: false,
      chooseFileModal: false, // 车辆保养图片弹框控制
      carList: [], // 车辆数组
      carTypeList: [], // 车辆类型数组
      dialogCarList: [],
      dialogCarTypeList: [],
      fileList: [],
      nowLP: null,
      carId: "",
      carTypeId: "",
      money: "", // 使用金额

      postList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,


      doTypeStr: "",
      doType: "",
      postModal: false,
      postInfo: {},
      postrules: {
        CARID: [{validator: CARID, trigger: "blur"}],
        CARTYPEID: [{validator: CARTYPEID, trigger: "blur"}],
        MONEY: [{validator: MONEY, trigger: "blur"}],
        REPAIRTIME: [{validator: REPAIRTIME, trigger: "blur"}],
      },
      allIds: [],
      role: {add: false, edit: false, del: false}
    };
  },
  mounted() {
    var getQueryData = this.getQueryData(this.$route.name)
    if (getQueryData != undefined) {
      this.PageIndex = getQueryData.PAGEINDEX
      this.carId = getQueryData.CARID
    }
    this.showPagination = true
    this.loadItem = null
    this.getPostList()
    this.getCarTypeList('carTypeList')
    // this.getCarList()
    this.role = this.setPageRoles(this.$route.path)
    setTimeout(() => {
      this.role = this.setPageRoles(this.$route.path)
    }, 3000)
  },
  methods: {
    // 获取车辆类型数据
    getCarTypeList(type) {
      var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
      this.$http.post("CarType/GetCarTypeList", md5_data)
          .then((response) => {
            if (response.data.SUCCESS) {
              if (type === 'dialogCarTypeList') {
                this.dialogCarTypeList = response.data.DATA
              } else if (type === 'carTypeList') {
                this.carTypeList = response.data.DATA
              }
            }
          })
    },
    getCarList(type, CARTYPEID) {
      var params = {
        CARTYPEID: CARTYPEID
      }
      this.setQueryData(this.$route.name, params)
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Car/GetCarList", md5_data)
          .then((response) => {
            if (response.data.SUCCESS) {
              if (type === 'dialogCarList') {
                this.dialogCarList = response.data.DATA
              } else if (type === 'carList') {
                this.carList = response.data.DATA
              }
            }
          })
    },
    getPostList() {
      this.showLoading("请稍后")
      var params = {
        PAGEINDEX: this.PageIndex,
        PAGESIZE: this.PageSize,
        CARID: this.carId,
        MONEY: this.money, //使用金额
        Platenumber: "", //车牌号
        CarTypeID: this.carTypeId, //车辆类型
        REPAIRTYPE: 1 //1保养2维修
      }
      this.setQueryData(this.$route.name, params)
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("CarRepair/GetCarRepairList", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.postList = response.data.DATA
              this.Total = response.data.TOTALCOUNT
            } else {
              this.postList = []
              this.Total = 0
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.postList = []
            this.Total = 0
          });
    },
    // 选中车辆类型,获取对应的车辆
    selectCarType(row, type = "carList") {
      if (row) {
        this.getCarList(type, row);
      }
    },
    searchPost() {
      this.PageIndex = 1
      this.getPostList()
    },
    addPost() {
      this.postInfo = {
        MONEY: "", //使用金额
        REPAIRTYPE: 1, //1保养2维修
        REPAIRTIME: "", //时间
        CARID: "", //车辆ID
        CARTYPEID: ''
      }
      this.getCarTypeList('dialogCarTypeList')
      this.doTypeStr = "添加车辆保养记录"
      this.doType = "1"
      this.postModal = true
    },
    close() {
      this.postModal = false;
      this.dialogCarTypeList = []
      this.dialogCarList = []
      this.$refs.uForm.resetFields();
    },
    sendAdd() {
      this.canDo = false
      this.showLoading("请稍后")
      var params = {
        MONEY: this.postInfo.MONEY, //使用金额
        REPAIRTYPE: 1, //1保养2维修
        REPAIRTIME: this.postInfo.REPAIRTIME, //时间
        CARID: this.postInfo.CARID //车辆ID
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("CarRepair/InsertCarRepair", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已添加")
              this.postModal = false
              this.getPostList()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    editPost(item) {
      this.getCarTypeList('dialogCarTypeList')
      this.getCarList('dialogCarList', item.CARTYPEID)
      this.postInfo = {
        SNOWID: item.SNOWID,
        MONEY: item.MONEY, //使用金额
        CARTYPEID: item.CARTYPEID,
        REPAIRTYPE: 1, //1保养2维修
        REPAIRTIME: new Date(item.REPAIRTIME), //时间
        CARID: item.CARID //车辆ID
      }
      this.doTypeStr = "修改车辆保养记录"
      this.doType = "2"
      this.postModal = true
    },
    sendEdit() {
      this.canDo = false
      this.showLoading("请稍后")
      var params = {
        SNOWID: this.postInfo.SNOWID,
        REPAIRTIME: this.postInfo.REPAIRTIME, //时间
        MONEY: this.postInfo.MONEY, //使用金额
        CARID: this.postInfo.CARID, //车辆ID
        REPAIRTYPE: 1 //1
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("CarRepair/UpdateCarRepair", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已修改")
              this.postModal = false
              this.getPostList();
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    delPost(item) {
      if (this.canDo) {
        this.$confirm('确定删除车辆保养记录：' + item.PLATENUMBER + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(item.SNOWID)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }

    },
    sendDel(id) {
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt({SNOWID: id}, this.$store.state.token, this.$md5(this.comjs.sortAsc({SNOWID: id})).toUpperCase())
      this.$http.post("CarRepair/DeleteCarRepair", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.postModal = false
              this.getPostList()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    PGChange(e) {
      this.PageIndex = e
      this.getPostList()
    },
    submitPostForm() {
      if (this.canDo) {
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            if (this.doType == "1") {
              this.sendAdd()
            } else if (this.doType == "2") {
              this.sendEdit()
            }
          } else {
            return false;
          }
        });
      }
    },
    handleSelectionChange(e) {
      this.allIds = e
    },
    delMore() {
      if (this.allIds.length == 0) {
        this.$message.error("请至少选择一项")
      } else {
        this.$confirm('确定要删除选中的内容?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var ids = []
          for (var i = 0; i < this.allIds.length; i++) {
            ids.push(this.allIds[i].SNOWID)
          }
          this.delMoreFun(ids)
        })
      }
    },
    delMoreFun(ids) {
      //
    },
    imgPost(item) {
      this.nowLP = item
      // this.getImgById()
      if (item.IDAndUrls) {
        this.fileList = item.IDAndUrls
      }
      this.chooseFileModal = true
    },
    imgClose(){
      this.fileList = []
      this.$refs.upload.clearFiles();
      this.chooseFileModal = false
    },
    downFile(e) {
      window.open(e)
    },
    getImgById() {
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt({SNOWID: this.nowLP.SNOWID}, this.$store.state.token, this.$md5(this.comjs.sortAsc({SNOWID: this.nowLP.SNOWID})).toUpperCase())
      this.$http.post("Compensation/GetCompensationFileList", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.fileList = response.data.DATA
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    delFile(e) {
      if (this.canDo) {
        this.$confirm('确定删除图片？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDelFile(e)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }
    },
    sendDelFile(id) {
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt({SNOWID: id}, this.$store.state.token, this.$md5(this.comjs.sortAsc({SNOWID: id})).toUpperCase())
      this.$http.post("CarRepair/DeleteCarRepairImages", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.getImgById()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    uploadFun(f) {
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("CarRepair/UploadImages?SNOWID="+this.nowLP.SNOWID, fd)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              // this.getImgById()
              this.hideLoading()
              this.$message.success("上传成功")
              this.$refs.upload.clearFiles()

            }else{
              this.hideLoading()
              this.$message.error("上传失败")
            }
          })
          .catch((error) => {
            this.hideLoading()
          });
    },
    // 控制使用金额的输入只能为数值
    digitalVerification(ipt) {
      let data = String(ipt);
      const char = data.charAt(0);
      // 先把非数字的都替换掉
      data = data
          // 转化为数字形式--包含小数，负数
          // 先把非数字的都替换掉，除了数字和.
          .replace(/[^\d.]/g, '')
          // 必须保证第一个为数字而不是.
          .replace(/^\./g, '')
          // 保证只有出现一个.而没有多个.
          .replace(/\.{2,}/g, '.')
          // 保证.只出现一次，而不能出现两次以上
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^0+(\d)/, '$1')
          // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .replace(/^\./, '0.');
      // 如果第一位是负号，则允许添加
      if (char === '-') {
        data = '-' + data;
      }
      this.money = data;
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.allItem{width: 100%;float:left;}
.icss{width: 90%;}

.fileBox{overflow: hidden;padding-bottom: 20px;border-bottom: 1px solid #ADADAD;margin-bottom: 20px;}
.fileTitle{font-size: 18px;font-weight: bold;margin-bottom: 20px;}
.fileTitleA{font-size: 16px;margin-top: 20px;}
.fileItem{width: 100px;float:left;margin-right: 20px;}
.fileItem .fileImg{width: 100px;height: 100px;overflow: hidden;background-color: #F8F8F9;}
.fileBtn span{cursor: pointer;margin-right: 20px;}
.noFile{margin-bottom: 20px;}
</style>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>