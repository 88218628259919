<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">百度地图</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <Map class="map" ref="map" :width="mapWidth" :height="mapHeight" @mapFinish="mapFinish" @setPolygonCount="setPolygonCount"></Map>
            <div style="overflow:hidden;">
                <el-button type="primary" @click="appendMarker">添加中心点</el-button>
                <el-button type="primary" @click="draw">开启绘制</el-button>
                <el-button type="primary" @click="getDrawPolygons">获取绘制的多边形集合</el-button>
                <el-button type="primary" @click="getDrawPolygonsWKTStr">获取多边形WKT</el-button>    
                <el-button type="primary" @click="getPathByWKTStr">通过WKT获取坐标集合</el-button>    
            </div>
        </div>
    </div>
</template>
<script>
var h=document.body.clientHeight
import Map from "../../components/BaiDuMap"
export default {
  name: "baiduMap",
  components:{
    Map,
  },
  data() {
    return {
        canDo:true,

        map:null,
        marker:null,


        mapWidth:1400,
        mapHeight:600,
        
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
     mapFinish(){
        this.map =this.$refs.map.getMap()
     },
     appendMarker(){
        if(this.marker){
            var point = this.marker.getPosition()
            this.map.panTo(point)
        }else{
            this.marker = this.$refs.map.setMarker()
        }
     },

     draw(){
        this.$refs.map.openDraw()
     },
     getDrawPolygons(){
        var list=this.$refs.map.getDrawPolygons()
        for(var i=0;i<list.length;i++){
            console.log(list[i].getPath())
        }
     },
     getDrawPolygonsWKTStr(){
        var list=this.$refs.map.getDrawPolygons()
        var pgStr=""
        var isMutil=false
        if(list.length>0){
            if(list.length==1){
                pgStr="POLYGON"
            }else{
                pgStr="MULTIPOLYGON"
                isMutil=true
            }

            for(var i=0;i<list.length;i++){
                var path=list[i].getPath()
                var str =this.$refs.map.getWKTByBDPolygon(path)
                if(isMutil){
                    if(i==0){
                        pgStr+="("
                    }else{
                        pgStr+=","
                    }
                    pgStr+=str
                }else{
                    pgStr+=str
                }
            }
            if(isMutil){
                    pgStr+=")"
            }
        }
        console.log(pgStr)
        return pgStr 
     },
     getPathByWKTStr(){
        var str= this.getDrawPolygonsWKTStr()
        var path=this.$refs.map.getBDPathByWKTStr(str)
        console.log(path)
     },

     setPolygonCount(num){
        console.log(num)
     }

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>