<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">租户管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入租户名称" class="iw" ></el-input>
               
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ADDRESS" label="地址"></el-table-column>
                    <el-table-column prop="LEGALPERSON" label="法人"></el-table-column>
                    <el-table-column prop="MOBILE" label="手机号"></el-table-column>
                    <!-- <el-table-column prop="IDENTITY" label="租户标识"></el-table-column> -->
                    <el-table-column prop="ADMINLOGINNAME" label="超级管理员账号"></el-table-column>
                    <el-table-column prop="LOCATIONNAME" label="位置信息服务平台账号"></el-table-column>
                    <el-table-column prop="EXPIRATIONDATE" label="使用截止日期">
                        <template slot-scope="scope">
                            <span v-if="scope.row.EXPIRATIONDATE">{{scope.row.EXPIRATIONDATE}}</span>
                            <span v-else>无限期</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="CLTPost(scope.row)" type="text" size="small" style="color:#409EFF">车辆定位类型</el-button>
                            <el-button @click="appPost(scope.row)" type="text" size="small" style="color:#67C23A">appKey设置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="160px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="ADDRESS">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="法人" prop="LEGALPERSON">
                    <el-input v-model="postInfo.LEGALPERSON" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="MOBILE">
                    <el-input v-model="postInfo.MOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="租户标识" prop="IDENTITY" v-if="doType=='1'">
                    <el-input v-model="postInfo.IDENTITY" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="管理员登录账号" prop="ADMINLOGINNAME" v-if="doType=='1'">
                    <el-input v-model="postInfo.ADMINLOGINNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账号初始密码" prop="ADMINPASSWORD" v-if="doType=='1'">
                    <el-input v-model="postInfo.ADMINPASSWORD" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="位置信息服务平台账号" prop="LOCATIONNAME">
                    <el-input v-model="postInfo.LOCATIONNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="位置信息服务平台密码" prop="LOCATIONPASSWORD">
                    <el-input v-model="postInfo.LOCATIONPASSWORD" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用截止日期">
                    <el-date-picker v-model="postInfo.EXPIRATIONDATE" type="date" placeholder="选择使用截止日期" clearable style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="carLocationTypeModal" width="1200px" :append-to-body="true" top="50px">
          <CLT :style="{height:dialogMaxHeigh+'px'}" :TOKEN="tokenstr" ROTE="0" ref="clt"></CLT>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="appKeyModal" width="1200px" :append-to-body="true" top="50px">
          <KEY :style="{height:dialogMaxHeigh+'px'}" :TOKEN="tokenstr" ROTE="0" ref="clt"></KEY>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import CLT from "../../components/CarLocationType"
import KEY from "../../components/AppKey"
export default {
  name: "PostManage",
  components:{
    CLT,
    KEY,
  },
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var ADDRESS = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入地址"));
      } else {
        callback();
      }
    };
    var LEGALPERSON = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入法人"));
      } else {
        callback();
      }
    };
    var MOBILE = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var ADMINLOGINNAME = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入管理员登录账号"));
      } else {
        callback();
      }
    };
    var ADMINPASSWORD = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入管理员账号密码"));
      } else {
        callback();
      }
    };
    // var IDENTITY = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请输入租户标识"));
    //   } else {
    //     callback();
    //   }
    // };
    var EXPIRATIONDATE = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择使用截止日期"));
      } else {
        callback();
      }
    };

    var LOCATIONNAME= (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入位置信息服务平台账号"));
      } else {
        callback();
      }
    };

    var LOCATIONPASSWORD= (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入位置信息服务平台密码"));
      } else {
        callback();
      }
    };

    return {
        dialogMaxHeigh:0,
        canDo:true,
        showPagination:false,
        name:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
            LEGALPERSON: [{ validator: LEGALPERSON, trigger: "blur" }],
            MOBILE: [{ validator: MOBILE, trigger: "blur" }],
            EXPIRATIONDATE: [{ validator: EXPIRATIONDATE, trigger: "blur" }],
            // IDENTITY: [{ validator: IDENTITY, trigger: "blur" }],
            ADMINLOGINNAME: [{ validator: ADMINLOGINNAME, trigger: "blur" }],
            ADMINPASSWORD: [{ validator: ADMINPASSWORD, trigger: "blur" }],
            LOCATIONNAME: [{ validator: LOCATIONNAME, trigger: "blur" }],
            LOCATIONPASSWORD: [{ validator: LOCATIONPASSWORD, trigger: "blur" }],
        },

        tokenstr:"",
        carLocationTypeModal:false,
        appKeyModal:false,
    };
  },
  mounted() {
    this.dialogMaxHeigh = h-200
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            NAME:this.name,
            ISPAGE:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MainDb/GetCompanyList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            MOBILE:"",
            LEGALPERSON:"",
            ADDRESS:"",
            EXPIRATIONDATE:null,
            // IDENTITY:"",
            ADMINLOGINNAME:"",
            ADMINPASSWORD:"",
            LOCATIONNAME:"",
            LOCATIONPASSWORD:"",
        }
        this.doTypeStr="添加租户"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var t=null
        if(this.postInfo.EXPIRATIONDATE){
            t=this.comjs.getDateMinStrByDate(this.postInfo.EXPIRATIONDATE)
        }
        var params={
            COMPANYSNOWID:this.postInfo.SNOWID,
            NAME:this.postInfo.NAME,
            MOBILE:this.postInfo.MOBILE,
            LEGALPERSON:this.postInfo.LEGALPERSON,
            ADDRESS:this.postInfo.ADDRESS,
            EXPIRATIONDATE:t,
            // IDENTITY:this.postInfo.IDENTITY,
            ADMINLOGINNAME:this.postInfo.ADMINLOGINNAME,
            ADMINPASSWORD:this.postInfo.ADMINPASSWORD,
            LOCATIONNAME:this.postInfo.LOCATIONNAME,
            LOCATIONPASSWORD:this.postInfo.LOCATIONPASSWORD,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MainDb/CreateCompany", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var t=null
        if(item.EXPIRATIONDATE){
                t=new Date(item.EXPIRATIONDATE)
        }
        this.postInfo={
            COMPANYSNOWID:item.SNOWID,
            NAME:item.NAME,
            MOBILE:item.MOBILE,
            LEGALPERSON:item.LEGALPERSON,
            ADDRESS:item.ADDRESS,
            EXPIRATIONDATE:t,
            LOCATIONNAME:item.LOCATIONNAME,
            LOCATIONPASSWORD:item.LOCATIONPASSWORD,
        }
        this.doTypeStr="修改租户"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var t=null
        if(this.postInfo.EXPIRATIONDATE){
            t=this.comjs.getDateMinStrByDate(this.postInfo.EXPIRATIONDATE)
        }
        var params={
            COMPANYSNOWID:this.postInfo.COMPANYSNOWID,
            NAME:this.postInfo.NAME,
            MOBILE:this.postInfo.MOBILE,
            LEGALPERSON:this.postInfo.LEGALPERSON,
            ADDRESS:this.postInfo.ADDRESS,
            EXPIRATIONDATE:t,
            LOCATIONNAME:this.postInfo.LOCATIONNAME,
            LOCATIONPASSWORD:this.postInfo.LOCATIONPASSWORD,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MainDb/EditCompany", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除租户：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({COMPANYSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({COMPANYSNOWID:id})).toUpperCase())
        this.$http.post("MainDb/DeleteCompany", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrl = _URL.createObjectURL(file);
            this.postInfo.ICON=1
            this.fileItem=file
        }
        return isJPG && isLt2M;
    },
    uploadFun(file){
        //console.log(file)
    },
    addImg(item,type){
        var fd = new FormData();
        fd.append("file", this.fileItem);
        var fname=this.fileItem.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(type=="1"){
                        this.$message.success("已添加")
                    }else{
                        this.$message.success("已修改")
                    }
                    this.imageUrl=""
                    this.updateImgUrl(item,response.data.DATA)
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    updateImgUrl(item,url){
        var params=item
        if(params.USERSNOWID==undefined){
            params.USERSNOWID=item.SNOWID
        }
        params.PHOTOIMAGE=url
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("User/UpdateUser", md5_data)
        .then((response) => {
            //console.log(response)
            this.hideLoading()
            this.canDo=true
            if(response.data.SUCCESS){   
                this.postModal=false
                this.getPostList()
            }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
        })
        .catch((error) => {
            this.$message.error("请求出错")
            this.canDo=true
        });
    },

    CLTPost(item){
        var modalName="clt"
        console.log(item)
    },
    appPost(item){
        var modalName="key"
        console.log(item)
    },
    getUserTokenByCompany(){
        // tokenstr:"",
        // carLocationTypeModal:false,
        // appKeyModal:false,
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>