<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">人员工作时间管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="typeId" placeholder="用户类型"  filterable  clearable class="iwu">
                        <el-option v-for="item in userTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="projectId" placeholder="所在项目"  filterable  clearable class="iwu">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                <el-button type="primary" @click="downloadTemplet"  class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr mr10" v-if="role.add">导入模板</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="DUTYNAME" label="人员类型"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目"></el-table-column>
                    <el-table-column prop="STIME" label="开始时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.STIME.split(" ")[1]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ETIME" label="结束时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.ETIME.split(" ")[1]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="人员类型" prop="DUTYID">
                    <el-select  v-model="postInfo.DUTYID" placeholder="点击选择人员类型" filterable style="width:100%">
                            <el-option v-for="item in userTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目" prop="PROJECTID">
                    <el-select  v-model="postInfo.PROJECTID" placeholder="点击选择项目" filterable style="width:100%">
                            <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="STIMES">
                    <el-time-picker v-model="postInfo.STIMES" type="datetime" placeholder="选择开始时间" style="width:100%"></el-time-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="ETIMES">
                    <el-time-picker v-model="postInfo.ETIMES" type="datetime" placeholder="选择结束时间" style="width:100%"></el-time-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "UserWorkTime",
  data() {
    var DUTYID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择人员类型"));
      } else {
        callback();
      }
    };
    var PROJECTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择项目"));
      } else {
        callback();
      }
    };
    var STIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择开始时间"));
      } else {
        callback();
      }
    };
    var ETIMES = (rule, value, callback) => {
    var ss=this.postInfo.STIMES.getTime()
      if (value === "") {
        callback(new Error("请选择结束时间"));
      }else if(this.postInfo.STIMES && this.postInfo.STIMES!="" && value.getTime()<=ss){
        callback(new Error("结束时间必须大于开始时间"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        showPagination:false,
        typeId:"",
        projectId:"",
        projectList:[],
        userTypeList:[],


        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            DUTYID: [{ validator: DUTYID, trigger: "blur" }],
            PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
            STIMES: [{ validator: STIMES, trigger: "blur" }],
            ETIMES: [{ validator: ETIMES, trigger: "blur" }],
        },
        chooseFileModal:false,
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.typeId=getQueryData.DUTYID
        this.projectId=getQueryData.PROJECTID
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getProject()
    this.getUseType()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getProject(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        if(list[i].PARENTID=='0'){
                            this.projectList.push(list[i])
                        }
                    }
                } 
            })
    },
    getUseType(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Duty/GetDutyList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userTypeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DUTYID:this.typeId,
            PROJECTID:this.projectId,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserWorkTime/GetUserWorkTimeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            DUTYID:"",
            PROJECTID:"",
            STIMES:"",
            ETIMES:"",
        }
        this.doTypeStr="添加工作时间"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            DUTYID:this.postInfo.DUTYID,
            PROJECTID:this.postInfo.PROJECTID,
            STIME:this.comjs.getDateStrByDate(this.postInfo.STIMES),
            ETIME:this.comjs.getDateStrByDate(this.postInfo.ETIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserWorkTime/InsertUserWorkTime", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            USERWORKTIMESNOWID:item.SNOWID,
            DUTYID:item.DUTYID,
            PROJECTID:item.PROJECTID,
            STIMES:new Date(item.STIME),
            ETIMES:new Date(item.ETIME),
        }
        this.doTypeStr="修改工作时间"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            USERWORKTIMESNOWID:this.postInfo.USERWORKTIMESNOWID,
            DUTYID:this.postInfo.DUTYID,
            PROJECTID:this.postInfo.PROJECTID,
            STIME:this.comjs.getDateStrByDate(this.postInfo.STIMES),
            ETIME:this.comjs.getDateStrByDate(this.postInfo.ETIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserWorkTime/UpdateUserWorkTime", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除工作时间？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({USERWORKTIMESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({USERWORKTIMESNOWID:id})).toUpperCase())
        this.$http.post("UserWorkTime/DeleteUserWorkTime", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    downloadTemplet(){
      window.open("file/人员工作时间模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("UserWorkTime/UploadExcel?token="+this.$store.state.token, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.chooseFileModal=false
             this.PageIndex=1
             this.getPostList()
             if(response.data.DATA && response.data.DATA!=""){
                this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        window.open(response.data.DATA)
                    })
             }else{
                this.$message.success("导入完成")
             }
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>