<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">调休管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="agentId" placeholder="代理人"  filterable  clearable class="iwsm">
                        <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="userId" placeholder="调休人"  filterable  clearable class="iwsm">
                        <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="time" type="datetime" placeholder="选择调休时间" class="iwsm"></el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="USERNAME" label="调休人"></el-table-column>
                    <el-table-column prop="AGENTNAME" label="代理人"></el-table-column>
                    <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="调休人" prop="USERID">
                    <el-select  v-model="postInfo.USERID" placeholder="点击选择调休人" filterable  style="width:100%">
                            <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="代理人" prop="AGENTID">
                    <el-select  v-model="postInfo.AGENTID" placeholder="点击选择代理人" filterable  style="width:100%">
                            <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="STARTTIMES">
                    <el-date-picker v-model="postInfo.STARTTIMES" type="datetime" placeholder="选择开始时间" :clearable="false" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="ENDTIMES">
                    <el-date-picker v-model="postInfo.ENDTIMES" type="datetime" placeholder="选择结束时间" :clearable="false" style="width:100%"></el-date-picker>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "RestManage",
  data() {
    var AGENTID = (rule, value, callback) => {
      var  txr=this.postInfo.USERID
      if(value != "" && value == txr){
        callback(new Error("代理人不能和调休人相同"));
      } else {
        callback();
      }
    };
    var USERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择调休人"));
      }else {
        callback();
      }
    };
    var STARTTIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择开始时间"));
      } else if(this.postInfo.ENDTIMES !="" && this.postInfo.ENDTIMES.getTime()<= value.getTime()){
        callback(new Error("开始时间必须小于结束时间"));
      }else {
        callback();
      }
    };
    var ENDTIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择结束时间"));
      }else if(this.postInfo.STARTTIMES !="" && this.postInfo.STARTTIMES.getTime()>= value.getTime()){
        callback(new Error("结束时间必须大于开始时间"));
      }else {
        callback();
      }
    };
    return {
        canDo:true,
        showPagination:false,
        agentId:"",
        userId:"",
        time:"",

        UserList:[],
        TypeList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            AGENTID: [{ validator: AGENTID, trigger: "blur" }],
            USERID: [{ validator: USERID, trigger: "blur" }],
            STARTTIMES: [{ validator: STARTTIMES, trigger: "blur" }],
            ENDTIMES: [{ validator: ENDTIMES, trigger: "blur" }],
        },
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.userId=getQueryData.USERID
        this.agentId=getQueryData.AGENTID
        this.time=getQueryData.SEARCHTIME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getUserList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
    
  },
  methods: {
    getUserList(){
        var md5_data =this.comjs.requestDataEncrypt({ISQUIT:"0",}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ISQUIT:"0",})).toUpperCase())
        this.$http.post("User/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.UserList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var  time=""
        if(this.time && this.time!=null){
            time= this.comjs.getDateStrByDate(this.time)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            USERID:this.userId,
            AGENTID:this.agentId,
            SEARCHTIME:time,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserRest/GetUserRestList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            AGENTID:"",
            USERID:"",
            STARTTIMES:"",
            ENDTIMES:"",
        }
        this.doTypeStr="添加人员调休"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            AGENTID:this.postInfo.AGENTID,
            USERID:this.postInfo.USERID,
            STARTTIME:this.comjs.getDateStrByDate(this.postInfo.STARTTIMES),
            ENDTIME:this.comjs.getDateStrByDate(this.postInfo.ENDTIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserRest/InsertUserRest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            USERRESTSNOWID:item.SNOWID,
            AGENTID:item.AGENTID,
            USERID:item.USERID,
            STARTTIMES:new Date(item.STARTTIME),
            ENDTIMES:new Date(item.ENDTIME),
        }
        this.doTypeStr="修改人员调休"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            USERRESTSNOWID:this.postInfo.USERRESTSNOWID,
            AGENTID:this.postInfo.AGENTID,
            USERID:this.postInfo.USERID,
            STARTTIME:this.comjs.getDateStrByDate(this.postInfo.STARTTIMES),
            ENDTIME:this.comjs.getDateStrByDate(this.postInfo.ENDTIMES),
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserRest/UpdateUserRest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除调休？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({USERRESTSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({USERRESTSNOWID:id})).toUpperCase())
        this.$http.post("UserRest/DeleteUserRest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>