<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">报警类型管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-button type="success" @click="resetPost" class="fr">重置报警类型</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CODE" label="代码"></el-table-column>
                    <el-table-column prop="RESTISVALID" label="是否调休过滤">
                        <template slot-scope="scope">
                            <span v-if="scope.row.RESTISVALID==1" style="color:#67C23A;">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="200" v-if="role.edit">
                        <template slot-scope="scope">
                          <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <!-- <el-button @click="sendEdit(scope.row.SNOWID,1)" v-if="scope.row.RESTISVALID==0" type="text" size="small" style="color:#409EFF" >调休过滤</el-button>
                            <el-button @click="sendEdit(scope.row.SNOWID,0)" v-else  type="text" size="small" style="color:#E6A23C">调休不过滤</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination> -->
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1000px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="是否调休过滤">
                    <el-radio v-model="postInfo.RESTISVALID" label="1">是</el-radio>
                    <el-radio v-model="postInfo.RESTISVALID" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="图标" prop="ICON">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFun"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "RestManage",
  data() {
    var ICON = (rule, value, callback) => {
      if (value == 0) {
          callback(new Error("请选择图标"));
      } else {
          callback();
      }
    };
    return {
        canDo:true,
        fileBaseUrl:"",
        showPagination:false,
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        role:{add:false,edit:false,del:false},


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ICON: [{ validator: ICON, trigger: "blur" }],
        },
        doData:null,
        imageUrl:"",
        fileItem:null,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
    }
    this.showPagination=true
    this.getFileBaseUrl()
    this.loadItem=null
    this.getPostList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getFileBaseUrl(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("HomeScreen/GetFilesUrl", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    this.fileBaseUrl=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var  time=""
        if(this.time && this.time!=null){
            time= this.comjs.getDateStrByDate(this.time)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("WarningType/GetWarningTypeList?token="+this.$store.state.token, md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },

    editPost(item){
        var sc=""
        var icon=0
        if(item.ICONURL && item.ICONURL!=this.fileBaseUrl){
            sc=item.ICONURL
            icon=1
        }
        this.postInfo={
            WARNINGTYPESNOWID:item.SNOWID,
            RESTISVALID:item.RESTISVALID.toString(),
            ICONURL:sc,
            ICON:icon,
        }
        this.imageUrl=sc
        this.fileItem=null
        this.doTypeStr="修改"+item.NAME
        this.postModal=true
    },


    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("WarningType/UpdateWarningType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")  
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    delete this.postInfo.ICON
                    this.doData=this.postInfo
                    if(this.fileItem){
                      this.addImg()
                    }else{
                      this.sendEdit()
                    }
                    
                } else {
                    return false;
                }
            });
        }
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrl = _URL.createObjectURL(file);
            this.postInfo.ICON=1
            this.fileItem=file
        }
        return isJPG && isLt2M;
    },
    uploadFun(file){
        //console.log(file)
    },
    addImg(){
        this.canDo=false
        this.showLoading("请稍后")
        var fd = new FormData();
        fd.append("file", this.fileItem);
        var fname=this.fileItem.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.doData.ICONURL=response.data.DATA
                    this.updateImgUrl()
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
          });
    },
    updateImgUrl(){
        var md5_data =this.comjs.requestDataEncrypt(this.doData, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.doData)).toUpperCase())
        this.$http.post("WarningType/UpdateWarningType", md5_data)
        .then((response) => {
            //console.log(response)
            this.hideLoading()
            this.canDo=true
            if(response.data.SUCCESS){ 
                this.$message.success("已修改")  
                this.postModal=false
                this.getPostList()
            }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
        })
        .catch((error) => {
            this.$message.error("请求出错")
            this.canDo=true
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    resetPost(){
        if(this.canDo){
            this.$confirm('确定重置报警类型？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendReset()
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendReset(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("WarningType/InitWarningType?token="+this.$store.state.token, md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已重置")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>