<template>
    <div class="views-main">
      <div class="views-main-title">
        <p class="txt">菜单管理</p>
      </div>
      <div class="tableBox" style="top:50px;">
        <div class="views-main-search">
          <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
          <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
          <el-button type="success" @click="addDep" class="ml10" v-if="role.add">添加菜单</el-button>
          <el-button type="success" @click="getTree" class="fr">查询</el-button>
        </div>
        <div class="views-main-body">
            <el-tree :props="props"  :data="tree" ref="tree" node-key="SNOWID"  :filter-node-method="filterNode" draggable :allow-drop="allowDrop" @node-drop="handleDrop" >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <!-- <el-image src="data.IMAGEURL" class="treeIcon"></el-image> -->
                <span class="treeName">{{ node.label }}</span>
                <span class="treeTxt">{{data.LINKURL}}</span>
                <span class="treeTxt" v-if="data.ISVALID==1" style="color:#19be6b">启用</span>
                <span class="treeTxt" v-else style="color: #ed4014;">禁用</span>
                <span style="margin-left: 50px;">
                  <el-button type="success" size="mini" @click.stop="() => append(node, data)" v-if="role.add">添加</el-button>
                  <el-button type="warning" size="mini" @click.stop="() => edit(node, data)" v-if="role.edit">编辑</el-button>
                  <el-button type="danger" size="mini" @click.stop="() => remove(node, data)" v-if="role.del">删除</el-button>
                </span>
              </span>
            </el-tree>
        </div>
      </div>
      <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="800px">
            <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="200px" class="demo-DepForm" >
                <el-form-item label="名称" prop="NAME">
                        <el-input v-model="DepInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="跳转链接">
                        <el-input v-model="DepInfo.LINKURL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图标（参考：ElementUI）">
                        <el-input v-model="DepInfo.IMAGEURL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否有效">
                    <el-radio v-model="DepInfo.ISVALID" label="1">有效</el-radio>
                    <el-radio v-model="DepInfo.ISVALID" label="0">无效</el-radio>
                </el-form-item>
                <!-- <el-form-item label="菜单图标">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFun"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" @click="submitDepForm()">提交</el-button>
                    <el-button @click="closeDepForm()">取消</el-button>
                </el-form-item>
            </el-form>
      </el-dialog>
    </div>
  </template>
  <script>
  var h=document.body.clientHeight
  export default {
    name: "MENU",
    data() {
      var NAME = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入名称"));
        } else {
          callback();
        }
      };
    //   var LINKURL= (rule, value, callback) => {
    //     if (value === "") {
    //       callback(new Error("请输入跳转链接"));
    //     } else {
    //       callback();
    //     }
    //   };
      var IMAGEURL = (rule, value, callback) => {
        if (value == 0) {
            callback(new Error("请输入图标名称"));
        } else {
            callback();
        }
      };


      return {
        tree:[],
        //资料库模块
        props: {
            label: 'NAME',
            children: 'ListMenu',
            // isLeaf: 'leaf'
        },
        filterText:"",
  
        DepModalType:"",
        DepModalTitle:"",
  
        DepParentID:"",
        DepParentNAME:"",   
  
        DepModal:false,
        DepInfo:{},
        DepRules: {
          NAME: [{ validator: NAME, trigger: "blur" }],
        //   LINKURL: [{ validator: LINKURL, trigger: "blur" }],
            IMAGEURL:[{ validator: IMAGEURL, trigger: "blur" }],
        },
        DepEditItem:null,
        canDo:true,
        auditText:"",

        imageUrl:"",
        fileItem:null,


        childTreeList:[],

        role:{add:false,edit:false,del:false},
      };
    },
    mounted() {
        this.dialogMaxHeigh = h -300
        this.loadItem=null
        this.getTrees()
        this.role=this.setPageRoles(this.$route.path)
        setTimeout(()=>{
            this.role=this.setPageRoles(this.$route.path)
        },3000)
    },
    methods: {
        getTrees(){
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Menu/GetMenuTree?ParentID=0&token="+this.$store.state.token, md5_data)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.tree=response.data.DATA
                
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },  
    
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        sendQuery(){
            var val=this.filterText
            this.$refs.tree.filter(val)
        },
        closeDepForm(){
            this.DepModal=false
        },
        submitDepForm(){
            if (this.canDo) {
                this.$refs.DepForm.validate((valid) => {
                if (valid) {
                    this.DepInfo.ISVALID = parseInt(this.DepInfo.ISVALID)
               
                    if(this.DepModalType=="1"){
                    this.appendDep()
                    }else if(this.DepModalType=="2"){
                    this.editDep()  
                    }
                } else {
                return false;
                }
            });
            }
        },
        appendDep(){
            //console.log(this.DepInfo)
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
            this.$http.post("Menu/InsertMenu", md5_data)
            .then((response) => {
                //console.log(response)
                    this.hideLoading()
                    this.canDo=true
                    this.DepModal=false
                    if(response.data.SUCCESS){   
                    var data=response.data.DATA
                    var  pid=null
                    if(this.DepParentID!="0"){
                        pid=this.DepParentID
                    }
                    this.$refs.tree.append(data,pid) 
                    this.DepEditItem=data
                    if(this.fileItem){
                        this.addImg(data,"1")
                    }
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },
        editDep(){
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
            this.$http.post("Menu/UpdateMenu", md5_data)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                this.DepModal=false
                if(response.data.SUCCESS){   
                  
                    this.$set(this.DepEditItem,'NAME',this.DepInfo.NAME)
                    this.$set(this.DepEditItem,'ISVALID',this.DepInfo.ISVALID.toString())
                    this.$set(this.DepEditItem,'LINKURL',this.DepInfo.LINKURL)
                    if(this.fileItem){
                        this.addImg(this.DepEditItem,"2")
                    }
                    this.$message.success("编辑成功")
                }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },
        addDep(){
            this.DepInfo={
                PARENTID:"0",
                NAME:"",
                ISVALID:"1",
                LINKURL:"",
                IMAGEURL:"",  
                ICON:0,
            },
            this.DepModalType="1"
            this.DepParentID="0"
            this.imageUrl=""
            this.fileItem=null
            this.DepModalTitle="添加菜单"
            this.DepModal=true
        },
        append(node, data){
            //console.log(data)
            this.DepInfo={
                PARENTID:data.SNOWID,
                NAME:"",
                ISVALID:"1",
                LINKURL:"",
                IMAGEURL:"",  
                ICON:0,
            },
            this.DepModalType="1"
            this.DepParentID=data.SNOWID
            this.imageUrl=""
            this.fileItem=null
            this.DepModalTitle="添加菜单"
            this.DepModal=true
        },
        edit(node, data){
            var icon =0
            if(data.LINKURL && data.LINKURL!=""){
                this.imageUrl=data.LINKURL
                icon=1
            }
            this.fileItem=null
            this.DepInfo={
                MENUSNOWID:data.SNOWID,
                SORT:data.SORT,
                NAME:data.NAME,
                PARENTID:data.PARENTID,
                ISVALID:data.ISVALID.toString(),
                LINKURL:data.LINKURL,
                IMAGEURL:data.IMAGEURL,  
                ICON:icon,
            },
            this.DepEditItem=data
            this.DepModalType="2"
            this.DepParentID=data.PARENTID
            //this.DepParentNAME=data.pname
            this.DepModalTitle="编辑菜单"
            this.DepModal=true
    
        },
        remove(node, data){
            if(this.canDo){
            this.$confirm('确定删除'+data.NAME+'及其全部的子菜单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.delDep(data)
                }).catch(() => {
                this.$message.info("已取消")    
                });
            }
        },
        delDep(data){
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt({MENUSNOWID:data.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({MENUSNOWID:data.SNOWID})).toUpperCase())
            this.$http.post("Menu/DeleteMenu", md5_data)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.$refs.tree.remove(data)
                    this.$message.success("删除成功")
                }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },

        allowDrop(draggingNode, dropNode, type) {
            console.log(draggingNode)
            if (draggingNode.level === dropNode.level) {
                if (draggingNode.data.PARENTID === dropNode.data.PARENTID) {
                    return type === 'prev' || type === 'next'
                }else{
                    return false;
                }
            } else {
                return false;
            }
        },

        handleDrop(draggingNode, dropNode, dropType, ev) {
                var  list =[]
                var nl=[]
                if(draggingNode.level == 1){
                    list =this.tree
                }else{
                    this.childTreeList=[]
                    this.getChildById(draggingNode.data.PARENTID,this.tree)
                    list =this.childTreeList
                }
               
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    nl.push({
                        MENUSNOWID:d.SNOWID,
                        SORT:(i+1),
                        PARENTID:d.PARENTID,
                        //NAME:d.NAME,
                    })
                }
                this.sortMenu(nl)
        },
        getChildById(id,list){
            if(this.childTreeList.length==0){
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    if(d.SNOWID == id){
                        this.childTreeList = d.ListMenu
                        break;
                    }else{
                        if(d.ListMenu.length>0){
                            this.getChildById(id,d.ListMenu)
                        }
                    }
                }
            }
        },
        sortMenu(list){
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt(list, this.$store.state.token,this.$md5(this.comjs.sortAsc(list)).toUpperCase())
            this.$http.post("Menu/UpdateMenuListForSort", md5_data)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    //this.$message.success("删除成功")
                }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },

        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('图片只能是 JPG/PNG  格式!');
            }else if (!isLt2M) {
            this.$message.error('图片大小不能超过 2MB!');
            }else{
                var _URL = window.URL || window.webkitURL;
                this.imageUrl = _URL.createObjectURL(file);
                this.DepInfo.ICON=1
                this.fileItem=file
            }
            return isJPG && isLt2M;
        },
        uploadFun(file){
            //console.log(file)
        },
        addImg(item,type){
            var fd = new FormData();
            fd.append("file", this.fileItem);
            var fname=this.fileItem.name
            this.$upload.post("Image/UploadImage?NAME="+fname, fd)
                .then((response) => {
                    // console.log("上传图标")
                    // console.log(response)
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        if(type=="1"){
                            this.$message.success("已添加")
                        }else{
                            this.$message.success("已修改")
                        }
                        this.DepModal=false
                        this.imageUrl=""
                        this.updateImgUrl(item,response.data.DATA)
                    }else{
                        this.hideLoading()
                        this.$message.error("上传失败")
                    }
                })
                .catch((error) => {
                this.hideLoading()
            });
        },
        updateImgUrl(item,url){
            var params={
                MENUSNOWID:item.SNOWID,
                NAME:item.NAME,
                SORT:item.SORT,
                IMAGEURL:url,
                ISVALID:item.ISVALID,
                LINKURL:item.LINKURL,
                PARENTID:item.PARENTID,
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Menu/UpdateMenu", md5_data)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.$set(this.DepEditItem,'IMAGEURL',params.IMAGEURL)
                }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.canDo=true
            });
        },
        getTree(){
            console.log(this.tree)
        }
    },
    computed: {
    },
    watch: {
    },
  };
  </script>
  <style >
    .el-tree-node{margin-top: 20px;}
    .treeIcon{vertical-align: middle;margin-right: 10px;}
    .treeName{font-size: 16px;margin-right: 5px;vertical-align: middle;}
    .treeTxt{font-size: 14px;margin-left: 10px;vertical-align: middle;}

    .funTreeRow{width: 500px;}
    .funtreeName{font-size: 15px;margin-right: 30px;}
    .funItemList{float:right;}
    .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
    .custom-tree-node{display: block;}
    .el-checkbox{margin-right: 15px!important;}
    .el-checkbox__label{padding-left: 5px!important;}
    .avatar{width:100%;}
  </style>
  