<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定位器管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-input v-model="imei" placeholder="请输入IMEI" class="iw" ></el-input>
                <!-- <el-select v-model="typeId" placeholder="定位器类型"  filterable  clearable class="iwu">
                        <el-option v-for="item in TypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select> -->
                <el-select v-model="isOnline" placeholder="在线状态"  filterable  clearable class="iwu">
                        <el-option label="在线" value="1" ></el-option>
                        <el-option label="离线" value="0" ></el-option>
                </el-select>
                <el-select v-model="bindUser" placeholder="是否绑定人员"  filterable  clearable class="iwu">
                        <el-option label="是" value="1" ></el-option>
                        <el-option label="否" value="0" ></el-option>
                </el-select>
                <el-select v-model="userId" placeholder="选择已绑人员"  filterable  clearable class="iwu" v-if="bindUser=='1'">
                        <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="primary" @click="downloadTemplet"  class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr mr10" v-if="role.add">导入模板</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>


                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="IMEI" label="IMEI"></el-table-column>
                    <!-- <el-table-column prop="POSITIONERTYPENAME" label="类型"></el-table-column> -->
                    <el-table-column prop="ISLOGIN" label="在线状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISLOGIN==1" style="color:#67C23A">在线</span>
                            <span v-else style="color:#909399">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="GPSTIME" label="最后一次GPS时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.GPSTIME!='1900-01-01 00:00:00'">{{scope.row.GPSTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="USERNAME" label="已绑人员">
                        <template slot-scope="scope">
                            <span v-if="scope.row.USERNAME">{{scope.row.USERNAME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="IMEI" prop="IMEI">
                    <el-input v-model="postInfo.IMEI" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="类型" prop="POSITIONERTYPEID">
                    <el-select  v-model="postInfo.POSITIONERTYPEID" placeholder="点击选择类型" filterable style="width:100%">
                            <el-option v-for="item in TypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="人员">
                    <el-select  v-model="postInfo.USERID" placeholder="点击选择人员" filterable clearable style="width:100%">
                            <el-option v-for="item in UserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var IMEI = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入IMEI"));
      } else {
        callback();
      }
    };
    // var POSITIONERTYPEID = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请选择定位器类型"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
        canDo:true,
        showPagination:false,
        name:"",
        imei:"",
        typeId:"",
        isOnline:"",
        bindUser:"",
        userId:"",

        UserList:[],
        TypeList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            //POSITIONERTYPEID: [{ validator: POSITIONERTYPEID, trigger: "blur" }],
            IMEI: [{ validator: IMEI, trigger: "blur" }],
        },

        role:{add:false,edit:false,del:false},
        allIds:[],
        chooseFileModal:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.name=getQueryData.NAME
        this.userId=getQueryData.USERID
        this.bindUser=getQueryData.ISBINDUSER
        this.isOnline=getQueryData.ISLOGIN
        this.imei=getQueryData.IMEI
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getUserList()
    this.getTypeList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getUserList(){
        var md5_data =this.comjs.requestDataEncrypt({ISQUIT:"0",}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ISQUIT:"0",})).toUpperCase())
        this.$http.post("User/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.UserList=response.data.DATA
                } 
            })
    },
    getTypeList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("PositionerType/GetAllPositionerType?token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.TypeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var  uid=""
        if(this.bindUser=="1"){
            uid=this.userId
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            //POSITIONERTYPEID:this.typeId,
            USERID:uid,
            ISBINDUSER:this.bindUser,
            ISLOGIN:this.isOnline,
            IMEI:this.imei,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Positioner/GetPositionerList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            //POSITIONERTYPEID:"",
            IMEI:"",
            USERID:"",
        }
        this.doTypeStr="添加定位器"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Positioner/InsertPositioner", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var uid=""
        if(item.USERID && item.USERID!="0"){
            uid =item.USERID
        }
        this.postInfo={
            POSITIONERSNOWID:item.SNOWID,
            NAME:item.NAME,
            //POSITIONERTYPEID:item.POSITIONERTYPEID,
            IMEI:item.IMEI,
            USERID:uid,
        }
        this.doTypeStr="修改定位器"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Positioner/UpdatePositioner", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除定位器：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({POSITIONERSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({POSITIONERSNOWID:id})).toUpperCase())
        this.$http.post("Positioner/DeletePositioner", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    downloadTemplet(){
      window.open("file/定位器模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("Positioner/UploadExcel?token="+this.$store.state.token, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
            
             this.chooseFileModal=false
             this.PageIndex=1
             this.getPostList()
             if(response.data.DATA && response.data.DATA!=""){
                this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        window.open(response.data.DATA)
                    })
             }else{
                this.$message.success("导入完成")
             }
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>