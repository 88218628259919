<template>
    <div class="views-mainA">
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search" style="padding:0 16px">
                <el-input v-model="carname" placeholder="请输入名称" class="iww" ></el-input>
                <el-button type="primary" @click="searchCar">查询</el-button>
                <el-button type="danger" @click="delCars" class="fr mr20" v-if="role.del">批量删除</el-button>
                <el-button type="success" @click="addCar" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="carList" stripe style="width: 100%" @selection-change="carSelectionChange" ref="carList" >
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                    <el-table-column prop="LOCATIONREQUEST" label="轨迹请求">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LOCATIONREQUEST != null">{{scope.row.LOCATIONREQUEST.DISPLAYNAME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CAMERAREQUESTID" label="监控请求">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CAMERAREQUESTID =='1'">博云设备</span>
                            <span v-else-if="scope.row.CAMERAREQUESTID =='0'">北斗设备</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ONLINEREQUEST" label="在线状态请求">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ONLINEREQUEST != null">{{scope.row.ONLINEREQUEST.DISPLAYNAME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="editCar(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                                <el-button @click="delCar(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                            </template>
                        </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexC" :page-size="PageSizeC" :total="TotalC" class="pg" @current-change="PGChangeC"></el-pagination>
            </div>
            <el-dialog :title="doTypeStr" :visible.sync="carFormModal" width="800px" :append-to-body="true">
            <el-form :model="carInfo" status-icon :rules="carrules"  ref="cForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="carInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="轨迹请求" prop="LOCATIONREQUESTID">
                    <el-select v-model="carInfo.LOCATIONREQUESTID" placeholder="请选择轨迹请求"  filterable  style="width:100%;">
                        <el-option v-for="item in queryRequests" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="监控请求" prop="CAMERAREQUESTID">
                    <el-select v-model="carInfo.CAMERAREQUESTID" placeholder="请选择监控请求"  filterable  style="width:100%;">
                        <el-option label="北斗设备" value="0" ></el-option>
                        <el-option label="博云设备" value="1" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="在线状态请求" prop="ONLINEREQUESTID">
                    <el-select v-model="carInfo.ONLINEREQUESTID" placeholder="请选择在线状态请求"  filterable  style="width:100%;">
                        <el-option v-for="item in queryRequests" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitCarForm()">提交</el-button>
                    <el-button @click="carFormModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        </div>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "CARLocationType",
  props: {
    TOKEN: {
      type: String,
      default: "",
    },
    ROTE:{
        type:String,
        default: "0",
    }
  },
  data() {
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var LOCATIONREQUESTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择轨迹请求"));
      } else {
        callback();
      }
    };
    var CAMERAREQUESTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择监控请求"));
      } else {
        callback();
      }
    };
    var ONLINEREQUESTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择在线状态请求"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        doTypeStr:"",
        doType:"",
        dialogMaxHeigh:0,
        queryRequests:[],
        //车辆定位类型
        carname:"",
        carList:[],
        PageIndexC:1,
        PageSizeC:10,
        TotalC:0,

        checkCar:[],
        carListModal:false,
        carFormModal:false,
        carInfo:{},
        carrules: {
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            LOCATIONREQUESTID: [{ validator: LOCATIONREQUESTID, trigger: "blur" }],
            CAMERAREQUESTID: [{ validator: CAMERAREQUESTID, trigger: "blur" }],
            ONLINEREQUESTID: [{ validator: ONLINEREQUESTID, trigger: "blur" }],
        },

        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    if(this.ROTE=="1"){
        this.role=this.setPageRoles(this.$route.path)
        setTimeout(()=>{
            this.role=this.setPageRoles(this.$route.path)
        },3000)
    }else{
        this.role={add:true,edit:true,del:true}
    }

    this.dialogMaxHeigh = h-500
    this.loadItem=null
    this.getRequestList()
    this.getCarList()
  },
  methods: {
    getRequestList(){
        var params={
            PageIndex:1,
            PageSize:500,
            DISPLAYNAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.TOKEN,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetRequestList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.queryRequests=response.data.DATA
                }
            })
    },
    getCarList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndexC,
            PageSize:this.PageSizeC,
            DISPLAYNAME:this.carname,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.TOKEN,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarLocationType/GetCarLocationTypeList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.carList=response.data.DATA
                    this.TotalC=response.data.TOTALCOUNT
                } else {
                    this.carList=[]
                    this.TotalC=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.carList=[]
                this.TotalC=0
        });
    },
    searchCar(){
        this.PageIndexC=1
        this.getCarList()
    },
    addCar(){
        this.carInfo={
            DISPLAYNAME:"",
            LOCATIONREQUESTID:"",
            CAMERAREQUESTID:"",
            ONLINEREQUESTID:"",
        }
        this.doTypeStr="添加车辆定位类型"
        this.doType="1"
        this.carFormModal=true
        
    },
    sendAddCar(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.carInfo, this.TOKEN,this.$md5(this.comjs.sortAsc(this.carInfo)).toUpperCase())
        this.$http.post("CarLocationType/InsertCarLocationType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.carFormModal=false
                    this.getCarList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editCar(item){
        this.carInfo={
            SNOWID:item.SNOWID,
            DISPLAYNAME:item.DISPLAYNAME,
            LOCATIONREQUESTID:item.LOCATIONREQUESTID,
            CAMERAREQUESTID:item.CAMERAREQUESTID,
            ONLINEREQUESTID:item.ONLINEREQUESTID,
        }
        this.doTypeStr="修改车辆定位类型"
        this.doType="2"
        this.carFormModal=true
    },
    sendEditCar(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.carInfo, this.TOKEN,this.$md5(this.comjs.sortAsc(this.carInfo)).toUpperCase())
        this.$http.post("CarLocationType/EditCarLocationType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.carFormModal=false
                    this.getCarList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delCar(item){
        if(this.canDo){
            this.$confirm('确定删除车辆定位类型：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendCarDel([item.SNOWID])
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendCarDel(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWIDLIST:ids}, this.TOKEN,this.$md5(this.comjs.sortAsc({SNOWIDLIST:ids})).toUpperCase())
        this.$http.post("CarLocationType/DeleteCarLocationType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.carFormModal=false
                    this.getCarList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChangeC(e){
        this.PageIndexC=e
        this.getCarList()
    },
    submitCarForm() {
        if (this.canDo) {
            this.$refs.cForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAddCar()
                    }else if(this.doType=="2"){
                        this.sendEditCar()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    carSelectionChange(list){
        this.checkCar=[]
        for(var i=0;i<list.length;i++){
            this.checkCar.push(list[i].SNOWID)
        }
    },
    delCars(){
        if(this.checkCar.length==0){
            this.$message.error("请先选择需要删除的车辆定位类型")
        }else{
            if(this.canDo){
                this.$confirm('确定删除选择的车辆定位类型？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.sendCarDel(this.checkCar)
                }).catch(() => {
                this.$message.info("已取消")    
            });
            }
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>