<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">系统用户</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="lname" placeholder="请输入登录名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="OPENID" label="OPENID"></el-table-column>
                    <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">全局账号</span>
                            <span v-else-if="scope.row.TYPE=='2'">分管账号</span>
                            <span v-else-if="scope.row.TYPE=='3'">项目管理账号</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="RPOJECTNAME" label="项目"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                            <el-button @click="ressetpwd(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="role.edit">密码重置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="姓名" prop="DISPLAYNAME" v-if="doType!='3'">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="LOGINNAME" v-if="doType!='3'">
                    <el-input v-model="postInfo.LOGINNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="OPENID" v-if="doType!='3'">
                    <el-input v-model="postInfo.OPENID" autocomplete="off"></el-input>
                </el-form-item>  
                <el-form-item label="账号类型"  v-if="doType!='3'">
                    <el-radio v-model="postInfo.TYPE" label="1">全局账号</el-radio>
                    <el-radio v-model="postInfo.TYPE" label="2">分管账号</el-radio>
                    <el-radio v-model="postInfo.TYPE" label="3">项目管理账号</el-radio>
                </el-form-item>  
                <el-form-item label="项目" prop="PROJECTID" v-if="doType!='3' && postInfo.TYPE=='2' || postInfo.TYPE=='3'">
                    <el-select  v-model="postInfo.PROJECTID" placeholder="点击选择项目" filterable style="width:100%">
                            <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>  
                <el-form-item label="密码" prop="PASSWORD" v-if="doType!='2'">
                    <el-input v-model="postInfo.PASSWORD" autocomplete="off"></el-input>
                </el-form-item>          
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var LOGINNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录密"));
      } else {
        callback();
      }
    };
    var PASSWORD = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    var PROJECTID=(rule, value, callback) => {
      if (this.postInfo.TYPE =='2' || this.postInfo.TYPE =='3' && value === "") {
        callback(new Error("请选择项目"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        lname:"",
        name:"",
        showPagination:false,
        projectList:[],
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            LOGINNAME: [{ validator: LOGINNAME, trigger: "blur" }],
            PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
            PASSWORD: [{ validator: PASSWORD, trigger: "blur" }],
        },

        
        resetItem:null,
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex=getQueryData.PAGEINDEX
        this.name = getQueryData.DISPLAYNAME
        this.lname = getQueryData.LOGINNAME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getProject()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getProject(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.projectList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            SYSUSERSNOWID:"",
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            LOGINNAME:this.lname,
            DISPLAYNAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysUser/GetSysUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            DISPLAYNAME:"",
            ISADMIN:1,
            LOGINNAME:"",
            OPENID:"",
            PASSWORD:"",
            TYPE:"1",
            PROJECTID:"",
        }
        this.doTypeStr="添加系统用户"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        if(this.postInfo.TYPE=="1"){
            this.postInfo.PROJECTID =""
        }
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("SysUser/InsertSysUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SYSUSERSNOWID:item.SNOWID,
            DISPLAYNAME:item.DISPLAYNAME,
            ISADMIN:item.ISADMIN,
            LOGINNAME:item.LOGINNAME,
            OPENID:item.OPENID,
            PASSWORD:item.PASSWORD,
            TYPE:item.TYPE.toString(),
            PROJECTID:item.PROJECTID,
        }
        this.doTypeStr="修改系统用户"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        if(this.postInfo.TYPE=="1"){
            this.postInfo.PROJECTID =""
        }
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("SysUser/UpdateSysUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除系统用户：'+item.DISPLAYNAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                     this.$message.info("已取消")    
                });
            }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SYSUSERSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SYSUSERSNOWID:id})).toUpperCase())
        this.$http.post("SysUser/DeleteSysUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }else if(this.doType=="3"){
                        this.sendReset()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    ressetpwd(item){
        this.postInfo={
            SYSUSERSNOWID:item.SNOWID,
            DISPLAYNAME:item.DISPLAYNAME,
            ISADMIN:item.ISADMIN,
            LOGINNAME:item.LOGINNAME,
            OPENID:item.OPENID,
            PASSWORD:"",
        }
        this.doTypeStr="密码重置"
        this.doType="3"
        this.postModal=true
    },

    sendReset(){
        //
        var param={
            SYSUSERSNOWID:this.postInfo.SYSUSERSNOWID,
            PASSWORD:this.postInfo.PASSWORD,
        }
        
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(param, this.$store.state.token,this.$md5(this.comjs.sortAsc(param)).toUpperCase())
        this.$http.post("SysUser/ResetPassword", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已重置")
                    this.postModal=false
                    //this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>