<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">人员管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="cardno" placeholder="请输入工牌号码" class="iw" ></el-input>
                <el-input v-model="mobile" placeholder="请输入手机号" class="iw" ></el-input>
                <el-select v-model="userType" placeholder="用户类型"  filterable  clearable class="iwu">
                        <el-option v-for="item in userTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="projectId" placeholder="所在项目"  filterable  clearable class="iwu">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="isquit" placeholder="是否离职"  filterable  clearable class="iwu">
                        <el-option label="所有" value="" ></el-option>
                        <el-option label="离职" value="1" ></el-option>
                        <el-option label="在职" value="0" ></el-option>
                </el-select>
                <el-button type="primary" @click="downloadTemplet"  class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr mr10" v-if="role.add">导入模板</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"  @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div v-if="props.row.DUTYPROJECTUSERLIST && props.row.DUTYPROJECTUSERLIST.length>0" style="overflow:hidden;">
                                    <div style="padding:12px 20px;float: left;margin-right: 20px;background-color:#F8F8F9;border-radius:3px;" v-for="(item,index) in props.row.DUTYPROJECTUSERLIST" :key="item.SNOWID">
                                    <div style="font-size:14px;font-weight: bold;">项目{{index+1}}：</div>
                                    <div style="overflow:hidden;margin-top: 5px;">
                                        <span>类型：{{item.DUTYNAME}}　　</span>
                                        <span>项目：{{item.PROJECTNAME}}　　</span>
                                        <span>小组：{{item.CLEANGROUPNAME}}　　</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="height: 50px;line-height: 50px;text-align: center;font-size: 14px;color: #666;">
                                暂未绑定项目
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="姓名" width="100"></el-table-column>
                    <el-table-column prop="CARDNO" label="工牌号码"></el-table-column>
                    <el-table-column prop="IDNUMBER" label="身份证号" width="200"></el-table-column>
                    <el-table-column prop="EMERGENCYPHONE" label="紧急联系人电话" width="200"></el-table-column>
                    <el-table-column prop="MOBILE" label="手机号" width="150"></el-table-column>
                    <el-table-column prop="EMAIL" label="邮箱">
                        <template slot-scope="scope">
                            <span v-if="scope.row.EMAIL">{{scope.row.EMAIL}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SEX" label="性别">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SEX=='男'">男</span>
                            <span v-else>女</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ADDRESS" label="地址"  width="260">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ADDRESS">{{scope.row.ADDRESS}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="HOMETOWN" label="户籍" width="260">
                        <template slot-scope="scope">
                            <span v-if="scope.row.HOMETOWN">{{scope.row.HOMETOWN}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="CONTRACTINFO" label="合同情况">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CONTRACTINFO">{{scope.row.CONTRACTINFO}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="USERTYPE" label="合同性质">
                        <template slot-scope="scope">
                            <span v-if="scope.row.USERTYPE=='0'">临时人员</span>
                            <span v-else>正式员工</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="INTIME" label="入职时间" width="160"></el-table-column>
                    <el-table-column prop="ISQUIT" label="是否离职">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISQUIT=='0'" style="color:#67C23A;">否</span>
                            <span v-else style="color:#F56C6C;">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="QUITTIME" label="离职时间" width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISQUIT=='1'">{{scope.row.QUITTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期" width="160"></el-table-column>
                    <el-table-column label="操作" width="220" fixed="right">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        <el-button @click="outPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.edit && scope.row.ISQUIT =='0'">离职</el-button>
                        <el-button @click="unbind(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="scope.row.OPENID">小程序解绑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="姓名" prop="DISPLAYNAME">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="工牌号码" prop="CARDNO">
                    <el-input v-model="postInfo.CARDNO" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="身份证号" >
                    <el-input v-model="postInfo.IDNUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="紧急联系人电话" >
                    <el-input v-model="postInfo.EMERGENCYPHONE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="MOBILE">
                    <el-input v-model="postInfo.MOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" >
                    <el-input v-model="postInfo.EMAIL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="SEX">
                    <el-radio v-model="postInfo.SEX" label="男">男</el-radio>
                    <el-radio v-model="postInfo.SEX" label="女">女</el-radio>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="postInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="户籍">
                    <el-input v-model="postInfo.HOMETOWN" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="合同情况">
                    <el-input v-model="postInfo.CONTRACTINFO" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="合同性质" prop="USERTYPE">
                    <el-radio v-model="postInfo.USERTYPE" label="0">临时人员</el-radio>
                    <el-radio v-model="postInfo.USERTYPE" label="1">正式员工</el-radio>
                </el-form-item>
                <el-form-item label="入职时间" prop="INTIME">
                    <el-date-picker v-model="postInfo.INTIME" type="datetime" placeholder="选择入职时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="是否离职" prop="ISQUIT">
                    <el-radio v-model="postInfo.ISQUIT" label="0">否</el-radio>
                    <el-radio v-model="postInfo.ISQUIT" label="1">是</el-radio>
                </el-form-item>
                <el-form-item label="离职时间" prop="QUITTIME" v-if="postInfo.ISQUIT=='1'">
                    <el-date-picker v-model="postInfo.QUITTIME" type="datetime" placeholder="选择离职时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="系统用户" prop="SYSUSERID">
                    <el-select v-model="postInfo.SYSUSERID" placeholder="请选择系统用户"  filterable clearable style="width:100%;">
                        <el-option v-for="item in sysUserList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item> 
                <el-form-item label="电子围栏">
                    <el-select v-model="postInfo.ELECREGIONID" placeholder="请选择电子围栏"  filterable  style="width:100%;">
                        <el-option v-for="item in elecList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="头像" prop="ICON">-->
<!--                        <el-upload-->
<!--                            action=""-->
<!--                            :http-request="uploadImgFun"-->
<!--                            class="avatar-uploader"-->
<!--                            :show-file-list="false"-->
<!--                            :file-list="fileList"-->
<!--                            :before-upload="beforeAvatarUpload">-->
<!--                          <div style="display: flex;align-items: center">-->
<!--                            <img v-if="imageUrl" :src="imageUrl" class="avatar" style="width: 100px;height: 100px">-->
<!--                            <el-button v-if="imageUrl" type="primary" @click="removeImg($event)">删除图片</el-button>-->
<!--                            <el-button v-else type="primary" size="mini">添加头像</el-button>-->
<!--                          </div>-->
<!--                        </el-upload>-->
<!--                </el-form-item>-->
                <el-form-item label="所在项目" prop="DUTYPROJECTUSERLIST">
                      <el-button type="text" @click="addProTtem()" class="addProTtem">添加</el-button>
                      <div class="itemBox"  v-for="(pro, index) in postInfo.DUTYPROJECTUSERLIST" :key="index">
                            <div class="itemOut">
                                <div class="itemTit">
                                    <p>项目{{index+1}}</p>    
                                    <el-button type="text" style="color:#F56C6C;float:right;"  @click="delProItem(index)">删除</el-button>
                                </div>  
                                <el-form-item label="人员类型" :prop="'DUTYPROJECTUSERLIST.' + index + '.DUTYID'" :rules="{ required: true, message: '人员类型不能为空', trigger: 'blur'}" class="halfItem">
                                    <el-select  v-model="pro.DUTYID" placeholder="点击选择人员类型" filterable style="width:100%">
                                            <el-option v-for="item in userTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="项目" :prop="'DUTYPROJECTUSERLIST.' + index + '.PROJECTID'" :rules="{ required: true, message: '项目不能为空', trigger: 'blur'}" class="halfItem">
                                    <el-select  v-model="pro.PROJECTID" placeholder="点击选择项目" filterable style="width:100%" @change="userProjectChange(index)">
                                            <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="小组（子项目）"  class="halfItem">
                                    <el-select  v-model="pro.CLEANGROUPID" placeholder="点击选择子项目" filterable clearable style="width:100%">
                                            <el-option v-for="item in pro.groupList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>      
                </el-form-item>  
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
        <el-dialog title="用户离职" :visible.sync="outModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="姓名">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="离职时间" prop="QUITTIME">
                    <el-date-picker v-model="postInfo.QUITTIME" type="datetime" placeholder="选择离职时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitOutForm()">提交</el-button>
                    <el-button @click="outModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    // var CARDNO = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请输入工牌工号"));
    //   } else {
    //     callback();
    //   }
    // };
    var IDNUMBER = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入身份证号'));
      } else if(!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)){
        callback(new Error('请输入正确的身份证号'));
      }else {
        callback();
      }
    };
    var MOBILE = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var EMAIL = (rule, value, callback) => {
     if(value != '' && value != null && !/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)){
        callback(new Error('请输入正确的邮箱'));
      }else {
        callback();
      }
    };
    var INTIME = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择入职时间"));
      } else {
        callback();
      }
    };
    var QUITTIME = (rule, value, callback) => {
      if (this.postInfo.ISQUIT=='1'&& value === "" || value == null) {
        callback(new Error("请选择离职时间"));
      } else {
        callback();
      }
    };
    var SEX = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择性别"));
      } else {
        callback();
      }
    };
    var USERTYPE = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择合同性质"));
      } else {
        callback();
      }
    };
    var ISQUIT = (rule, value, callback) => {
      if ( value === "" || value == null) {
        callback(new Error("请选择合同性质"));
      } else {
        callback();
      }
    };
    var SYSUSERID = (rule, value, callback) => {
      if ( value === "" || value == null) {
        callback(new Error("请选择系统用户"));
      } else {
        callback();
      }
    };
    
    
    // var ICON=(rule, value, callback) => {
    //   if ( value===0 || value == null) {
    //     callback(new Error("请选择人员图标"));
    //   } else {
    //     callback();
    //   }
    // };
    var DUTYPROJECTUSERLIST= (rule, value, callback) => {
      if ( value.length==0 || value == null) {
        callback(new Error("至少绑定一个项目"));
      } else {
        callback();
      }
    };


    return {
        sysUserList:[],
        userTypeList:[],
        projectList:[],
        projectChildList:[],
        elecList:[],

        canDo:true,
        showPagination:false,
        name:"",
        userType:"",
        projectId:"",
        cardno:"",
        mobile:"",
       
        isquit:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur",required:true, }],
            SEX: [{ validator: SEX, trigger: "blur",required:true, }],
            USERTYPE: [{ validator: USERTYPE, trigger: "blur",required:true, }],
            ISQUIT: [{ validator: ISQUIT, trigger: "blur",required:true, }],
            //: [{ validator: SYSUSERID, trigger: "blur",required:true, }],
            // CARDNO: [{ validator: CARDNO, trigger: "blur" }],
            // IDNUMBER: [{ validator: IDNUMBER, trigger: "blur" }],
            MOBILE: [{ validator: MOBILE, trigger: "blur" ,required:true,}],
            // EMAIL: [{ validator: EMAIL, trigger: "blur" }],
            INTIME: [{ validator: INTIME, trigger: "blur" ,required:true,}],
            QUITTIME: [{ validator: QUITTIME, trigger: "blur" }],
            // ICON: [{ validator: ICON, trigger: "blur" }],
            DUTYPROJECTUSERLIST: [{ validator: DUTYPROJECTUSERLIST, trigger: "blur",required:true, }],
        },


        imageUrl:"",
        fileItem:null,
        fileList: [],

        chooseFileModal:false,

        outModal:false,
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.name=getQueryData.DISPLAYNAME
        this.userType=getQueryData.DUTYID
        this.projectId=getQueryData.PROJECTID
        this.cardno=getQueryData.CARDNO
        this.mobile=getQueryData.MOBILE
        this.isquit=getQueryData.ISQUIT
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getSysUser()
    this.getProject()
    this.getUseType()
    this.getElec()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    addProTtem(){
        var obj={
            DUTYID:"",
            PROJECTID:"",
            CLEANGROUPID:"",
            groupList:[],
        }
        this.postInfo.DUTYPROJECTUSERLIST.push(obj)
    },
    delProItem(index){
        var nl=[]
        for(var i=0;i< this.postInfo.DUTYPROJECTUSERLIST.length;i++){
            if(i!=index){
                nl.push(this.postInfo.DUTYPROJECTUSERLIST[i])
            }
        }
        this.postInfo.DUTYPROJECTUSERLIST =nl
    },
    userProjectChange(index){
        var item = this.postInfo.DUTYPROJECTUSERLIST[index]
        item.CLEANGROUPID=""
        item.groupList=[]
        if(item.PROJECTID!=""){
            for(var i=0;i<this.projectChildList.length;i++){
                if(this.projectChildList[i].PARENTID == item.PROJECTID){
                    item.groupList.push(this.projectChildList[i])
                }
            }   
        }
        var nl=[]
        for(var j=0;j<this.postInfo.DUTYPROJECTUSERLIST.length;j++){
            if(j==index){
                nl.push(item)
            }else{
                nl.push(this.postInfo.DUTYPROJECTUSERLIST[j])
            }
        }
    },
    getSysUser(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("SysUser/GetSysUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.sysUserList=response.data.DATA
                } 
            })
    },
    getProject(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        if(list[i].PARENTID=='0'){
                            this.projectList.push(list[i])
                        }else{
                            this.projectChildList.push(list[i])
                        }
                    }
                } 
            })
    },
    getElec(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("ElecRegion/GetElecRegionList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.elecList=response.data.DATA
                } 
            })
    },
    getUseType(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Duty/GetDutyList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userTypeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DISPLAYNAME:this.name,
            DUTYID:this.userType,
            PROJECTID:this.projectId,
            CARDNO:this.cardno,
            MOBILE:this.mobile,
            ISQUIT:this.isquit,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("User/GetUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.imageUrl=""
        this.fileItem=null
        this.postInfo={
            CARDNO:"",
            // CONTRACTINFO:"",
            MOBILE:"",
            DISPLAYNAME:"",
            PHOTOIMAGE:"",
            EMAIL:"",
            QUITTIME:"",
            HOMETOWN:"",
            SEX:"男",
            ADDRESS:"",
            IDNUMBER:"",
            ISQUIT:"0",
            USERTYPE:"1",
            INTIME:"",
            SYSUSERID:"",
            DUTYPROJECTUSERLIST:[],
            ELECREGIONID:"",
            EMERGENCYPHONE:""
        }
        this.doTypeStr="添加人员"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("User/InsertUser", md5_data)
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if (response.data.SUCCESS) {
                    var data=response.data.DATA
                    this.imageUrl = ""
                    this.postModal=false
                    this.getPostList()
                    // 将用户头像进行上传
                    // this.addImg(data,"1")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var qt=""
        if(item.QUITTIME && item.QUITTIME!=""){
            qt=new Date(item.QUITTIME)
        }
        var it=""
        if(item.INTIME && item.INTIME!=""){
            it=new Date(item.INTIME)
        }
        var dl=[]
        for(var i=0;i<item.DUTYPROJECTUSERLIST.length;i++){
            var gid=""
            if(item.DUTYPROJECTUSERLIST[i].CLEANGROUPID && item.DUTYPROJECTUSERLIST[i].CLEANGROUPID!=""){
                gid=item.DUTYPROJECTUSERLIST[i].CLEANGROUPID
            }
            var gl=[]
            for(var j=0;j<this.projectChildList.length;j++){
                if(this.projectChildList[j].PARENTID == item.DUTYPROJECTUSERLIST[i].PROJECTID){
                    gl.push(this.projectChildList[i])
                }
            }   
            dl.push({
                DUTYID:item.DUTYPROJECTUSERLIST[i].DUTYID,
                PROJECTID:item.DUTYPROJECTUSERLIST[i].PROJECTID,
                CLEANGROUPID:gid,
                groupList:gl,
            })
        }
        // var icon=0
        // if(item.PHOTOIMAGE && item.PHOTOIMAGE!=""){
        //     icon=1
        // }
        var sex="男"
        if(item.SEX!="男"){
            sex="女"
        }
        this.postInfo={
            USERSNOWID:item.SNOWID,
            CARDNO:item.CARDNO,
            //CONTRACTINFO:item.CONTRACTINFO,
            MOBILE:item.MOBILE,
            DISPLAYNAME:item.DISPLAYNAME,
            PHOTOIMAGE:item.PHOTOIMAGE,
            EMAIL:item.EMAIL,
            QUITTIME:qt,
            HOMETOWN:item.HOMETOWN,
            SEX:sex,
            ADDRESS:item.ADDRESS,
            IDNUMBER:item.IDNUMBER,
            ISQUIT:item.ISQUIT.toString(),
            USERTYPE:item.USERTYPE.toString(),
            INTIME:it,
            SYSUSERID:item.SYSUSERID,
            DUTYPROJECTUSERLIST:dl,
            PHOTOSNOWID: item.PHOTOSNOWID,
            //ICON:icon,
            ELECREGIONID:item.ELECREGIONID,
            SNOWID: item.SNOWID,
            EMERGENCYPHONE:item.EMERGENCYPHONE
        }
        this.imageUrl=item.PHOTOIMAGE
        this.fileItem=null
        this.doTypeStr="修改人员"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("User/UpdateUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                  this.$message.success("已修改")
                  this.postModal=false
                  this.getPostList()
                    // // 删除图片
                    // if(!this.imageUrl && !this.fileItem){
                    //   this.removeImgSubmit(this.postInfo.PHOTOSNOWID)
                    // }
                    // // 进行图片的更新
                    // if(this.fileItem && this.imageUrl){
                    //     // this.removeImgSubmit(this.postInfo.PHOTOSNOWID)
                    //     this.addImg(this.postInfo,'2')
                    // }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除人员：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({USERSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({USERSNOWID:id})).toUpperCase())
        this.$http.post("User/DeleteUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },


    outPost(item){
        var it=""
        if(item.INTIME && item.INTIME!=""){
            it=new Date(item.INTIME)
        }
        var dl=[]
        for(var i=0;i<item.DUTYPROJECTUSERLIST.length;i++){
            var gid=""
            if(item.DUTYPROJECTUSERLIST[i].CLEANGROUPID && item.DUTYPROJECTUSERLIST[i].CLEANGROUPID!=""){
                gid=item.DUTYPROJECTUSERLIST[i].CLEANGROUPID
            }
            var gl=[]
            for(var j=0;j<this.projectChildList.length;j++){
                if(this.projectChildList[j].PARENTID == item.DUTYPROJECTUSERLIST[i].PROJECTID){
                    gl.push(this.projectChildList[i])
                }
            }   
            dl.push({
                DUTYID:item.DUTYPROJECTUSERLIST[i].DUTYID,
                PROJECTID:item.DUTYPROJECTUSERLIST[i].PROJECTID,
                CLEANGROUPID:gid,
                groupList:gl,
            })
        }
        // var icon=0
        // if(item.PHOTOIMAGE && item.PHOTOIMAGE!=""){
        //     icon=1
        // }
        var sex="男"
        if(item.SEX!="男"){
            sex="女"
        }
        this.postInfo={
            USERSNOWID:item.SNOWID,
            CARDNO:item.CARDNO,
            //CONTRACTINFO:item.CONTRACTINFO,
            MOBILE:item.MOBILE,
            DISPLAYNAME:item.DISPLAYNAME,
            PHOTOIMAGE:item.PHOTOIMAGE,
            EMAIL:item.EMAIL,
            QUITTIME:"",
            HOMETOWN:item.HOMETOWN,
            SEX:sex,
            ADDRESS:item.ADDRESS,
            IDNUMBER:item.IDNUMBER,
            ISQUIT:"1",
            USERTYPE:item.USERTYPE.toString(),
            INTIME:it,
            SYSUSERID:item.SYSUSERID,
            DUTYPROJECTUSERLIST:dl,
            //ICON:icon,
            ELECREGIONID:item.ELECREGIONID,
            EMERGENCYPHONE:item.EMERGENCYPHONE
        }
        this.imageUrl=item.PHOTOIMAGE
        this.fileItem=null
        this.doTypeStr="修改人员"
        this.doType="2"
        this.outModal=true
    },
    // 解除绑定
    unbind(obj){
      console.log(obj)
      let data = {
        USERSNOWID: obj.SNOWID
      }
      this.$confirm('确定解除绑定？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.sendDel(obj.SNOWID)
        var md5_data =this.comjs.requestDataEncrypt(data, this.$store.state.token,this.$md5(this.comjs.sortAsc(data)).toUpperCase())
        this.$http.post("User/UnBindUser", md5_data)
            .then((response) => {
              if (response.data.SUCCESS) {
                this.$message.success("操作成功")
                this.getPostList()
              } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
              }
            })
            .catch((error) => {
              this.$message.error("请求出错")
              this.canDo=true
            });
      }).catch(() => {
      });
    },
    submitOutForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.postInfo.QUITTIME && this.postInfo.QUITTIME!=""){
                        this.postInfo.QUITTIME = this.comjs.getDateStrByDate(this.postInfo.QUITTIME)
                    }
                    this.sendOut()
                } else {
                    return false;
                }
            });
        }
    },
    sendOut(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("User/UpdateUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("操作成功")
                        this.outModal=false
                        this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },


    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.INTIME = this.comjs.getDateStrByDate(this.postInfo.INTIME)
                    if(this.postInfo.QUITTIME && this.postInfo.QUITTIME!=""){
                        this.postInfo.QUITTIME = this.comjs.getDateStrByDate(this.postInfo.QUITTIME)
                    }
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    downloadTemplet(){
      window.open("file/人员模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("User/UploadExcel?token="+this.$store.state.token, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
            
             this.chooseFileModal=false
             this.PageIndex=1
             this.getPostList()
             if(response.data.DATA && response.data.DATA!=""){
                this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        window.open(response.data.DATA)
                    })
             }else{
                this.$message.success("导入完成")
             }
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    // beforeAvatarUpload(file) {
    //     const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //
    //     if (!isJPG) {
    //     this.$message.error('图片只能是 JPG/PNG  格式!');
    //     }else if (!isLt2M) {
    //     this.$message.error('图片大小不能超过 2MB!');
    //     }else{
    //         var _URL = window.URL || window.webkitURL;
    //         this.imageUrl = _URL.createObjectURL(file);
    //         console.log(this.imageUrl)
    //         this.postInfo.ICON=1
    //         this.fileItem=file
    //       console.log(this.fileItem)
    //     }
    //     return isJPG && isLt2M;
    // },
    // 上传图片的接口
    // uploadImgFun(file){
    // },
    // 删除图片
    // removeImg(e){
    //   e.stopPropagation()
    //   this.imageUrl = null
    //   this.fileItem = null
    // },
    // removeImgSubmit(id){
    //   var md5_data =this.comjs.requestDataEncrypt({ImagesId:[id]}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ImagesId:[id]})).toUpperCase())
    //   this.$http.post("User/DeleteUserPhoto", md5_data)
    //       .then((response) => {
    //         this.canDo=true
    //         this.hideLoading()
    //         if (response.data.SUCCESS) {
    //           this.$message.success("已修改")
    //           this.postModal=false
    //           this.getPostList()
    //         } else {
    //           this.errorRequest(response.data.DATA,response.data.MSG,true)
    //         }
    //       })
    //       .catch((error) => {
    //         this.$message.error("请求出错")
    //         this.hideLoading()
    //         this.canDo=true
    //       });
    // },
    // addImg(item,type){
    //   let userId = item.SNOWID
    //   var fd = new FormData();
    //   // fd.append("file", this.fileItem);
    //   fd.append("images", this.fileItem,this.fileItem.name);
    //   this.$upload.post(`User/UploadImages?Toekn=${this.$store.state.token}&UserID=` + userId, fd)
    //       .then((response) => {
    //         this.canDo=true
    //         if (response.data.SUCCESS) {
    //           this.hideLoading()
    //           if (type == "1") {
    //             this.$message.success("已添加")
    //           } else {
    //             this.$message.success("已修改")
    //           }
    //           this.imageUrl = ""
    //           this.postModal=false
    //           this.getPostList()
    //           // this.updateImgUrl(item, response.data.DATA)
    //         } else {
    //           // this.hideLoading()
    //           this.$message.error("上传失败")
    //         }
    //       })
    //       .catch((error) => {
    //         this.hideLoading()
    //       });
    // },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>