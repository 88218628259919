<template>
  <div id="container">
       <div class="partBox">
      <div class="login_box">
        <div class="login_nav">
            <p class="login_title" :class="navType=='1'?'login_navOn':''" @click="changeNavType(1)">平台用户登陆</p>
            <p class="login_title" :class="navType=='2'?'login_navOn':''" @click="changeNavType(2)">管理端登录</p>
        </div>

        <div style="overflow:hidden;">
              <div class="login_row" v-if="navType=='1'">
                <i class="fa el-icon-s-home"></i>
<!--                <img src="../../public/statics/img/company.png" class="i" style="width:28px;margin-left:13px;"/>-->
                <select name="" id="company" class="sel">
                  <option value="">点击选择区域</option>
                  <option v-for="item in companyList" :key="item.SNOWID" :value="item.SNOWID" >{{ item.NAME }}</option>
                </select>
              </div>
              <div class="login_row">
                <i class="fa fa-user"></i>
                <input
                  type="text"
                  id="LOGINNAME"
                  class="login_input"
                  placeholder="请输入用户名"
                  autocomplete="off"
                  v-model="name"
                />
              </div>
              <div class="login_row">
                <i class="fa fa-lock"></i>
                <input
                  type="password"
                  id="PASSWORD"
                  class="login_input"
                  placeholder="请输入密码"
                  autocomplete="off"
                  v-model="pwd"
                />
              </div>
              <p class="login_error">{{ errorMsg }}</p>
              <button
                id="loginBtn"
                class="login_btn"
                :class="canLogin ? '' : 'loginBtn_doing'"
                v-on:click="doLogin"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                登　　录
              </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "Login",
  data() {

    return {
      registerModal: false,
      fullscreenLoading: false,
      errorMsg: "",
      canLogin: true,

      companyList:[],
      companyName:"",
      company:"",
      name: "",
      pwd: "",
      navType:1,
    };
  },
  mounted() {
    document.title = '国土巡逻鹰';
    document.getElementsByTagName("title")[0].innerText = '国土巡逻鹰';
    this.loadItem=null 
    this.initUserInfo()
    this.getComList()
  },
  methods: {
    changeNavType(type){
      this.navType=type
    },
    getComList(){
      var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("MainDb/GetCompanyList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.companyList=response.data.DATA
                } 
            })
    },
    initUserInfo(){
        var loginValid=false

        if(this.$cookies.isKey("hb_setSysType")  && this.$cookies.isKey("hb_setToken") && this.$cookies.isKey("hb_setEndTime")){
          var be=new Date().getTime()
          var ee=new Date(this.$cookies.get("hb_setEndTime")).getTime()
          if(be<ee){
            loginValid=true
            this.$store.commit('setToken', this.$cookies.get("hb_setToken"));  
            this.$store.commit('setAdmin', this.$cookies.get("hb_setAdmin"));  
            this.$store.commit('setEndTime', this.$cookies.get("hb_setEndTime")); 
            this.$store.commit('setSnowid', this.$cookies.get("hb_setSnowid"));
            this.$store.commit('setName', this.$cookies.get("hb_setName"));
            this.$store.commit('setLoginName', this.$cookies.get("hb_setLoginName"));
            this.$store.commit('setPassword', this.$cookies.get("hb_setPassword"));
  
          }
        }
        if(loginValid){
          var admin=this.$cookies.get("hb_setAdmin")
          var sysType = this.$cookies.get("hb_setSysType")
          this.$store.commit('setSysType', sysType); 
          if(admin=="1"){
              if(this.$store.state.openPage !=""){
                this.$router.replace("/"+ this.$store.state.openPage);
              }else{
                if(sysType=="1"){
                  this.$router.replace("/AdminManage");
                }else if(sysType=="2"){
                  this.$router.replace("/AdminUserManage");
                }
                  
              }
          }else{
              this.logout(false)
          }
        }else{
          this.logout(false)
        }
    },
    doLogin(){
      if(this.navType=="1"){
        this.doLoginUser()
      }else{
        this.doLoginAdmin()
      }
    },
    doLoginUser() { 
      if (this.canLogin) {
        var cid=document.getElementById("company").value
        if(cid ==""){
          this.errorMsg = "请选择区域";
        }else if (this.name == "" || this.pwd == "") {
          this.errorMsg = "用户名或密码不能为空";
        } else {
          this.fullscreenLoading = true;
          this.errorMsg = "";
          this.canLogin = false;
          var params = {
            DATA:{            
              COMPANYSNOWID:cid,
              LOGINNAME: this.name,
              PASSWORD: this.pwd,
            }
          };
          this.$http
            .post("SysUser/Login", params)
            .then((response) => {
              //console.log(response);
              this.fullscreenLoading = false;
              if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    var  rlist=d.ROLELIST
                    var canIn=false
                    for(var i=0;i<rlist.length;i++){
                      if(rlist[i].SNOWID == "1656486063901904896"){
                        canIn=true
                        break;
                      }
                    }
                    if(canIn){
                      this.$store.commit('setSysType', "1");
                      this.$store.commit('setToken', d.TOKEN.TOKEN); 
                      this.$store.commit('setAdmin', d.ISADMIN);  
                      this.$store.commit('setEndTime', d.TOKEN.ENDTIME);
                      this.$store.commit('setSnowid', d.SNOWID);  
                      this.$store.commit('setName', d.DISPLAYNAME);
                      this.$store.commit('setLoginName', d.LOGINNAME);
                      this.$store.commit('setPassword', this.pwd);

                      this.$cookies.set("hb_setSysType","1")
                      this.$cookies.set("hb_setToken",d.TOKEN.TOKEN)
                      this.$cookies.set("hb_setAdmin",d.ISADMIN)
                      this.$cookies.set("hb_setEndTime",d.TOKEN.ENDTIME)
                      this.$cookies.set("hb_setSnowid",d.SNOWID)
                      this.$cookies.set("hb_setName",d.DISPLAYNAME)
                      this.$cookies.set("hb_setLoginName",d.LOGINNAME)
                      this.$cookies.set("hb_setPassword",this.pwd)
                      this.$cookies.set("hb_companyId",cid)
                      
                      this.$router.replace("/AdminManage");
                    }else{
                      this.canLogin = true;
                      return this.$message.error("这不是正确的账号,请登录正确的账号");
                    }                   
              } else {
                  this.canLogin = true;
                  this.$message.error(response.data.MSG);
              }
            })
            .catch((error) => {
                this.canLogin = true;
                this.fullscreenLoading = false;
                this.$message.error("登录失败，请稍后再试");
            });
        }
      }
    },
    doLoginAdmin(){
      if (this.canLogin) {
        if (this.name == "" || this.pwd == "") {
          this.errorMsg = "用户名或密码不能为空";
        } else {
          this.fullscreenLoading = true;
          this.errorMsg = "";
          this.canLogin = false;
          var params = {
            DATA:{            
              LOGINNAME: this.name,
              PASSWORD: this.pwd,
            }
          };
          this.$http
            .post("MainDb/Login", params)
            .then((response) => {
              this.fullscreenLoading = false;
              if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    this.$store.commit('setSysType', "2"); 
                    this.$store.commit('setToken', d.TOKENINFO.TOKEN); 
                    this.$store.commit('setAdmin', "1");  
                    this.$store.commit('setEndTime', d.TOKENINFO.ENDTIME);
                    this.$store.commit('setSnowid', d.SNOWID);  
                    this.$store.commit('setName', d.TRUENAME);
                    this.$store.commit('setLoginName', d.LOGINNAME);
                    this.$store.commit('setPassword', this.pwd);

                    this.$cookies.set("hb_setSysType","2")
                    this.$cookies.set("hb_setToken",d.TOKENINFO.TOKEN)
                    this.$cookies.set("hb_setAdmin","1")
                    this.$cookies.set("hb_setEndTime",d.TOKENINFO.ENDTIME)
                    this.$cookies.set("hb_setSnowid",d.SNOWID)
                    this.$cookies.set("hb_setName",d.TRUENAME)
                    this.$cookies.set("hb_setLoginName",d.LOGINNAME)
                    this.$cookies.set("hb_setPassword",this.pwd)

                    this.$router.replace("/AdminUserManage");
              } else {
                  this.canLogin = true;
                  var msg=''
                  if(response.data.MSG){
                    msg=response.data.MSG
                  }else{
                    msg="账号或密码不正确"
                  }
                  this.$message.error(msg);
              }
            })
            .catch((error) => {
                this.canLogin = true;
                this.fullscreenLoading = false;
                this.$message.error("登录失败，请稍后再试");
            });
        }
      }
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #fff;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  min-height: 600px;
  position: relative;
}
#container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../public/statics/img/mainBackground.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 666;
}
.partBox {
  width: 330px;
  overflow: hidden;
  border: 0;
  margin: 18% auto;
}
.codeBox{
  width: 260px;
  margin: 0px auto;
}
.codeBox img{
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}
.codeBox p{
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 10px;
  text-align: center;
}
.options-login,
.options-register {
  width: 320px;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  display: none;
  z-index: 999;
}
.options-login .register,
.options-login .forget {
  float: right;
  margin-left: 20px;
  cursor: pointer;
  color: #2dd1b1;
}

.options-register .login {
  color: #fff;
  float: right;
  margin-left: 20px;
}
.options-register .login a {
  cursor: pointer;
  color: #2dd1b1;
}

.login_box {
  width: 320px;
  overflow: hidden;
  padding: 0 5px;
}
.login_row {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}
.login_row i {
  font-size: 16px;
  color: #fff;
  float: left;
  margin-top: 16px;
  margin-left: 16px;
}
.login_row input {
  width: 256px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
}
.login_row .sel {
  width: 250px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
}
.login_row .sel option{
  background-color: #ADADAD;
}
.sel .el-input__inner {
  background-color: transparent !important;;
}
.active {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.login_row_last {
  margin-bottom: 0 !important;
}
.login_error {
  height: 32px;
  font-size: 14px;
  color: #ff4e44;
  line-height: 35px;
}
.login_btn {
  background-color: rgba(44, 209, 177, 0.8);
  color: #fff;
  font-size: 16px;
  width: 310px;
  height: 46px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 47px;
  border: 0;
}
.login_nav{
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}
.login_navOn{
  color: rgb(44, 209, 177)!important;
  border-bottom:2px solid rgb(44, 209, 177)!important;
  font-weight: bold;
}
.login_title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 50%;
  float:left;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}
.login_row select {
  width: 320px;
  display: inline-block;
  margin-right: 20px;
  height: 45px;
  border-color: #aaa;
  border-radius: 4px;
  outline: none;
}
.login_doing {
  color: #33cc99 !important;
}
.loginBtn_doing {
  cursor: wait, auto !important;
  background-color: rgba(187, 190, 196, 0.8);
}
.i{width:16px;  color:#FFF;margin-left:16px;}
</style>
