<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">问题等级</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <!-- <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button> -->
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="LEVEL" label="等级"></el-table-column>
                    <el-table-column prop="POINTS" label="按时完成扣除分数"></el-table-column>
                    <el-table-column prop="TIMEOUTPOINTS" label="超时完成扣除分数"></el-table-column>
                    <!-- <el-table-column prop="CREATETIME" label="创建日期"></el-table-column> -->
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="200px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                  <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="等级" prop="LEVEL">
                    <el-input v-model="postInfo.LEVEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="按时完成扣除分数" prop="POINTS">
                    <el-input v-model="postInfo.POINTS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超时完成扣除分数" prop="TIMEOUTPOINTS">
                    <el-input v-model="postInfo.TIMEOUTPOINTS" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var NUM = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('不能为空'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var NAME = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('名称不能为空'));
      } else {
        callback();
      }
    };
    
    return {
        canDo:true,
        showPagination:false,
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            LEVEL: [{ validator: NUM, trigger: "blur" ,required:true,}],
            POINTS: [{ validator: NUM, trigger: "blur" ,required:true,}],
            TIMEOUTPOINTS: [{ validator: NUM, trigger: "blur" ,required:true,}],
            NAME: [{ validator: NAME, trigger: "blur" ,required:true,}],

        },

        role:{add:true,edit:true,del:true}
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    // setTimeout(()=>{
    //     this.role=this.setPageRoles(this.$route.path)
    // },2000)
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            ISPAGE:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Problem/QueryProblemLevelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            LEVEL:"",
            POINTS:"",
            TIMEOUTPOINTS:"",
            NAME: ""
        }
        this.doTypeStr="添加问题等级"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Problem/CreateProblemLevel", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.hideLoading()
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            LEVEL:item.LEVEL,
            POINTS:item.POINTS,
            TIMEOUTPOINTS:item.TIMEOUTPOINTS,
            NAME: item.NAME
        }
        this.doTypeStr="修改问题等级"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Problem/EditProblemLevel", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除问题等级：'+item.LEVEL+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Problem/DeleteProblemLevel", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    delete this.postInfo.ICON
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>