<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">车辆监控管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="carId" placeholder="车辆"  filterable  clearable class="iwu">
                        <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
                    <el-table-column prop="CARCAMERATYPETYPE" label="设备类型"></el-table-column>
                    <el-table-column prop="SORT" label="顺序"></el-table-column>
                    <el-table-column prop="CHANNELNO" label="频道号"></el-table-column>
                    <el-table-column prop="URL" label="监控链接"></el-table-column>
                    <el-table-column prop="VIDEONUM" label="监控数量"></el-table-column>
                    <el-table-column prop="DEVIDNO" label="设备号"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="ISMONITORLOGIN" label="是否登录">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISMONITORLOGIN==1" style="color:#67C23A;">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="车辆" prop="CARID">
                    <el-select  v-model="postInfo.CARID" placeholder="点击选择车辆" filterable style="width:100%">
                            <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型" prop="CARCAMERATYPEID">
                    <el-select  v-model="postInfo.CARCAMERATYPEID" placeholder="点击选择设备类型" filterable style="width:100%">
                            <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="频道号" prop="CHANNELNO">
                    <el-input v-model="postInfo.CHANNELNO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="顺序" prop="SORT">
                    <el-input v-model="postInfo.SORT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="监控链接" prop="URL">
                    <el-input v-model="postInfo.URL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="监控数量" prop="VIDEONUM">
                    <el-input v-model="postInfo.VIDEONUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="设备号" prop="DEVIDNO">
                    <el-input v-model="postInfo.DEVIDNO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否登录">
                    <el-radio v-model="postInfo.ISMONITORLOGIN" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISMONITORLOGIN" label="2">否</el-radio>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CARCamera",
  data() {
    var CARID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆"));
      } else {
        callback();
      }
    };
    var CARCAMERATYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择设备类型"));
      } else {
        callback();
      }
    };
    var CHANNELNO = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入频道号"));
      } else {
        callback();
      }
    };
    var URL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入监控链接"));
      } else {
        callback();
      }
    };
    var DEVIDNO = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入设备号"));
      } else {
        callback();
      }
    };
    var SORT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入排序'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('排序只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var VIDEONUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入监控数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('监控数量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        showPagination:false,
        typeList:[],
        carList:[],
        carId:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            CARID: [{ validator: CARID, trigger: "blur" }],
            CARCAMERATYPEID: [{ validator: CARCAMERATYPEID, trigger: "blur" }],
            SORT: [{ validator: SORT, trigger: "blur" }],
            CHANNELNO: [{ validator: CHANNELNO, trigger: "blur" }],
            URL: [{ validator: URL, trigger: "blur" }],
            VIDEONUM: [{ validator: VIDEONUM, trigger: "blur" }],
            DEVIDNO: [{ validator: DEVIDNO, trigger: "blur" }],
        },
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PAGEINDEX
        this.carId= getQueryData.CARID
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getTypeList()
    this.getCarList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
 
    getCarList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.carList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CARID:this.carId,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarCamera/GetCarCameraList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            CARID:"",
            SORT:"",
            CHANNELNO:"",
            URL:"",
            VIDEONUM:"",
            DEVIDNO:"",
            ISMONITORLOGIN:"1",
            CARCAMERATYPEID:"",
        }
        this.doTypeStr="添加车辆监控"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CARID:this.postInfo.CARID,
            SORT:parseInt(this.postInfo.SORT),
            CHANNELNO:this.postInfo.CHANNELNO,
            URL:this.postInfo.URL,
            VIDEONUM:parseInt(this.postInfo.VIDEONUM),
            DEVIDNO:this.postInfo.DEVIDNO,
            ISMONITORLOGIN:parseInt(this.postInfo.ISMONITORLOGIN),
            CARCAMERATYPEID:this.postInfo.CARCAMERATYPEID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarCamera/InsertCarCamera", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CARCAMERASNOWID:item.SNOWID,
            CARID:item.CARID,
            SORT:item.SORT,
            CHANNELNO:item.CHANNELNO,
            URL:item.URL,
            VIDEONUM:item.VIDEONUM,
            DEVIDNO:item.DEVIDNO,
            ISMONITORLOGIN:item.ISMONITORLOGIN.toString(),
            CARCAMERATYPEID:item.CARCAMERATYPEID,
        }
        this.doTypeStr="修改车辆监控"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CARCAMERASNOWID:this.postInfo.CARCAMERASNOWID,
            CARID:this.postInfo.CARID,
            SORT:parseInt(this.postInfo.SORT),
            CHANNELNO:this.postInfo.CHANNELNO,
            URL:this.postInfo.URL,
            VIDEONUM:parseInt(this.postInfo.VIDEONUM),
            DEVIDNO:this.postInfo.DEVIDNO,
            ISMONITORLOGIN:parseInt(this.postInfo.ISMONITORLOGIN),
            CARCAMERATYPEID:this.postInfo.CARCAMERATYPEID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarCamera/UpdateCarCamera", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除车辆监控？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CARCAMERASNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CARCAMERASNOWID:id})).toUpperCase())
        this.$http.post("CarCamera/DeleteCarCamera", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>