<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">租户统计</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="cid" placeholder="选择租户"  filterable  clearable class="iwsm">
                        <el-option v-for="item in cList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="COMPANYINFO" label="租户名称">
                        <template slot-scope="scope">
                            <span v-if="scope.row.COMPANYINFO">{{scope.row.COMPANYINFO.NAME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="PROJECTCOUNT" label="项目数量"></el-table-column>
                    <el-table-column prop="CLEANOBJECTCOUNT" label="保洁要素数量"></el-table-column>
                    <el-table-column prop="USERCOUNT" label="人员数量"></el-table-column>
                    <el-table-column prop="CARCOUNT" label="车辆数量"></el-table-column>
                    <el-table-column prop="LASTSYNCTIME" label="最后一次同步时间"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    return {
        
        canDo:true,
        showPagination:false,
        cid:"",
        cList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getCList()
    this.getPostList()
  },
  methods: {
    getCList(){
        var md5_data =this.comjs.requestDataEncrypt({ISPAGE:false}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ISPAGE:false})).toUpperCase())
        this.$http.post("MainDb/GetCompanyList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.cList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            ISPAGE:true,
            COMPANYSNOWID:this.cid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MainDb/GetCompanyStatistics", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            LOGINNAME:"",
            PASSWORD:"",
            TRUENAME:"",
        }
        this.doTypeStr="添加管理员"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("MainDb/CreateUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            USERSNOWID:item.SNOWID,
            LOGINNAME:item.LOGINNAME,
            TRUENAME:item.TRUENAME,
        }
        this.doTypeStr="修改管理员"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("MainDb/EditUserInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            if(item.SNOWID == this.$store.state.snowid){
                this.$message.error("不能删除当前登录账号") 
            }else{
                this.$confirm('确定删除管理员：'+item.TRUENAME+'？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")    
                });
            }
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({USERSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({USERSNOWID:id})).toUpperCase())
        this.$http.post("MainDb/DeleteUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>