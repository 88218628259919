<template>
    <div id="mapCon" :style="{width:width+ 'px',height:height+ 'px'}"></div>
</template>
<script>
import * as turf from '@turf/turf'
var styleOptions = {
      strokeColor:"#409EFF",    //边线颜色。
      fillColor:"#409EFF",      //填充颜色。当参数为空时，圆形将没有填充效果。
      strokeWeight: 1,       //边线的宽度，以像素为单位。
      strokeOpacity: 0.8,    //边线透明度，取值范围0 - 1。
      fillOpacity: 0.6,      //填充的透明度，取值范围0 - 1。
      strokeStyle: 'solid' //边线的样式，solid或dashed。
    }
var polygons=[]
export default {
  name: "MAP",
  props: {
    width: {
      type:Number,
      default:600
    },
    height:{
       type:Number,
      default:450
    },
    lat:{
       type:Number,
        default:32.151818
    },
    lng:{
       type:Number,
        default:118.711152
    },
    zoom:{
       type:Number,
        default:14
    },
  },
  data() {
    return {
      map:null,
      marker:[],

      drawer:null,
      polygons:[],  
    };
  },
  mounted() {
    this.loadItem=null
    this.initMap()
    var _this=this
    this.$nextTick(function () {
      document.addEventListener('keyup', function (e) {
        if (e.keyCode == 27) {
          _this.closeDraw()
        }
      })
    })
  },
  methods: {
    initMap(){
      this.map = new window.BMap.Map("mapCon");
      var point = new window.BMap.Point(this.lng,this.lat);   
      this.map.enableScrollWheelZoom(true); 
      this.map.centerAndZoom(point, this.zoom); 
      this.map.addEventListener("click",function(e){
        if(!e.yb){
          for(var i=0;i<polygons.length;i++){
                var p=polygons[i]
                p.check=false
                p.setFillColor("#409EFF")
                p.setStrokeColor("#409EFF")
          }
        }
      })
      this.initDrawManage()
      this.$emit('mapFinish')
    },
    getMap(){
      return this.map
    },
    setMarker(){
      var point =this.map.getCenter()
      this.marker = new window.BMap.Marker(point,{enableDragging:true,title:"位置中心点"})
      this.map.addOverlay(this.marker)
      return this.marker
    },
    getMarker(){
      return this.marker
    },

    initDrawManage(){
      this.drawer = new window.BMapLib.DrawingManager(this.map, {
          isOpen: false, //是否开启绘制模式
          enableDrawingTool: false, //是否显示工具栏
          drawingToolOptions: {
              anchor: window.BMAP_ANCHOR_TOP_RIGHT, //位置
              offset: new window.BMap.Size(5, 5), //偏离值
          },
          circleOptions: styleOptions, //圆的样式
          polylineOptions: styleOptions, //线的样式
          polygonOptions: styleOptions, //多边形的样式
          rectangleOptions: styleOptions, //矩形的样式
      });
      var _this=this
      this.drawer.addEventListener('overlaycomplete',function (e){
          //console.log(e)
          var oid=new Date().getTime().toString()
          //e.overlay.enableEditing()
          e.overlay.id=oid
          e.overlay.check=false
          e.overlay.addEventListener('click',function(e){
            if(e.currentTarget.check){
                e.currentTarget.check=false
                e.currentTarget.setFillColor("#409EFF")
                e.currentTarget.setStrokeColor("#409EFF")
            }else{
                e.currentTarget.check=true
                e.currentTarget.setFillColor("#F56C6C")
                e.currentTarget.setStrokeColor("#F56C6C")
            } 
          })
          polygons.push(e.overlay)
          _this.$emit('setPolygonCount',polygons.length)
      });

    },
    openDraw(){
      this.closeDraw()
      this.drawer.setDrawingMode(window.BMAP_DRAWING_POLYGON)
      this.drawer.open()
    },
    closeDraw(){
      this.drawer.close()
      var d=this.getDrawPolygons()
      this.map.clearOverlays()
      for(var i=0;i<d.length;i++){
        this.map.addOverlay(d[i])
      }
    },
    getDrawPolygons(){
      return polygons
    },
    clearDraw(){
      for(var i=0;i<polygons.length;i++){
        this.map.removeOverlay(polygons[i])
      }
      polygons=[]
    },  
    removeDrawPolygon(polygon){
      this.map.removeOverlay(polygon)
      var nl=[]
      for(var i=0;i<polygons.length;i++){
        if(polygons[i].id != polygon.id){
          nl.push(polygons[i])
        }
      }
      polygons = nl
      this.$emit('setPolygonCount',polygons.length)
    },
    getCheckPolygons(){
      var nl=[]
      for(var i=0;i<polygons.length;i++){
        if(polygons[i].check){
          nl.push(polygons[i])
        }
      }
      return nl
    },
    appendPolygon(path,id){
      var polygon= new window.BMap.Polygon(path,styleOptions)
      polygon.id=id
      polygon.check=false
      polygon.addEventListener('click',function(e){
            if(e.currentTarget.check){
                e.currentTarget.check=false
                e.currentTarget.setFillColor("#409EFF")
                e.currentTarget.setStrokeColor("#409EFF")
            }else{
                e.currentTarget.check=true
                e.currentTarget.setFillColor("#F56C6C")
                e.currentTarget.setStrokeColor("#F56C6C")
            } 
          })
      this.map.addOverlay(polygon)
      //polygon.enableEditing()
      polygons.push(polygon)
      this.$emit('setPolygonCount',polygons.length)
    },


    //百度坐标转WKT 字符串
    getWKTByBDPolygon(path){
      var str="("
      for(var i=0;i<path.length;i++){
              if(i==0){
                  str +="("+path[i].lng+" "+path[i].lat
              }else{
                  str +=","+path[i].lng+" "+path[i].lat
              }
          }
          str +=","+path[0].lng+" "+path[0].lat
          str+="))"
      return str
    },

    //WKT字符串转百度坐标
    getBDPathByWKTStr(str){
      var zb={isMulti:false,arr:[]}
      if(str.indexOf("MULTIPOLYGON")!= -1){  //多个多边形
          zb.isMulti=true
          str = str.replace("MULTIPOLYGON","")
          str = str.replace("(((","")
          str = str.replace(")))","")
          var m_strs=str.split(")),((")
          var marr=[]
          for(var i=0;i<m_strs.length;i++){
              var mstrArr= this.getBDItemPathByStr(m_strs[i])
              marr.push(mstrArr)
          }
          zb.arr = marr
      }else if(str.indexOf("POLYGON")!= -1){  //单个多边形
          str = str.replace("POLYGON","")
          str = str.replace("((","")
          str = str.replace("))","")
          zb.arr=this.getBDItemPathByStr(str)
      }
      return zb
    },
    getBDItemPathByStr(str){
       var arr=[]
       var p_strs=str.split(",")
        for(var j=0;j<p_strs.length-1;j++){
            var pp=p_strs[j].split(" ")
            arr.push(new window.BMap.Point(parseFloat(pp[0]),parseFloat(pp[1])))
        }
      return arr
    },

    /*
    * 坐标判断
    * turf.js
    */


    //百度多边形转Turf 多边形
    getTurfPolygonByBD(list) {
        var coordinates = []
        for (var i = 0; i < list.length; i++) {
            coordinates.push([list[i].lng, list[i].lat])
        }
        coordinates.push([list[0].lng, list[0].lat])
        return turf.polygon([coordinates])
    },
    //百度点坐标转Turf点坐标
    getTurfPointByBD(point) {
        return turf.point([point.lng, point.lat])
    },
    //判断两个多边形是否包含
    isInclude(p1, p2) {
        return turf.booleanContains(p1, p2);
    },
    //判断两个多边形是否重叠 （在内部和外部 false，有交集true)
    isOverlay(p1, p2) {
        return turf.booleanOverlap(p1, p2)
    },
    //判断点是否在多边形内
    isPointInPolygon(pt, poly){
      return turf.booleanPointInPolygon(pt, poly);
    },

    //获取多边形中心点
    getPolygonCenter(polygon) {
        return  turf.centerOfMass(polygon);
    },

    //turf坐标 转百度坐标
    turfCoordToBDPoint(coordinates) {
        return new window.BMap.Point(coordinates[1], coordinates[0])
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>