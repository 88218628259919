<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">问题列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="xz" placeholder="问题种类" filterable clearable class="iwss">
                    <el-option label="预警" value="0"></el-option>
                    <el-option label="历史案发" value="1"></el-option>
                </el-select>
                <el-select v-model="types" placeholder="问题类型" filterable clearable multiple class="iwss">
                    <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="leaves" placeholder="问题等级" filterable clearable multiple class="iwss">
                    <el-option v-for="item in leaveList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <!-- <el-select v-model="statuss" placeholder="问题状态"  filterable  clearable multiple  class="iwss">
                        <el-option label="全部状态" value="" ></el-option>
                        <el-option label="待处理" value="0" ></el-option>
                        <el-option label="处理中" value="1" ></el-option>
                        <el-option label="已处理" value="2" ></el-option>
                        <el-option label="分配人确认" value="3" ></el-option>
                        <el-option label="结束" value="4" ></el-option>
                        <el-option label="不予处理" value="5" ></el-option>
                </el-select> -->

                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目"></el-table-column>
                    <el-table-column prop="CLEANOBJECTNAME" label="点位" width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CLEANOBJECTNAME">{{ scope.row.CLEANOBJECTNAME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TYPE" label="问题种类">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE == 0" style="color:#ff9900;">预警</span>
                            <span v-else-if="scope.row.STATUS == 1" style="color:#2d8cf0;">历史案发</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="PROBLEMTYPENAME" label="问题类型"></el-table-column>
                    <el-table-column prop="LEVEL" label="问题等级">
                        <template slot-scope="scope">
                            <span>{{ scope.row.PROBLEMLEVEL.NAME }}</span>

                        </template>
                    </el-table-column>

                    <!-- <el-table-column prop="STATUS" label="当前状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS == 0" style="color:#ff9900;">待处理</span>
                            <span v-else-if="scope.row.STATUS == 1" style="color:#2d8cf0;">处理中</span>
                            <span v-else-if="scope.row.STATUS == 2" style="color:#2db7f5;">已处理</span>
                            <span v-else-if="scope.row.STATUS == 3" style="color:#19be6b;">分配人确认</span>
                            <span v-else-if="scope.row.STATUS == 4" style="color:#808695;">结束</span>
                            <span v-else-if="scope.row.STATUS == 5" style="color:#ed4014;">不予处理</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="REMARK" label="问题描述"></el-table-column>
                    <!-- <el-table-column prop="DEADLINE" label="解决期间" width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.DEADLINE">{{ scope.row.DEADLINE }}</span>
                            <span v-else>无限期</span>
                        </template>
                    </el-table-column> -->

                    <el-table-column prop="CREATEUSERNAME" label="提交人" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CREATEUSERNAME">{{ scope.row.CREATEUSERNAME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="CLR" label="处理人" width="80"></el-table-column> -->
                    <el-table-column prop="CREATETIME" label="创建日期" width="160"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <!-- <span v-if="role.edit"> 
                                <el-button @click="setCleanPoint(scope.row)"  type="text" size="small" style="color:#409EFF;margin-left: 0;" v-if="scope.row.STATUS == 0">修改问题</el-button>
                                <el-button @click="setUser(scope.row)"  type="text" size="small" style="color:#67C23A;margin-left: 0;" v-if="scope.row.STATUS == 0">分配人员</el-button>
                                <el-button @click="setResult(scope.row)"  type="text" size="small" style="color:#67C23A;margin-left: 0;" v-if="scope.row.STATUS == 2">确认处理结果</el-button>
                            </span> -->
                            <el-button @click="detail(scope.row)" type="text" size="small"
                                style="color:#E6A23C;margin-left: 0;">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="修改问题" :visible.sync="postModal" width="800px">

            <el-form :model="postInfo" status-icon :rules="postrules" ref="cForm" label-width="120px" class="demo-uForm">
                <el-form-item label="位置">
                    <TianMap class="map" ref="tmap" :width="mapWidth" :height="mapHeight" style="float:left" :move="false"
                        :draw="true" @mapFinish="tianmapFinishA"></TianMap>
                </el-form-item>
                <el-form-item label="项目检索点位">
                    <el-select v-model="postInfo.PROJECTID" placeholder="项目" clearable filterable style="width:100%"
                        @change="projectChange">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型检索点位">
                    <el-select v-model="postInfo.CLEANOBJECTTYPEID" placeholder="类型" clearable filterable style="width:100%"
                        @change="typeChange">
                        <el-option v-for="item in cleanTypeList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择点位" prop="CLEANOBJECTSNOWID">
                    <el-select v-model="postInfo.CLEANOBJECTSNOWID" placeholder="请先选择项目或类型检索点位" filterable
                        style="width:100%">
                        <el-option v-for="item in cleanList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择问题等级" prop="LEVERSNOWID">
                    <el-select v-model="postInfo.LEVERSNOWID" placeholder="选择问题等级" filterable style="width:100%">
                        <el-option v-for="item in leaveList" :key="item.SNOWID" :label="item.LEVEL"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitCleanForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="分配处理人" :visible.sync="userModal" width="800px">
            <el-form :model="userInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="选择处理人" prop="HANDLEUSERSNOWID">
                    <el-select v-model="userInfo.HANDLEUSERSNOWID" placeholder="处理人" filterable style="width:100%">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitUserForm()">提交</el-button>
                    <el-button @click="userModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="设置处理结果" :visible.sync="resultModal" width="800px">
            <el-form :model="resultInfo" status-icon :rules="resultrules" ref="rForm" label-width="100px"
                class="demo-uForm">
                <el-form-item label="是否合格" prop="SEX">
                    <el-radio v-model="resultInfo.ISPASS" label="1">合格</el-radio>
                    <el-radio v-model="resultInfo.ISPASS" label="2">不合格</el-radio>
                </el-form-item>
                <el-form-item label="不合格原因" v-if="resultInfo.ISPASS == '2'" prop="RESULT">
                    <el-input v-model="resultInfo.RESULT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitResultForm()">提交</el-button>
                    <el-button @click="resultModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="问题详情" :visible.sync="detailModal" width="800px" top="30px">
            <div class="pdDetai" style="overflow:hidden" v-if="detailModal">
                <!-- <div class="pdtitle">问题信息</div> -->
                <div class="con">
                    <div class="row">
                        <label>名称：</label>
                        <p>{{ problemItem.NAME }}</p>
                    </div>
                    <div class="row">
                        <label>项目：</label>
                        <p v-if="problemItem.PROJECTNAME">{{ problemItem.PROJECTNAME }}</p>
                        <p v-else>/</p>
                    </div>
                    <div class="row">
                        <label>点位：</label>
                        <p>{{ problemItem.CLEANOBJECTNAME }}</p>
                    </div>
                    <div class="row">
                        <label>问题种类：</label>
                        <p v-if="problemItem.TYPE == 0">预警</p>
                        <p v-else>历史案发</p>
                    </div>
                    <div class="row">
                        <label>问题类型：</label>
                        <p>{{ problemItem.PROBLEMTYPENAME }}</p>
                    </div>
                    <div class="row">
                        <label>问题等级：</label>
                        <p>{{ problemItem.PROBLEMLEVEL.NAME }}</p>
                    </div>
                    <div class="row">
                        <label>问题描述：</label>
                        <p>{{ problemItem.REMARK }}</p>
                    </div>
                    <div class="row">
                        <label>上报人：</label>
                        <p>{{ problemItem.CREATEUSERNAME }}</p>
                    </div>
                    <div class="row">
                        <label>上报时间：</label>
                        <p>{{ problemItem.CREATETIME }}</p>
                    </div>
                    <!-- <div class="row">
                        <label>处理期限：</label>
                        <p v-if="problemItem.DEADLINE">{{problemItem.DEADLINE}}</p>
                        <p v-else>/</p>
                    </div>
                    <div class="row">
                        <label>点位：</label>
                        <p v-if="problemItem.CLEANOBJECTNAME">{{problemItem.CLEANOBJECTNAME}}</p>
                        <p v-else>/</p>
                    </div> -->

                    <!-- <div class="row">
                        <label>当前状态：</label>
                        <P v-if="problemItem.STATUS ==0" style="color:#ff9900;">待处理</P>
                        <P v-else-if="problemItem.STATUS ==1" style="color:#2d8cf0;">处理中</P>
                        <P v-else-if="problemItem.STATUS ==2" style="color:#2db7f5;">已处理</P>
                        <P v-else-if="problemItem.STATUS ==3" style="color:#19be6b;">分配人确认</P>
                        <P v-else-if="problemItem.STATUS ==4" style="color:#808695;">结束</P>
                        <P v-else-if="problemItem.STATUS ==5" style="color:#ed4014;">不予处理</P>
                    </div> -->
                    <div class="row">
                        <label>现场照片：</label>
                        <div class="imgBox">
                            <div class="imgCon" v-for="item in problemItem.imgs" :key="item">
                                <el-image class="imgConOut"
                                    :src="item" :preview-src-list="[item]">
                                </el-image>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="problemItem.video">
                        <label>现场视频：</label>
                        <div class="imgBox">
                            <video :src="problemItem.video" style="width:200px;height:120px;" controls="controls"> </video>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden;" v-if="problemItem.HANDLERESULTLIST.length > 0">
                    <div class="pdtitle">处理详情</div>
                    <div class="con pdconItem" v-for="handel in problemItem.HANDLERESULTLIST" :key="handel.SNOWID">
                        <div class="row">
                            <label>处理人：</label>
                            <p>{{ handel.HANDLEUSERNAME }}</p>
                        </div>
                        <div class="row">
                            <label>分配时间：</label>
                            <p>{{ handel.ASSIGNTIME }}</p>
                        </div>
                        <div class="row">
                            <label>处理状态：</label>
                            <p v-if="handel.ISHANDLE == 1">已处理</p>
                            <p v-else style="color:#808695;">未处理</p>
                        </div>

                        <div class="row" v-if="handel.ISHANDLE == 1">
                            <label>处理时间：</label>
                            <p>{{ handel.HANDLETIME }}</p>
                        </div>
                        <div class="row" v-if="handel.ISHANDLE == 1">
                            <label>处理描述：</label>
                            <p>{{ handel.RESULTREMARK }}</p>
                        </div>
                        <div class="row" v-if="handel.ISHANDLE == 1">
                            <label>验收结果：</label>
                            <p v-if="handel.NOPASSREMARK" style="color:#808695;">{{ handel.NOPASSREMARK }}</p>
                            <p v-else>/</p>
                        </div>
                        <div class="row">
                            <label>处理照片：</label>
                            <div class="imgBox">
                                <div class="imgCon" v-for="imgs in handel.PHOTOLIST" :key="imgs.PATH">
                                    <el-image style="max-width: 100px; max-height: 100px;display: block;margin: 0 auto;"
                                        :src="imgs.PATH" :preview-src-list="[imgs.PATH]">
                                    </el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </el-dialog>
    </div>
</template>
<script>
import TianMap from "../../components/OpenLayers"
export default {
    name: "PostManage",
    components: {
        TianMap,
    },
    data() {
        var CLEANOBJECTSNOWID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择点位"));
            } else {
                callback();
            }
        };
        var LEVERSNOWID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择问题等级"));
            } else {
                callback();
            }
        };


        var HANDLEUSERSNOWID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择处理人"));
            } else {
                callback();
            }
        };

        var RESULT = (rule, value, callback) => {
            if (this.resultInfo.ISPASS == '2' && value === "") {
                callback(new Error("请输入不合格原因"));
            } else {
                callback();
            }
        };




        return {
            canDo: true,
            showPagination: false,
            typeList: [],
            types: [],
            statuss: [],

            xz: "",

            leaves: [],
            leaveList: [],

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            cleanTypeList: [],
            cleanList: [],
            projectList: [],

            problemItem: null,

            postModal: false,
            postInfo: {},
            postrules: {
                CLEANOBJECTSNOWID: [{ validator: CLEANOBJECTSNOWID, trigger: "blur" }],
                LEVERSNOWID: [{ validator: LEVERSNOWID, trigger: "blur" }],
            },

            userList: [],
            userModal: false,
            userInfo: {},
            userrules: {
                HANDLEUSERSNOWID: [{ validator: HANDLEUSERSNOWID, trigger: "blur" }],
            },

            resultModal: false,
            resultInfo: {},
            resultrules: {
                RESULT: [{ validator: RESULT, trigger: "blur" }],
            },


            mapWidth: 660,
            mapHeight: 300,
            tianmap: null,


            detailModal: false,



            role: { add: false, edit: false, del: false }
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PAGEINDEX
            this.statuss = getQueryData.STATUSLIST
            this.types = getQueryData.TYPELIST
            this.leaves = getQueryData.LEAVELIST
        }
        this.showPagination = true
        this.loadItem = null
        this.getPostList()
        this.getTypeList()
        this.getLeaveList()
        this.getCleanTypeList()
        this.getProjectList()

        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {
        tianmapFinishA() {
            this.tianmap = this.$refs.tmap.map
        },
        getTypeList() {
            var params = {
                ISPAGE: false,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/QueryProblemType", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.typeList = response.data.DATA
                    }
                })
        },
        getLeaveList() {
            var params = {
                ISPAGE: false,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/QueryProblemLevelList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.leaveList = response.data.DATA
                    }
                })
        },
        getProjectList() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.projectList = response.data.DATA
                    }
                })
        },
        projectChange(e) {
            this.getCleanList()
        },
        getCleanTypeList() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("CleanObjectType/GetList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.cleanTypeList = response.data.DATA
                    }
                })
        },
        typeChange(e) {
            this.getCleanList()
        },
        getCleanList() {
            var params = {
                CLEANOBJECTTYPEID: this.postInfo.CLEANOBJECTTYPEID,
                PROJECTID: this.postInfo.PROJECTID,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanObject/GetCleanObjectList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.cleanList = response.data.DATA
                    }
                })
        },



        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PageIndex: this.PageIndex,
                PageSize: this.PageSize,
                ISPAGE: true,
                STATUSLIST: this.statuss,
                TYPELIST: this.types,
                LEAVELIST: this.leaves,
                TYPE: this.xz,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/QueryProblemList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        var nl = []
                        for (var i = 0; i < list.length; i++) {
                            var d = list[i]
                            d.CLR = "/"
                            if (d.HANDLERESULTLIST.length > 0) {
                                var us = this.comjs.repeatArrayList(d.HANDLERESULTLIST, 'HANDLEUSERSNOWID')
                                for (var j = 0; j < us.length; j++) {
                                    if (j == 0) {
                                        d.CLR = us[j].HANDLEUSERNAME
                                    } else {
                                        d.CLR = d.CLR + "、" + us[j].HANDLEUSERNAME
                                    }
                                }
                            }

                            nl.push(d)
                        }

                        this.postList = nl
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },

        //修改点位
        setCleanPoint(item) {
            this.problemItem = item
            this.postInfo = {
                PROJECTID: "",
                CLEANOBJECTTYPEID: "",
                CLEANOBJECTSNOWID: "",
                LEVERSNOWID: item.LEVEL,
            }
            this.cleanList = []
            this.postModal = true
            setTimeout(() => {
                if (this.tianmap) {
                    this.$refs.tmap.clearDraw()
                }
                var c = [item.GPSLNG, item.GPSLAT]
                var t = this.$refs.tmap.getMapTarget()
                t.view.setCenter(c)
                this.$refs.tmap.appendDrawPoint(c, false, null)

            }, 500)
        },
        submitCleanForm() {
            if (this.canDo) {
                this.$refs.cForm.validate((valid) => {
                    if (valid) {
                        this.sendChangeClean()
                    } else {
                        return false;
                    }
                });
            }
        },
        sendChangeClean() {
            this.canDo = false
            this.showLoading("请稍后")
            var params = {
                PROBLEMSNOWID: this.problemItem.SNOWID,
                CLEANOBJECTSNOWID: this.postInfo.CLEANOBJECTSNOWID,
                LEVERSNOWID: this.postInfo.LEVERSNOWID,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/EditProblemCleanObject", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        //确定分配人
        getUserList() {
            var md5_data = this.comjs.requestDataEncrypt({ PROBLEMSNOWID: this.problemItem.SNOWID }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ PROBLEMSNOWID: this.problemItem.SNOWID })).toUpperCase())
            this.$http.post("Problem/QueryAssignUserList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.userList = response.data.DATA
                    }
                })
        },
        setUser(item) {
            if (this.canDo) {
                this.$confirm('分配前请确认好点位是否正确？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.problemItem = item
                    this.getUserList()
                    this.userInfo = {
                        HANDLEUSERSNOWID: ""
                    }
                    this.userModal = true
                })
            }
        },

        submitUserForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        this.sendSetUser()
                    } else {
                        return false;
                    }
                });
            }
        },
        sendSetUser() {
            this.canDo = false
            this.showLoading("请稍后")
            var params = {
                PROBLEMSNOWID: this.problemItem.SNOWID,
                HANDLEUSERSNOWID: this.userInfo.HANDLEUSERSNOWID,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/ConfirmHandleUser", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已设置")
                        this.userModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        //设置处理结果
        setResult(item) {
            this.problemItem = item
            this.resultInfo = {
                ISPASS: "1",
                RESULT: ""
            }
            this.resultModal = true
        },

        submitResultForm() {
            if (this.canDo) {
                this.$refs.rForm.validate((valid) => {
                    if (valid) {
                        this.sendSetResult()
                    } else {
                        return false;
                    }
                });
            }
        },
        sendSetResult() {
            this.canDo = false
            this.showLoading("请稍后")
            var hg = true
            var yy = ""
            if (this.resultInfo.ISPASS == "2") {
                hg = false
                yy = this.resultInfo.RESULT
            }
            var params = {
                PROBLEMSNOWID: this.problemItem.SNOWID,
                ISPASS: hg,
                RESULT: yy,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Problem/ConfirmHandleResult", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已处理")
                        this.resultModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },




        //问题详情
        detail(item) {
            item.imgs = []
            item.video = null
            for (let j = 0; j < item.PHOTOLIST.length; j++) {
                if (item.PHOTOLIST[j].PATH.indexOf("mp4") != -1) {
                    item.video = item.PHOTOLIST[j].PATH
                } else {
                    item.imgs.push(item.PHOTOLIST[j].PATH)
                }
            }
            console.log(item)
            this.problemItem = item
            this.detailModal = true
        }
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.pdDetai .pdtitle {
    font-size: 16px;
    font-weight: bold;
}

.pdDetai .con {
    overflow: hidden;
    margin: 20px 0;
}

.pdDetai .con .row {
    overflow: hidden;
    font-size: 14px;
    margin-top: 10px;
}

.pdDetai .con label {
    width: 100px;
    text-align: right;
    float: left
}

.pdDetai .con p,
.pdDetai .con .imgBox {
    float: left;
    overflow: hidden;
}

.pdDetai .con .imgBox .imgCon {
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 10px;
    overflow: hidden;
    background-color: #F8F8F9;
    line-height: 100px;
}

.pdDetai .pdconItem {
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    background-color: #F8F8F9;
}

.imgConOut{overflow: hidden;}
.imgConOut .el-image__inner{max-width: 100%;max-height: 100%;display: block;overflow: hidden;}
</style>