<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">车辆加油管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="carId" placeholder="车辆"  filterable  clearable class="iwu">
                        <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
                    <el-table-column prop="CURROIL" label="当前油量"></el-table-column>
                    <el-table-column prop="OIL" label="加油量"></el-table-column>
                    <el-table-column prop="MONEY" label="加油金额"></el-table-column>
                    <el-table-column prop="OILTIME" label="加油时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="车辆" prop="CARID">
                    <el-select  v-model="postInfo.CARID" placeholder="点击选择车辆" filterable style="width:100%">
                            <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前油量" prop="CURROIL">
                    <el-input v-model="postInfo.CURROIL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="加油量" prop="OIL">
                    <el-input v-model="postInfo.OIL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="加油金额" prop="MONEY">
                    <el-input v-model="postInfo.MONEY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="加油时间" prop="OILTIMES">
                    <el-date-picker v-model="postInfo.OILTIMES" type="datetime" placeholder="加油时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CAROIL",
  data() {
    var CARID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆"));
      } else {
        callback();
      }
    };
    var CURROIL= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入当前油量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('当前油量只能是数字'));
        }else if(value<=0){
            callback(new Error('当前油量必须大于0'));
        }else {
          callback();
        }
    };
    var OIL= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入加油量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('加油量只能是数字'));
        }else if(value<=0){
            callback(new Error('加油量必须大于0'));
        }else {
          callback();
        }
    };
    var MONEY= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入加油金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('加油金额只能是数字'));
        }else if(value<=0){
            callback(new Error('加油金额必须大于0'));
        }else {
          callback();
        }
    };
    var OILTIMES= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择加油时间"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        showPagination:false,
        carList:[],
        carId:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            CARID: [{ validator: CARID, trigger: "blur" }],
            CURROIL: [{ validator: CURROIL, trigger: "blur" }],
            MONEY: [{ validator: MONEY, trigger: "blur" }],
            OIL: [{ validator: OIL, trigger: "blur" }],
            OILTIMES: [{ validator: OILTIMES, trigger: "blur" }],
        },
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex = getQueryData.PAGEINDEX
        this.carId = getQueryData.CARID
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.getCarList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getCarList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.carList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CARID:this.carId,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarOil/GetCarOilList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            CURROIL:"",
            MONEY:"",
            OIL:"",
            OILTIMES:"",
            CARID:"",
        }
        this.doTypeStr="添加加油记录"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CURROIL:this.postInfo.CURROIL,
            MONEY:this.postInfo.MONEY,
            OIL:this.postInfo.OIL,
            OILTIME:this.comjs.getDateStrByDate(this.postInfo.OILTIMES),
            CARID:this.postInfo.CARID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarOil/InsertCarOil", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CAROILSNOWID:item.SNOWID,
            CURROIL:item.CURROIL,
            MONEY:item.MONEY,
            OIL:item.OIL,
            OILTIMES:new Date(item.OILTIME),
            CARID:item.CARID,
        }
        this.doTypeStr="修改加油记录"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CAROILSNOWID:this.postInfo.CAROILSNOWID,
            CURROIL:this.postInfo.CURROIL,
            MONEY:this.postInfo.MONEY,
            OIL:this.postInfo.OIL,
            OILTIME:this.comjs.getDateStrByDate(this.postInfo.OILTIMES),
            CARID:this.postInfo.CARID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarOil/UpdateCarOil", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除加油记录：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("PositonManage/DeletePosition", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>