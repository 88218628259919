<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">行政区管理</p>
    </div>
    <div class="tableBox">
      <div class="views-main-body" style="top:0;">
        <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <span class="managerName">({{ data.code }})</span>
            </span>
          </el-tree>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "ProjectManage",
  components:{
  },
  data() {

    return {
      props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
      },
    };
  },
  mounted() {
    this.getData()
  },
  methods: {

    getList(node, resolve){
        var code=""
        var len="1"
        var isc=""
        var hasChild=true
        if(node.data != undefined){
          code = node.data.code
          isc="1"
          if(code.length==1){
            len="4"
          }else if(code.length==4){
            len="7"
          }
          
        }
        var params={
          CODE:code,
          CODELEN:len,
          ISCHILD:isc
        }
        
        if(hasChild){
          setTimeout(()=>{
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Region/GetRegionList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var d=[]
                  var leaf=false
                  for(var i=0;i<l.length;i++){
                    if(l[i].CODE.length==7){
                      leaf=true
                    }
                    d.push({
                        id:l[i].SNOWID,
                        name: l[i].NAME,
                        code:l[i].CODE,
                        leaf: leaf,
                    })
                    
                  }
                  resolve(d);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },100)
            
        }

    },


  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.nowMachineBox{width: 400px;height: 600px; position: relative;float:left;overflow: hidden;margin-left: 10px;background-color: #f4f4f5;}
.nowMachineBox .mlTitle{height: 40px;line-height: 40px;font-size: 16px;font-weight: bold;padding: 0 12px;}
.nowMachineBox .machineListBox{height: 520px;overflow-y: scroll;}
.nowMachineBox .machineListNull p{color: #adadad;text-align: center;}
.nowMachineBox .addMachineBox{height: 40px;}
.nowMachineBox .addMachineBox .addBtn{width: 100%;border-radius: 0;}

.machineListOut{overflow: hidden;padding: 20px 0px;}
.machineListOut .machineItem{overflow: hidden;height: 40px;cursor: pointer;border-bottom:1px solid #ADADAD;}
.machineListOut .machineItem .machineItemImg{width: 30px;height: 30px;float:left;display: block;margin:5px;}
.machineListOut .machineItem .machineItemTxt{width: 150px;height: 40px;color: #666;line-height: 40px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-size: 15px;float:left;margin-left: 10px;}
.machineListOut .machineItem .removeIcon{float:left;color: #FF0036;}

.el-tree{background: transparent;}
.custom-tree-node{width:100%;}

.xmjj .el-textarea__inner{height:100px!important;}
</style>
