<template>
    <div class="views-mainA">
        <el-form :model="BaiduAk" status-icon :rules="postrules"  ref="aForm" label-width="220px" class="demo-uForm">
                <el-form-item label="百度地图Key" prop="value">
                    <el-input v-model="BaiduAk.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitAForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="TianDiTuAk" status-icon :rules="postrules"  ref="bForm" label-width="220px" class="demo-uForm">
                <el-form-item label="天地图Key" prop="value">
                    <el-input v-model="TianDiTuAk.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitBForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="GZHAppID" status-icon :rules="postrules"  ref="cForm" label-width="220px" class="demo-uForm">
                <el-form-item label="公众号appId" prop="value">
                    <el-input v-model="GZHAppID.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitCForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="GZHAppSecret" status-icon :rules="postrules"  ref="dForm" label-width="220px" class="demo-uForm">
                <el-form-item label="公众号appSecret" prop="value">
                    <el-input v-model="GZHAppSecret.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitDForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   
 

            <el-form :model="LocationUserName" status-icon :rules="postrules"  ref="eForm" label-width="220px" class="demo-uForm">
                <el-form-item label="位置信息服务平台用户名" prop="value">
                    <el-input v-model="LocationUserName.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitEForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="LocationPassword" status-icon :rules="postrules"  ref="fForm" label-width="220px" class="demo-uForm">
                <el-form-item label="位置信息服务平台密码" prop="value">
                    <el-input v-model="LocationPassword.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitFForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="LocationAPPKEY" status-icon :rules="postrules"  ref="gForm" label-width="220px" class="demo-uForm">
                <el-form-item label="位置信息服务平台APPKEY" prop="value">
                    <el-input v-model="LocationAPPKEY.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitGForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="LocationAPPSECRET" status-icon :rules="postrules"  ref="hForm" label-width="220px" class="demo-uForm">
                <el-form-item label="位置信息服务平台APPSECRET" prop="value">
                    <el-input v-model="LocationAPPSECRET.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitHForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>  



            <el-form :model="BDIP" status-icon :rules="postrules"  ref="iForm" label-width="220px" class="demo-uForm">
                <el-form-item label="北斗监控设备IP" prop="value">
                    <el-input v-model="BDIP.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitIForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="BDPort" status-icon :rules="postrules"  ref="jForm" label-width="220px" class="demo-uForm">
                <el-form-item label="北斗监控设备端口号" prop="value">
                    <el-input v-model="BDPort.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitJForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="BDUserName" status-icon :rules="postrules"  ref="kForm" label-width="220px" class="demo-uForm">
                <el-form-item label="北斗监控设备账号" prop="value">
                    <el-input v-model="BDUserName.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitKForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form>   

            <el-form :model="BDPassword" status-icon :rules="postrules"  ref="lForm" label-width="220px" class="demo-uForm">
                <el-form-item label="北斗监控设备密码" prop="value">
                    <el-input v-model="BDPassword.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitLForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form> 

            <el-form :model="KQRule" status-icon :rules="postrules"  ref="mForm" label-width="220px" class="demo-uForm">
                <el-form-item label="考勤规则" prop="value">
                    <el-input v-model="KQRule.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitMForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form> 

            <el-form :model="PushTemplateld" status-icon :rules="postrules"  ref="nForm" label-width="220px" class="demo-uForm">
                <el-form-item label="推送模板ID" prop="value">
                    <el-input v-model="PushTemplateld.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitNForm()" v-if="role.edit">保存</el-button>
                </el-form-item>
            </el-form> 
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "CARLocationType",
  props: {
    TOKEN: {
      type: String,
      default: "",
    },
    ROTE:{
        type:String,
        default: "0",
    }
  },
  data() {
    var value = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("值不能为空"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        BaiduAk:{
            id:"",
            value:"",
            key:"BaiduAk",
        },

        TianDiTuAk:{
            id:"",
            value:"",
            key:"TianDiTuAk"
        },

        GZHAppID:{
            id:"",
            value:"",
            key:"GZHAppID"
        },

        GZHAppSecret:{
            id:"",
            value:"",
            key:"GZHAppSecret"
        },

        LocationUserName:{
            id:"",
            value:"",
            key:"LocationUserName"
        },

        LocationPassword:{
            id:"",
            value:"",
            key:"LocationPassword"
        },

        LocationAPPKEY:{
            id:"",
            value:"",
            key:"LocationAPPKEY"
        },

        LocationAPPSECRET:{
            id:"",
            value:"",
            key:"LocationAPPSECRET"
        },

        BDIP:{
            id:"",
            value:"",
            key:"BDIP"
        },

        BDPort:{
            id:"",
            value:"",
            key:"BDPort"
        },

        BDUserName:{
            id:"",
            value:"",
            key:"BDUserName"
        },

        BDPassword:{
            id:"",
            value:"",
            key:"BDPassword"
        },

        KQRule:{
            id:"",
            value:"",
            key:"KQRule"
        },

        PushTemplateld:{
            id:"",
            value:"",
            key:"PushTemplateld"
        },


        postrules: {
            value: [{ validator: value, trigger: "blur" }],
        },
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostListA()
    this.getPostListB()
    this.getPostListC()
    this.getPostListD()

    this.getPostListE()
    this.getPostListF()
    this.getPostListG()
    this.getPostListH()

    this.getPostListI()
    this.getPostListJ()
    this.getPostListK()
    this.getPostListL()

    this.getPostListM()
    this.getPostListN()

    if(this.ROTE=="1"){
        this.role=this.setPageRoles(this.$route.path)
        setTimeout(()=>{
            this.role=this.setPageRoles(this.$route.path)
        },3000)
    }else{
        this.role={add:true,edit:true,del:true}
    }
  },
  methods: {
    getPostListA(){
        var params={
            CKEY:'BaiduAk',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=BaiduAk&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.BaiduAk.id=d.SNOWID
                        this.BaiduAk.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListB(){
        var params={
            CKEY:'TianDiTuAk',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=TianDiTuAk&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.TianDiTuAk.id=d.SNOWID
                        this.TianDiTuAk.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListC(){
        var params={
            CKEY:'GZHAppID',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=GZHAppID&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.GZHAppID.id=d.SNOWID
                        this.GZHAppID.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListD(){
        var params={
            CKEY:'GZHAppSecret',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=GZHAppSecret&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.GZHAppSecret.id=d.SNOWID
                        this.GZHAppSecret.value=d.CVALUE
                    }
                } 
            })
    },

    getPostListE(){
        var params={
            CKEY:'LocationUserName',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=LocationUserName&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.LocationUserName.id=d.SNOWID
                        this.LocationUserName.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListF(){
        var params={
            CKEY:'LocationPassword',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=LocationPassword&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.LocationPassword.id=d.SNOWID
                        this.LocationPassword.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListG(){
        var params={
            CKEY:'LocationAPPKEY',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=LocationAPPKEY&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.LocationAPPKEY.id=d.SNOWID
                        this.LocationAPPKEY.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListH(){
        var params={
            CKEY:'LocationAPPSECRET',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=LocationAPPSECRET&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.LocationAPPSECRET.id=d.SNOWID
                        this.LocationAPPSECRET.value=d.CVALUE
                    }
                } 
            })
    },


    getPostListI(){
        var params={
            CKEY:'BDIP',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=BDIP&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.BDIP.id=d.SNOWID
                        this.BDIP.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListJ(){
        var params={
            CKEY:'BDPort',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=BDPort&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.BDPort.id=d.SNOWID
                        this.BDPort.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListK(){
        var params={
            CKEY:'BDUserName',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=BDUserName&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.BDUserName.id=d.SNOWID
                        this.BDUserName.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListL(){
        var params={
            CKEY:'BDPassword',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=BDPassword&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.BDPassword.id=d.SNOWID
                        this.BDPassword.value=d.CVALUE
                    }
                } 
            })
    },


    getPostListM(){
        var params={
            CKEY:'KQRule',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=KQRule&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.KQRule.id=d.SNOWID
                        this.KQRule.value=d.CVALUE
                    }
                } 
            })
    },
    getPostListN(){
        var params={
            CKEY:'PushTemplateld',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/GetSysConfigByCKey?CKey=PushTemplateld&token="+this.$store.state.token, md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    if(response.data.DATA){
                        var d=response.data.DATA
                        this.PushTemplateld.id=d.SNOWID
                        this.PushTemplateld.value=d.CVALUE
                    }
                } 
            })
    },
    sendAdd(item){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CKEY:item.key,
            CVALUE:item.value
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/Insert", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                    var id=response.data.DATA.SNOWID
                    if(item.key=="BaiduAk"){
                       this.BaiduAk.id=id
                    }else if(item.key=="TianDiTuAk"){
                        this.TianDiTuAk.id=id
                    }else if(item.key=="GZHAppID"){
                        this.GZHAppID.id=id
                    }else if(item.key=="GZHAppSecret"){
                        this.GZHAppSecret.id=id
                    }else if(item.key=="LocationUserName"){
                        this.LocationUserName.id=id
                    }else if(item.key=="LocationPassword"){
                        this.LocationPassword.id=id
                    }else if(item.key=="LocationAPPKEY"){
                        this.LocationAPPKEY.id=id
                    }else if(item.key=="LocationAPPSECRET"){
                        this.LocationAPPSECRET.id=id
                    }else if(item.key=="BDIP"){
                        this.BDIP.id=id
                    }else if(item.key=="BDPort"){
                        this.BDPort.id=id
                    }else if(item.key=="BDUserName"){
                        this.BDUserName.id=id
                    }else if(item.key=="BDPassword"){
                        this.BDPassword.id=id
                    }else if(item.key=="KQRule"){
                        this.KQRule.id=id
                    }else if(item.key=="PushTemplateld"){
                        this.PushTemplateld.id=id
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    sendEdit(params){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysConfig/Update", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitAForm() {
        if (this.canDo) {
            this.$refs.aForm.validate((valid) => {
                if (valid) {
                    if(this.BaiduAk.id==""){
                        this.sendAdd(this.BaiduAk)
                    }else {
                        var params={
                            SNOWID:this.BaiduAk.id,
                            CKEY:this.BaiduAk.key,
                            CVALUE:this.BaiduAk.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitBForm() {
        if (this.canDo) {
            this.$refs.bForm.validate((valid) => {
                if (valid) {
                    if(this.TianDiTuAk.id==""){
                        this.sendAdd(this.TianDiTuAk)
                    }else {
                        var params={
                            SNOWID:this.TianDiTuAk.id,
                            CKEY:this.TianDiTuAk.key,
                            CVALUE:this.TianDiTuAk.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitCForm() {
        if (this.canDo) {
            this.$refs.cForm.validate((valid) => {
                if (valid) {
                    if(this.GZHAppID.id==""){
                        this.sendAdd(this.GZHAppID)
                    }else {
                        var params={
                            SNOWID:this.GZHAppID.id,
                            CKEY:this.GZHAppID.key,
                            CVALUE:this.GZHAppID.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitDForm() {
        if (this.canDo) {
            this.$refs.dForm.validate((valid) => {
                if (valid) {
                    if(this.GZHAppSecret.id==""){
                        this.sendAdd(this.GZHAppSecret)
                    }else {
                        var params={
                            SNOWID:this.GZHAppSecret.id,
                            CKEY:this.GZHAppSecret.key,
                            CVALUE:this.GZHAppSecret.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },

    submitEForm() {
        if (this.canDo) {
            this.$refs.eForm.validate((valid) => {
                if (valid) {
                    if(this.LocationUserName.id==""){
                        this.sendAdd(this.LocationUserName)
                    }else {
                        var params={
                            SNOWID:this.LocationUserName.id,
                            CKEY:this.LocationUserName.key,
                            CVALUE:this.LocationUserName.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitFForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    if(this.LocationPassword.id==""){
                        this.sendAdd(this.LocationPassword)
                    }else {
                        var params={
                            SNOWID:this.LocationPassword.id,
                            CKEY:this.LocationPassword.key,
                            CVALUE:this.LocationPassword.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitGForm() {
        if (this.canDo) {
            this.$refs.gForm.validate((valid) => {
                if (valid) {
                    if(this.LocationAPPKEY.id==""){
                        this.sendAdd(this.LocationAPPKEY)
                    }else {
                        var params={
                            SNOWID:this.LocationAPPKEY.id,
                            CKEY:this.LocationAPPKEY.key,
                            CVALUE:this.LocationAPPKEY.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitHForm() {
        if (this.canDo) {
            this.$refs.hForm.validate((valid) => {
                if (valid) {
                    if(this.LocationAPPSECRET.id==""){
                        this.sendAdd(this.LocationAPPSECRET)
                    }else {
                        var params={
                            SNOWID:this.LocationAPPSECRET.id,
                            CKEY:this.LocationAPPSECRET.key,
                            CVALUE:this.LocationAPPSECRET.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },

    submitIForm() {
        if (this.canDo) {
            this.$refs.iForm.validate((valid) => {
                if (valid) {
                    if(this.BDIP.id==""){
                        this.sendAdd(this.BDIP)
                    }else {
                        var params={
                            SNOWID:this.BDIP.id,
                            CKEY:this.BDIP.key,
                            CVALUE:this.BDIP.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitJForm() {
        if (this.canDo) {
            this.$refs.jForm.validate((valid) => {
                if (valid) {
                    if(this.BDPort.id==""){
                        this.sendAdd(this.BDPort)
                    }else {
                        var params={
                            SNOWID:this.BDPort.id,
                            CKEY:this.BDPort.key,
                            CVALUE:this.BDPort.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitKForm() {
        if (this.canDo) {
            this.$refs.kForm.validate((valid) => {
                if (valid) {
                    if(this.BDUserName.id==""){
                        this.sendAdd(this.BDUserName)
                    }else {
                        var params={
                            SNOWID:this.BDUserName.id,
                            CKEY:this.BDUserName.key,
                            CVALUE:this.BDUserName.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitLForm() {
        if (this.canDo) {
            this.$refs.lForm.validate((valid) => {
                if (valid) {
                    if(this.BDPassword.id==""){
                        this.sendAdd(this.BDPassword)
                    }else {
                        var params={
                            SNOWID:this.BDPassword.id,
                            CKEY:this.BDPassword.key,
                            CVALUE:this.BDPassword.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },

    submitMForm() {
        if (this.canDo) {
            this.$refs.lForm.validate((valid) => {
                if (valid) {
                    if(this.KQRule.id==""){
                        this.sendAdd(this.KQRule)
                    }else {
                        var params={
                            SNOWID:this.KQRule.id,
                            CKEY:this.KQRule.key,
                            CVALUE:this.KQRule.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },
    submitNForm() {
        if (this.canDo) {
            this.$refs.lForm.validate((valid) => {
                if (valid) {
                    if(this.PushTemplateld.id==""){
                        this.sendAdd(this.PushTemplateld)
                    }else {
                        var params={
                            SNOWID:this.PushTemplateld.id,
                            CKEY:this.PushTemplateld.key,
                            CVALUE:this.PushTemplateld.value,
                        }
                        this.sendEdit(params)
                    }
                } else {
                    return false;
                }
            });
        }
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>