<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">请求列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iww" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delPosts" class="fr mr20" v-if="role.del">批量删除</el-button>
                <el-button type="primary" @click="carPost" class="fr">车辆定位类型</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="postSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                    <el-table-column prop="METHOD" label="请求类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.METHOD==0">GET</span>
                            <span v-else>POST</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="URL" label="地址" width="400"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                            <el-button @click="queryPost(scope.row)" type="text" size="small" style="color:#409EFF">请求参数</el-button>
                            <el-button @click="responsePost(scope.row)" type="text" size="small" style="color:#409EFF">返回参数</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-radio v-model="postInfo.METHOD" label="1">POST</el-radio>
                    <el-radio v-model="postInfo.METHOD" label="0">GET</el-radio>
                </el-form-item>
                <el-form-item label="请求地址" prop="URL">
                    <el-input v-model="postInfo.URL" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- :height="dialogMaxHeigh" -->
        <el-dialog title="请求参数" :visible.sync="queryListModal" width="1000px" :append-to-body="true">
            <div>
                <el-input v-model="queryname" placeholder="请输入名称" class="iww" ></el-input>
                <el-button type="primary" @click="searchQuery">查询</el-button>
                <el-button type="danger" @click="delQuerys" class="fr mr20" v-if="role.del">批量删除</el-button>
                <el-button type="success" @click="addQuery" class="fr" v-if="role.add">添加</el-button>
            </div>
            <el-table :data="queryList" stripe style="width: 100%" @selection-change="querySelectionChange" ref="queryList" :height="dialogMaxHeigh">
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                <el-table-column prop="QUERYTYPE" label="参数类型">
                    <template slot-scope="scope">
                            <span v-if="scope.row.QUERYTYPE==0">QUERY</span>
                            <span v-else>BODY</span>
                        </template>
                </el-table-column>
                <el-table-column prop="VALUEREQUESTID" label="参数值关联请求主键"></el-table-column>
                <el-table-column prop="VALUERESPONSEID" label="参数值关联返回主键"></el-table-column>
                <el-table-column prop="VALUECONTENT" label="参数值"></el-table-column>
                <el-table-column prop="PARAMETER" label="参数"></el-table-column>
                <el-table-column prop="QUERYTYPE" label="VALUETYPE">
                    <template slot-scope="scope">
                            <span v-if="scope.row.VALUETYPE==0">固定值</span>
                            <span v-else-if="scope.row.VALUETYPE==1">动态值_请求</span>
                            <span v-else-if="scope.row.VALUETYPE==2">动态值_表</span>
                            <span v-else-if="scope.row.VALUETYPE==3">动态值_当前时间</span>
                            <span v-else-if="scope.row.VALUETYPE==4">动态值_上次时间</span>
                        </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editQuery(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <el-button @click="delQuery(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexQ" :page-size="PageSizeQ" :total="TotalQ" class="pg" @current-change="PGChangeQ"></el-pagination>
            <div style="height:30px;overflow:hidden;"></div>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="queryFormModal" width="800px" :append-to-body="true">
            <el-form :model="queryInfo" status-icon :rules="queryrules"  ref="qForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="queryInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="参数类型">
                    <el-radio v-model="queryInfo.QUERYTYPE" label="1">BODY</el-radio>
                    <el-radio v-model="queryInfo.QUERYTYPE" label="0">QUERY</el-radio>
                </el-form-item>
                <el-form-item label="参数值关联请求主键" prop="VALUEREQUESTID" v-if="queryInfo.VALUETYPE =='1'">
                    <el-select v-model="queryInfo.VALUEREQUESTID" placeholder="请选择参数值关联请求主键"  filterable  style="width:100%;" @change="queryResquestChange">
                        <el-option v-for="item in queryRequests" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="参数值关联返回主键" prop="VALUERESPONSEID" v-if="queryInfo.VALUETYPE =='1'">
                    <el-select v-model="queryInfo.VALUERESPONSEID" placeholder="请选择参数值关联返回主键"  filterable  style="width:100%;">
                        <el-option v-for="item in queryResponses" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="参数值" prop="VALUECONTENT" v-if="queryInfo.VALUETYPE !='1'">
                    <el-input v-model="queryInfo.VALUECONTENT" autocomplete="off" v-if="queryInfo.VALUETYPE =='0' ||  queryInfo.VALUETYPE =='1'"></el-input>
                    <el-select v-model="queryInfo.VALUECONTENT" placeholder="请选择参数值"  filterable  style="width:100%;" v-else-if="queryInfo.VALUETYPE =='2'">
                        <el-option label="创建时间" value="CREATETIME" ></el-option>
                        <el-option label="删除时间" value="DELETETIME" ></el-option>
                        <el-option label="是否删除" value="ISDELETE" ></el-option>
                        <el-option label="更新时间" value="UPDATETIME" ></el-option>
                        <el-option label="百度经度" value="BAIDULON" ></el-option>
                        <el-option label="百度纬度" value="BAIDULAT" ></el-option>
                        <el-option label="GPS经度" value="LON" ></el-option>
                        <el-option label="GPS纬度" value="LAT" ></el-option>
                        <el-option label="发动机编号" value="ENGINENO" ></el-option>
                        <el-option label="SIM号码" value="SIMNUMBER" ></el-option>
                        <el-option label="车架号" value="FRAMENUMBER" ></el-option>
                        <el-option label="市级平台分类代码" value="LJFLTYPE" ></el-option>
                        <el-option label="车辆类型" value="CARTYPEID" ></el-option>
                        <el-option label="GPS编号" value="GPSNO" ></el-option>
                        <el-option label="定位ID" value="LOCATIONID" ></el-option>
                        <el-option label="速度" value="SPEED" ></el-option>
                        <el-option label="GPS时间" value="GPSTIME" ></el-option>
                        <el-option label="车载监控IP" value="IP" ></el-option>
                        <el-option label="车牌号码" value="PLATENUMBER" ></el-option>
                        <el-option label="监控数量" value="VIDEONUM" ></el-option>
                        <el-option label="设备号" value="DEVIDNO" ></el-option>
                        <el-option label="价格" value="PRICE" ></el-option>
                        <el-option label="车辆定位授权码" value="VKEY" ></el-option>
                        <el-option label="方向" value="DIRECT" ></el-option>
                        <el-option label="是否登录" value="ISLOGIN" ></el-option>
                        <el-option label="项目ID" value="PROJECTID" ></el-option>
                        <el-option label="监控是否登录" value="ISMONITORLOGIN" ></el-option>
                        <el-option label="项目处编号" value="PROJECTNUMBER" ></el-option>
                        <el-option label="定位类型主键" value="LOCATIONTYPEID" ></el-option>
                    </el-select>
                    <el-select v-model="queryInfo.VALUECONTENT" placeholder="请选择参数值"  filterable  style="width:100%;" v-else-if="queryInfo.VALUETYPE =='3' || queryInfo.VALUETYPE =='4'">
                        <el-option label="时间戳" value="1" ></el-option>
                        <el-option label="yyyy-MM-dd" value="2" ></el-option>
                        <el-option label="yyyy-MM-dd HH:mm:ss" value="3" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="参数" prop="PARAMETER">
                    <el-input v-model="queryInfo.PARAMETER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="参数值类型">
                    <el-radio v-model="queryInfo.VALUETYPE" label="0">固定值</el-radio>
                    <el-radio v-model="queryInfo.VALUETYPE" label="1">动态值_请求</el-radio>
                    <el-radio v-model="queryInfo.VALUETYPE" label="2">动态值_表</el-radio>
                    <el-radio v-model="queryInfo.VALUETYPE" label="3">动态值_当前时间</el-radio>
                    <el-radio v-model="queryInfo.VALUETYPE" label="4">动态值_上次时间</el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitQueryForm()">提交</el-button>
                    <el-button @click="queryFormModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="请求返回参数" :visible.sync="responseListModal" width="1000px" :append-to-body="true">
            <div>
                <el-input v-model="responsename" placeholder="请输入名称" class="iww" ></el-input>
                <el-button type="primary" @click="searchResponse">查询</el-button>
                <el-button type="danger" @click="delResponses" class="fr mr20" v-if="role.del">批量删除</el-button>
                <el-button type="success" @click="addResponse" class="fr" v-if="role.add">添加</el-button>
            </div>
            <el-table :data="responseList" stripe style="width: 100%" @selection-change="responseSelectionChange" ref="responseList" :height="dialogMaxHeigh"> 
                <el-table-column type="selection" width="60"></el-table-column>
                <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                <el-table-column prop="ISARRAY" label="是否为数组">
                    <template slot-scope="scope">
                            <span v-if="scope.row.ISARRAY==1">是</span>
                            <span v-else>否</span>
                        </template>
                </el-table-column>
                <el-table-column prop="FIELDNAME" label="映射字段名称"></el-table-column>
                <el-table-column prop="PARENTID" label="父级主键"></el-table-column>
                <el-table-column prop="PARAMETER" label="参数"></el-table-column>
                <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editResponse(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                            <el-button @click="delResponse(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexR" :page-size="PageSizeR" :total="TotalR" class="pg" @current-change="PGChangeR"></el-pagination>
            <div style="height:30px;overflow:hidden;"></div>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="responseFormModal" width="800px" :append-to-body="true">
            <el-form :model="responseInfo" status-icon :rules="responserules"  ref="rForm" label-width="150px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="responseInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否为数组">
                    <el-radio v-model="responseInfo.ISARRAY" label="1">是</el-radio>
                    <el-radio v-model="responseInfo.ISARRAY" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="映射字段名称">
                    <el-select v-model="responseInfo.FIELDNAME" placeholder="请选择映射字段名称"  filterable  style="width:100%;">
                        <el-option label="GPS经度" value="LON" ></el-option>
                        <el-option label="GPS纬度" value="LAT" ></el-option>
                        <el-option label="GPS时间" value="GPSTIME" ></el-option>
                        <el-option label="速度" value="SPEED" ></el-option>
                        <el-option label="方向" value="DIRECT" ></el-option>
                        <el-option label="系统时间" value="SYSTIME" ></el-option>
                        <el-option label="距离" value="DISTANCE" ></el-option>
                        <el-option label="总距离" value="TOTALDIS" ></el-option>
                        <el-option label="是否登录" value="ISLOGIN" ></el-option>
                        <el-option label="智能设备开关" value="ACC" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="父级主键">
                    <el-select v-model="responseInfo.PARENTID" placeholder="请选择父级主键"  filterable  style="width:100%;">
                        <el-option v-for="item in responseParents" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="参数" prop="PARAMETER">
                    <el-input v-model="responseInfo.PARAMETER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitResponseForm()">提交</el-button>
                    <el-button @click="responseFormModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="doTypeStr" :visible.sync="carLocationTypeModal" width="1200px" :append-to-body="true" top="50px">
          <CLT :style="{height:(dialogMaxHeigh+300)+'px'}" :TOKEN="tokenstr"  ROTE="1" ref="clt"></CLT>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import CLT from "../../components/CarLocationType"

export default {
  name: "PostManage",
  components:{
    CLT,
  },
  data() {
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var URL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入地址"));
      } else {
        callback();
      }
    };

    var VALUEREQUESTID = (rule, value, callback) => {
      if (value === "" && this.queryInfo.VALUETYPE =='1') {
        callback(new Error("请选择参数值关联请求主键"));
      } else {
        callback();
      }
    };
    var VALUERESPONSEID = (rule, value, callback) => {
      if (value === "" && this.queryInfo.VALUETYPE =='1') {
        callback(new Error("请选择参数值关联返回主键"));
      } else {
        callback();
      }
    };
    var VALUECONTENT = (rule, value, callback) => {
      if (value === "" && this.queryInfo.VALUETYPE !='1') {
        callback(new Error("请输入参数值"));
      } else {
        callback();
      }
    };
    var PARAMETER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入参数"));
      } else {
        callback();
      }
    };

    var FIELDNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入映射字段名称"));
      } else {
        callback();
      }
    };

    var PARENTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择父级主键"));
      } else {
        callback();
      }
    };

    return {
        dialogMaxHeigh:0,
        canDo:true,
        showPagination:false,
        name:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        checkPosts:[],

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            URL: [{ validator: URL, trigger: "blur" }],
        },


        sysOper:[],
        

        postItem:null,

        queryname:"",
        queryList:[],
        PageIndexQ:1,
        PageSizeQ:10,
        TotalQ:0,
        checkQuery:[],
        queryListModal:false,
        queryFormModal:false,
        queryInfo:{},
        queryrules: {
            VALUEREQUESTID: [{ validator: VALUEREQUESTID, trigger: "blur" }],
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            VALUERESPONSEID: [{ validator: VALUERESPONSEID, trigger: "blur" }],
            VALUECONTENT: [{ validator: VALUECONTENT, trigger: "blur" }],
            PARAMETER: [{ validator: PARAMETER, trigger: "blur" }],
        },

        queryRequests:[],
        queryResponses:[],


        responsename:"",
        responseList:[],
        PageIndexR:1,
        PageSizeR:10,
        TotalR:0,
        checkResponse:[],
        responseListModal:false,
        responseFormModal:false,
        responseInfo:{},
        responserules: {
            // FIELDNAME: [{ validator: FIELDNAME, trigger: "blur" }],
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            PARENTID: [{ validator: PARENTID, trigger: "blur" }],
            PARAMETER: [{ validator: PARAMETER, trigger: "blur" }],
        },
        responseParents:[],

        role:{add:false,edit:false,del:false},

        carLocationTypeModal:false,
        tokenstr:"",
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.name=getQueryData.DISPLAYNAME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.dialogMaxHeigh = h -500
    this.getRequestList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getRequestList(){
        var params={
            PageIndex:1,
            PageSize:500,
            DISPLAYNAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetRequestList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.queryRequests=response.data.DATA
                }
            })
    },
    queryResquestChange(e){
        this.queryResponses=[]
        if(e!=""){
           this.getResponseListById(e)
        }
    },
    getResponseListById(id){
        this.showLoading("请稍后")
        var params={
            PageIndex:1,
            PageSize:500,
            SNOWID:id,
            DISPLAYNAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetResponseList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.queryResponses=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            DISPLAYNAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetRequestList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
        this.getRequestList()
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            DISPLAYNAME:"",
            METHOD:"1",
            URL:"",
        }
        this.doTypeStr="添加请求"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            DISPLAYNAME:this.postInfo.DISPLAYNAME,
            METHOD:parseInt(this.postInfo.METHOD),
            URL:this.postInfo.URL,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/InsertRequest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            DISPLAYNAME:item.DISPLAYNAME,
            METHOD:item.METHOD.toString(),
            URL:item.URL,
        }
        this.doTypeStr="修改请求"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postInfo.SNOWID,
            DISPLAYNAME:this.postInfo.DISPLAYNAME,
            METHOD:parseInt(this.postInfo.METHOD),
            URL:this.postInfo.URL,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/EditRequest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除请求：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel([item.SNOWID])
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWIDLIST:ids}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWIDLIST:ids})).toUpperCase())
        this.$http.post("Request/DeleteRequest", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    postSelectionChange(list){
        this.checkPosts=[]
        for(var i=0;i<list.length;i++){
            this.checkPosts.push(list[i].SNOWID)
        }
    },
    delPosts(){
        if(this.checkPosts.length==0){
            this.$message.error("请先选择需要删除的请求")
        }else{
            if(this.canDo){
                this.$confirm('确定删除选择的请求？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.sendDel(this.checkPosts)
                }).catch(() => {
                this.$message.info("已取消")    
            });
            }
        }
    },

    //请求参数
    queryPost(item){
        this.queryListModal=true
        this.postItem=item
        this.checkQuery=[]
        this.getQueryList()
    },
    getQueryList(){
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postItem.SNOWID,
            PageIndex:this.PageIndexQ,
            PageSize:this.PageSizeQ,
            DISPLAYNAME:this.queryname,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetRequestQueryList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.queryList=response.data.DATA
                    this.TotalQ=response.data.TOTALCOUNT
                } else {
                    this.queryList=[]
                    this.TotalQ=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.queryList=[]
                this.TotalQ=0
        });
    },
    searchQuery(){
        this.PageIndexQ=1
        this.getQueryList()
    },
    addQuery(){
        this.queryInfo={
            REQUESTID:this.postItem.SNOWID,
            QUERYTYPE:"1",
            VALUEREQUESTID:"",
            DISPLAYNAME:"",
            VALUERESPONSEID:"",
            VALUECONTENT:"",
            PARAMETER:"",
            VALUETYPE:"0"
        }
        this.doTypeStr="添加请求参数"
        this.doType="1"
        this.queryFormModal=true
    },
    sendAddQuery(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            REQUESTID:this.queryInfo.REQUESTID,
            QUERYTYPE:parseInt(this.queryInfo.QUERYTYPE),
            VALUEREQUESTID:this.queryInfo.VALUEREQUESTID,
            DISPLAYNAME:this.queryInfo.DISPLAYNAME,
            VALUERESPONSEID:this.queryInfo.VALUERESPONSEID,
            VALUECONTENT:this.queryInfo.VALUECONTENT,
            PARAMETER:this.queryInfo.PARAMETER,
            VALUETYPE:parseInt(this.queryInfo.VALUETYPE),
        }
        if(params.VALUETYPE!="1"){
            params.VALUEREQUESTID=""
            params.VALUERESPONSEID=""
        }else{
            params.VALUECONTENT=""
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/InsertRequestQuery", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.queryFormModal=false
                    this.getQueryList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editQuery(item){
        this.queryInfo={
            SNOWID:item.SNOWID,
            QUERYTYPE:item.QUERYTYPE.toString(),
            VALUEREQUESTID:item.VALUEREQUESTID,
            DISPLAYNAME:item.DISPLAYNAME,
            VALUERESPONSEID:item.VALUERESPONSEID,
            VALUECONTENT:item.VALUECONTENT,
            PARAMETER:item.PARAMETER,
            VALUETYPE:item.VALUETYPE.toString(),
        }
        this.doTypeStr="修改请求参数"
        this.doType="2"
        this.queryFormModal=true
    },
    sendEditQuery(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.queryInfo.SNOWID,
            QUERYTYPE:parseInt(this.queryInfo.QUERYTYPE),
            VALUEREQUESTID:this.queryInfo.VALUEREQUESTID,
            DISPLAYNAME:this.queryInfo.DISPLAYNAME,
            VALUERESPONSEID:this.queryInfo.VALUERESPONSEID,
            VALUECONTENT:this.queryInfo.VALUECONTENT,
            PARAMETER:this.queryInfo.PARAMETER,
            VALUETYPE:parseInt(this.queryInfo.VALUETYPE),
        }
        if(params.VALUETYPE!="1"){
            params.VALUEREQUESTID=""
            params.VALUERESPONSEID=""
        }else{
            params.VALUECONTENT=""
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/EditRequestQuery", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.queryFormModal=false
                    this.getQueryList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delQuery(item){
        if(this.canDo){
            this.$confirm('确定删除请求参数：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendQueryDel([item.SNOWID])
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendQueryDel(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWIDLIST:ids}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWIDLIST:ids})).toUpperCase())
        this.$http.post("Request/DeleteRequestQuery", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.queryFormModal=false
                    this.getQueryList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChangeQ(e){
        this.PageIndexQ=e
        this.getQueryList()
    },
    submitQueryForm() {
        if (this.canDo) {
            this.$refs.qForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAddQuery()
                    }else if(this.doType=="2"){
                        this.sendEditQuery()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    querySelectionChange(list){
        this.checkQuery=[]
        for(var i=0;i<list.length;i++){
            this.checkQuery.push(list[i].SNOWID)
        }
    },
    delQuerys(){
        if(this.checkQuery.length==0){
            this.$message.error("请先选择需要删除的请求参数")
        }else{
            if(this.canDo){
                this.$confirm('确定删除选择的请求参数？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.sendQueryDel(this.checkQuery)
                }).catch(() => {
                this.$message.info("已取消")    
            });
            }
        }
    },

    //返回参数
    responsePost(item){
        this.responseListModal=true
        this.postItem=item
        this.checkResponse=[]
        this.getResponseList()
    },
    getResponsesList(id){
        var params={
            SNOWID:this.postItem.SNOWID,
            PageIndex:1,
            PageSize:500,
            DISPLAYNAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetResponseList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        if(list[i].SNOWID != id){
                            nl.push(list[i])
                        }
                    }
                    this.responseParents=nl
                }
            })
    },
    getResponseList(){
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postItem.SNOWID,
            PageIndex:this.PageIndexQ,
            PageSize:this.PageSizeQ,
            DISPLAYNAME:this.responsename,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/GetResponseList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.responseList=response.data.DATA
                    this.TotalR=response.data.TOTALCOUNT
                } else {
                    this.responseList=[]
                    this.TotalR=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.responseList=[]
                this.TotalR=0
        });
    },
    searchResponse(){
        this.PageIndexR=1
        this.getResponseList()
    },
    addResponse(){
        this.getResponsesList("")
        this.responseInfo={
            REQUESTID:this.postItem.SNOWID,
            ISARRAY:"0",
            FIELDNAME:"",
            DISPLAYNAME:"",
            PARENTID:"",
            PARAMETER:"",
        }
        this.doTypeStr="添加请求返回参数"
        this.doType="1"
        this.responseFormModal=true
        
    },
    sendAddResponse(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            REQUESTID:this.responseInfo.REQUESTID,
            ISARRAY:parseInt(this.responseInfo.ISARRAY),
            FIELDNAME:this.responseInfo.FIELDNAME,
            DISPLAYNAME:this.responseInfo.DISPLAYNAME,
            PARENTID:this.responseInfo.PARENTID,
            PARAMETER:this.responseInfo.PARAMETER,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/InsertRespoonse", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.responseFormModal=false
                    this.getResponseList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editResponse(item){
        this.getResponsesList(item.PARENTID)
        this.responseInfo={
            SNOWID:item.SNOWID,
            ISARRAY:item.ISARRAY.toString(),
            FIELDNAME:item.FIELDNAME,
            DISPLAYNAME:item.DISPLAYNAME,
            PARENTID:item.PARENTID,
            PARAMETER:item.PARAMETER,
        }
        this.doTypeStr="修改请求返回参数"
        this.doType="2"
        this.responseFormModal=true
    },
    sendEditResponse(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.responseInfo.SNOWID,
            ISARRAY:parseInt(this.responseInfo.ISARRAY),
            FIELDNAME:this.responseInfo.FIELDNAME,
            DISPLAYNAME:this.responseInfo.DISPLAYNAME,
            PARENTID:this.responseInfo.PARENTID,
            PARAMETER:this.responseInfo.PARAMETER,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Request/EditResponse", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.responseFormModal=false
                    this.getResponseList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delResponse(item){
        if(this.canDo){
            this.$confirm('确定删除请求返回参数：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendResponseDel([item.SNOWID])
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendResponseDel(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWIDLIST:ids}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWIDLIST:ids})).toUpperCase())
        this.$http.post("Request/DeleteResponse", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.responseFormModal=false
                    this.getResponseList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChangeR(e){
        this.PageIndexR=e
        this.getResponseList()
    },
    submitResponseForm() {
        if (this.canDo) {
            this.$refs.rForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAddResponse()
                    }else if(this.doType=="2"){
                        this.sendEditResponse()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    responseSelectionChange(list){
        this.checkResponse=[]
        for(var i=0;i<list.length;i++){
            this.checkResponse.push(list[i].SNOWID)
        }
    },
    delResponses(){
        if(this.checkResponse.length==0){
            this.$message.error("请先选择需要删除的请求返回参数")
        }else{
            if(this.canDo){
                this.$confirm('确定删除选择的请求返回参数？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.sendResponseDel(this.checkResponse)
                }).catch(() => {
                this.$message.info("已取消")    
            });
            }
        }
    },

    
    //车辆定位类型
    carPost(){
        this.tokenstr=this.$store.state.token
        this.carLocationTypeModal=true
       
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
</style>