<template>
  <div class="map" :id="mapId" :style="{ width: width + 'px', height: height + 'px' }">
    <OLMOVE class="mapMove" ref="olmove" :map="map" :view="view" v-if="move"></OLMOVE>
    <OLDRAW class="mapDraw" ref="oldraw" :map="map" v-if="draw"></OLDRAW>
    <div class="mapType">
      <el-radio-group v-model="mapType" size="mini" @change="mapTypeChange">
        <el-radio-button label="矢量"></el-radio-button>
        <el-radio-button label="影像"></el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<style scoped>
.map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.mapMove {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
}

.mapDraw {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 100;
}

.mapType {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}

.checkItem {
  overflow: hidden;
  width: 100%;
}

.checkItem .txt {
  font-size: 18px;
  float: left;
}

.checkItem .infoBox {
  overflow: hidden;
  float: left;
  margin-top: 3px;
  margin-left: 20px;
}

.checkItem .infoBox .infoItem {
  color: #444444;
  float: left;
  margin-right: 20px;
  font-size: 16px;
}

.handleBox {
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
}

.handleBox .btnItem {
  float: left;
  margin-right: 20px;
  width: 120px !important;
}
</style>
<script>
import "ol/ol.css";

import Map from "ol/Map";
import View from "ol/View";
import Overlay from 'ol/Overlay';
import XYZ from "ol/source/XYZ";
import Projection from 'ol/proj/Projection';
import {OSM, Vector as VectorSource} from "ol/source";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {Fill, Stroke, Circle, Style} from "ol/style";
import {boundingExtent, getCenter} from 'ol/extent';
// import proj4 from 'proj4'
import {register} from 'ol/proj/proj4';
import OLMOVE from "../components/OpenLayersMove";
import OLDRAW from "../components/OpenLayersDraw";
import * as turf from '@turf/turf'

export default {
  components: {
    OLMOVE,
    OLDRAW,
  },
  name: "OLMAP",
  props: {
    task: {
      type: Boolean,
      default: false,
    },
    move: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 5,
    },
    center: {
      type: Array,
      default: function () {
        return [118.70303496700701, 32.15291659624196];
      },
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 450
    },
  },
  data() {
    return {
      mapId: "",
      mapType: "矢量",
      map: null,
      view: null,
      mapLayerA: null,
      mapLayerB: null,
      mapLayerC: null,
      mapLayerD: null,
      mapSourceA: null,
      mapSourceB: null,
      mapSourceC: null,
      mapSourceD: null,
    };
  },
  mounted() {
    var t = new Date().getTime()
    this.mapId = t.toString()
    //查看器
    this.loadItem = null
    proj4.defs(
        "EPSG:4490",
        "+proj=longlat +ellps=GRS80 +no_defs"
    )
    register(proj4);


    var Extent = [80, 20, 134, 50]
    var p = new Projection({
      code: "EPSG:4490",
      extent: Extent
    })
    this.view = new View({
      projection: p,
      center: this.center,
      maxZoom: 15,
      minZoom: 1,
      zoom: 10,
    }),
        this.view.addEventListener("change", function (e) {
          //console.log(e)
        });

    //console.log(this.view.getProjection())
    //地图层矢量
    this.mapSourceA = new XYZ({
      url: "https://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //地图层矢量标注
    this.mapSourceB = new XYZ({
      url: "https://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //地图影像
    this.mapSourceC = new XYZ({
      url: "https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //影像标注
    this.mapSourceD = new XYZ({
      url: "https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    // this.mapSource=new OSM()
    this.mapLayerA = new TileLayer({
      source: this.mapSourceA,
      zIndex: 2,
    })
    this.mapLayerA.set("name", "vec_c");
    this.mapLayerB = new TileLayer({
      source: this.mapSourceB,
      zIndex: 3,
    });
    this.mapLayerB.set("name", "cva_c");
    this.mapLayerC = new TileLayer({
      source: this.mapSourceC,
      zIndex: 2,
      visible: false,
    });
    this.mapLayerC.set("name", "img_c");
    this.mapLayerD = new TileLayer({
      source: this.mapSourceD,
      zIndex: 3,
      visible: false,
    });
    this.mapLayerD.set("name", "cia_c");
    //地图实例

    //console.log("地图加载完毕")
    //移除默认控件

    setTimeout(() => {
      this.map = new Map({
        //图层
        layers: [this.mapLayerA, this.mapLayerB, this.mapLayerC, this.mapLayerD],
        target: this.mapId,
        view: this.view,
      });
      var contros = this.map.getControls().array_;
      for (var i = 0; i < contros.length; i++) {
        this.map.removeControl(contros[i]);
      }
      this.$emit('mapFinish')
    }, 100);

    //地图点击事件
    // this.map.addEventListener('click', function(e){
    //   console.log(e)
    // });
  },
  methods: {


    //是否在多边形内
    flagPointInPolygon(polygon, point) {
      var pt = turf.point(point);
      var poly = turf.polygon(polygon)
      return turf.booleanPointInPolygon(pt, poly);
    },
    mapTypeChange(e) {
      if (e == "矢量") {
        this.mapLayerA.setVisible(true);
        this.mapLayerB.setVisible(true);
        this.mapLayerC.setVisible(false);
        this.mapLayerD.setVisible(false);
      } else if (e == "影像") {
        this.mapLayerA.setVisible(false);
        this.mapLayerB.setVisible(false);
        this.mapLayerC.setVisible(true);
        this.mapLayerD.setVisible(true);
      }
    },
    getMapTarget() {
      var o = {
        map: this.map,
        view: this.view,
        source: this.mapSource,
        layer: this.mapLayer,
      };
      return o;
    },
    //添加图形
    appendPolygon() {
    },
    afterAppend(fea) {
      console.log("添加完成")
      console.log(fea)
      var type = fea.getGeometry().getType()
      var fid = fea.getId()
      fea.set("tbtype", "draw")
      //移除绘制时创建的删除按钮
      var overlays = this.map.getOverlays().array_
      for (var i = 0; i < overlays.length; i++) {
        if (overlays[i].get("fid") == fid) {
          this.map.removeOverlay(overlays[i])
        }
      }

    },

    //获取 draw对象
    getDrawSource() {
      return this.$refs.oldraw.drawSource
    },
    appendDrawPoint(point, draw, callback) {
      this.$refs.oldraw.appendDrawPoint(point, draw, callback)
    },
    //通过ID获取要素
    getFeaById(id) {
      return this.$refs.oldraw.getFeaById(id)
    },
    //坐标转换
    coorTransform(coordinates, from, to) {
      return this.$refs.oldraw.coorTransform(coordinates, from, to)
    },
    //字符串解析，附坐标转换
    getCoordinateByStr(str, from, to) {
      return this.$refs.oldraw.getCoordinateByStr(str, from, to)
    },

    //绘制多边形 drawDatas=绘制时要素的自定义参数   callback 绘制完成回调 （返回要素对象）
    drawPolygon(drawDatas, callback) {
      this.$refs.oldraw.beginDraw("Polygon", false, drawDatas, callback);
    },
    //删除绘制  fid=要素ID  type=要素geometry 类型
    delDrawFeature(fid, type) {
      this.$refs.oldraw.doRrmoveDraw(fid, type);
    },
    //结束绘制
    endDraw() {
      this.$refs.oldraw.flagNowDraw();
    },
    //获取所有要素
    getAllDrawFeatures() {
      return this.$refs.oldraw.getAllDrawFeatures();
    },
    //手动添加要素  type=geometry 类型  id=要素ID  coors=坐标  showLabel=是否显示标注  callback=添加完成回调（返回要素对象）
    appendFeature(type, id, coors, showLabel, callback) {
      this.$refs.oldraw.appendFeature(type, id, coors, showLabel, callback);
    },
    //移动到某个位置 center=坐标
    moveTo(center, zoom) {
      //console.log("移动到："+center)
      this.view.setCenter(center)
      if (zoom && zoom != undefined) {
        this.view.setZoom(zoom)
      }
    },

    // //获取所有绘制的要素
    // getAllDrawFeatures(){
    //   return  this.$refs.oldraw.getAllDrawFeatures()
    // },

    //获取已选中的要素
    getCheckDrawFeatures() {
      return this.$refs.oldraw.getCheckDrawFeatures()
    },
    //删除已选择要素
    delCheckDrawFeatures() {
      this.$refs.oldraw.delCheckDrawFeatures()
    },
    //清空绘制
    clearDraw() {
      this.$refs.oldraw.clearDraw()
    },

    //openlayer坐标转WKT 字符串
    getWKTByTDPolygon(path) {
      var str = "("
      for (var i = 0; i < path.length; i++) {
        if (i == 0) {
          str += "(" + path[i][0] + " " + path[i][1]
        } else {
          str += "," + path[i][0] + " " + path[i][1]
        }
      }
      str += "))"
      return str
    },
    //WKT字符串转openlayer坐标
    getTDPathByWKTStr(str){
      var zb={isMulti:false,arr:[],isGroup:false,}
      if(str.indexOf("MULTIPOLYGON")!= -1){  //多个多边形
          zb.isMulti=true
          str = str.replace("MULTIPOLYGON","")
          str = str.replace("(((","")
          str = str.replace(")))","")
          var m_strs=str.split(")),((")
          var marr=[]
          for(var i=0;i<m_strs.length;i++){
              if(m_strs[i]!=""){
                var mstrArr= this.getTDItemPathByStr(m_strs[i])
                marr.push(mstrArr)
              }
          }
          zb.arr = marr
      }else if(str.indexOf("POLYGON")!= -1){  //单个多边形
          str = str.replace("POLYGON","")
          str = str.replace("((","")
          str = str.replace("))","")
          if(str.indexOf('),(')!=-1 || str.indexOf('), (')!=-1 ){
          	zb.isGroup=true
          	var m_strs=''
            if(str.indexOf('),(')!=-1){
              m_strs=str.split("),(")
            }else{
              m_strs=str.split("), (")
            }
           
	          var marr=[]
	          for(var i=0;i<m_strs.length;i++){
	              if(m_strs[i]!=""){
	                var mstrArr= this.getTDItemPathByStr(m_strs[i])
	                 marr.push(mstrArr)
	              }
	          }
	          zb.arr = marr
          }else{
          	zb.arr=this.getTDItemPathByStr(str)
          }
      }
      return zb
    },
    getTDItemPathByStr(str) {
      var arr = []
      var p_strs = str.split(",")
      for (var j = 0; j < p_strs.length; j++) {
        var pp = p_strs[j].trim().split(" ")
        arr.push([parseFloat(pp[0]), parseFloat(pp[1])])
      }
      return arr
    },


    //修改要素样式 feature=要素  fill=多边形填充色 stroke=边线颜色  circle=圆填充色
    setFeaStyle(feature, fill, stroke, circle) {
      this.$refs.oldraw.setFeaStyle(feature, fill, stroke, circle);
    },
    //openlayer多边形转 turf 多边形（坐标系转换）
    getTurfPolygon(coordinates) {
      return turf.polygon(coordinates)
      // return this.$refs.oldraw.getTurfPolygon(coordinates, epsg);
    },
    //判断是否包含
    isInclude(f1, f2) {
      return this.$refs.oldraw.isInclude(f1, f2);
    },
    //判断是否相交 （在内部和外部 false，有交集true)
    isOverlay(f1, f2) {
      return this.$refs.oldraw.isOverlay(f1, f2);
    },

    //获取多边形中心点
    getPolygonCenter(polygon) {
      return turf.centerOfMass(polygon);
    },
  },
  computed: {}
};
</script>

