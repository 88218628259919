<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">电子围栏设置</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iww"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="DESC" label="备注"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small" style="color:#F56C6C"
                                v-if="role.del">删除</el-button>
                            <!-- <el-button @click="baiduArea(scope.row)" type="text" size="small" style="color:#409EFF" v-if="role.edit">百度地图区域</el-button> -->
                            <el-button @click="tianArea(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="role.edit">区域</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-press-escape="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="DESC">
                    <el-input v-model="postInfo.DESC" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="postModal = false" class="fr ml20">取消</el-button>
                    <el-button type="primary" @click="submitPostForm()" class="fr ml20">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="百度地图区域" :visible.sync="baidumapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <BaiDuMap class="map" ref="baidumap" :width="mapWidth" :height="mapHeight" @mapFinish="baidumapFinish"
                @setPolygonCount="setBDPolygonCount"></BaiDuMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startBDDraw()" class="fl mr20">绘制区域</el-button>
                <el-button type="danger" @click="delBDDraw()" class="fl mr20">删除区域</el-button>
                <!-- <el-button type="danger" @click="clearDraw()" class="fl mr20">清空绘制</el-button> -->
                <el-button @click="baidumapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitBaiduForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="天地图区域" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-press-escape="false">
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"
                :move="false" :draw="true"></TianMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startTDraw()" class="fl">绘制区域</el-button>
                <el-button type="danger" @click="delTDraw()" class="fl mr20">删除区域</el-button>
                <el-input placeholder="请在此处粘贴坐标字符串" autocomplete="off" v-model="polygonStr" style="width:620px;"
                    class="fl mr20" @keyup.enter.native="setMapPolygon()"><i slot="suffix"
                        class="el-input__icon el-icon-finished" style="cursor: pointer;"
                        @click="setMapPolygon()"></i></el-input>
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitTianForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
    </div>
</template>
<script>
var pattern = /^[1-9][0-9]*$/
var h = document.body.clientHeight
import BaiDuMap from "../../components/BaiDuMap"
import TianMap from "../../components/OpenLayers"
export default {
    name: "ElecManage",
    components: {
        BaiDuMap,
        TianMap,
    },
    data() {
        var NAME = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var DESC = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入备注"));
            } else {
                callback();
            }
        };

        return {
            canDo: true,
            showPagination: false,
            name: "",


            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                NAME: [{ validator: NAME, trigger: "blur" }],
                DESC: [{ validator: DESC, trigger: "blur" }],
            },


            //电子围栏实体
            elecItem: null,
            mapWidth: 1060,
            mapHeight: 600,

            //百度地图
            baidumapModal: false,
            baidumap: null,
            BDPOLYGON: 0,


            //天地图
            tianmapModal: false,
            tianmap: null,
            TPOLYGON: 0,
            polygonStr: "",
            role: { add: false, edit: false, del: false }
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PageIndex
            this.name = getQueryData.NAME
        }
        this.showPagination = true
        this.loadItem = null
        this.getPostList()
        this.coolLeftHeight = h - 60
        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {

        baidumapFinish() {
            this.baidumap = this.$refs.baidumap.getMap()
        },
        tianmapFinish() {
            this.tianmap = this.$refs.tianmap.map
        },
        //获取项目列表
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                NAME: this.name,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("ElecRegion/GetElecRegionList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        //搜索项目
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },

        //点击添加项目
        addPost() {
            this.postInfo = {
                NAME: "",
                DESC: "",
                ZB: "",
                ZBBAIDU: "",
            }
            this.doTypeStr = "添加电子围栏"
            this.doType = "1"
            this.postModal = true
        },
        //执行添加项目
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("ElecRegion/InsertElecRegion", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        //点击编辑项目
        editPost(item) {
            this.postInfo = {
                ELECREGIONSNOWID: item.SNOWID,
                NAME: item.NAME,
                DESC: item.DESC,
                ZB: item.ZBSTR,
                ZBBAIDU: item.ZBSTRBAIDU,
            }
            this.doTypeStr = "编辑电子围栏"
            this.doType = "2"
            this.postModal = true


        },

        //执行编辑项目请求
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
            this.$http.post("ElecRegion/UpdateElecRegion", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.getPostList()
                        this.$message.success("编辑成功")
                        this.postModal = false
                        this.baidumapModal = false
                        this.tianmapModal = false
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //点击删除项目
        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除电子围栏：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已删除")
                });
            }

        },
        //执行删除项目请求
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt({ ELECREGIONSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ ELECREGIONSNOWID: id })).toUpperCase())
            this.$http.post("ElecRegion/DeleteElecRegion", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        //页码切换
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        //确定提交项目
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }

                    } else {
                        return false;
                    }
                });
            }
        },
        /**
         * 百度地图区域
         */
        baiduArea(item) {
            this.elecItem = item
            this.baidumapModal = true
            this.BDPOLYGON = 0
            if (this.baidumap) {
                this.$refs.baidumap.clearDraw()
            }
            if (item.ZBSTRBAIDU && item.ZBSTRBAIDU != "") {
                setTimeout(() => {
                    this.setProjectBDZB(item.ZBSTRBAIDU)
                }, 500)
            }
        },
        setProjectBDZB(zb) {
            var zbItem = this.$refs.baidumap.getBDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.baidumap.appendPolygon(zbItem.arr[i], oids)
                    if (i == 0) {
                        this.baidumap.panTo(zbItem.arr[i][0])
                    }
                }
            } else {
                var oid = new Date().getTime().toString()
                this.$refs.baidumap.appendPolygon(zbItem.arr, oid)
                this.baidumap.panTo(zbItem.arr[0])
            }
        },
        //开始绘制
        startBDDraw() {
            this.$refs.baidumap.openDraw()
        },
        setBDPolygonCount(num) {
            this.BDPOLYGON = num
        },
        //删除绘制
        delBDDraw() {
            var ps = this.$refs.baidumap.getCheckPolygons()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for (var i = 0; i < ps.length; i++) {
                        this.$refs.baidumap.removeDrawPolygon(ps[i])
                    }
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }
        },
        submitBaiduForm() {
            if (this.canDo) {
                if (this.BDPOLYGON > 0) {
                    this.postInfo = {
                        ELECREGIONSNOWID: this.elecItem.SNOWID,
                        NAME: this.elecItem.NAME,
                        DESC: this.elecItem.DESC,
                        ZB: this.elecItem.ZBSTR,
                        ZBBAIDU: this.getBDDrawPolygonsWKTStr(),
                    }
                    this.sendEdit()
                } else {
                    this.$message.error("请先绘制区域")
                }
            }
        },
        getBDDrawPolygonsWKTStr() {
            var list = this.$refs.baidumap.getDrawPolygons()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getPath()

                    var str = this.$refs.baidumap.getWKTByBDPolygon(path)
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },

        /**
         * 天地图
         */
        tianArea(item) {
            // tianmapModal:false,
            // tianmap:null,
            this.elecItem = item
            this.tianmapModal = true
            this.polygonStr = ""
            if (this.tianmap) {
                this.$refs.tianmap.clearDraw()
            }
            if (item.ZBSTR && item.ZBSTR != "") {
                setTimeout(() => {
                    this.setProjectTDZB(item.ZBSTR)
                }, 500)
            }
        },
        setProjectTDZB(zb) {
            var zbItem = this.$refs.tianmap.getTDPathByWKTStr(zb)
            if (zbItem.isMulti) {
                for (var i = 0; i < zbItem.arr.length; i++) {
                    var oids = new Date().getTime().toString() + i
                    this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                    if (i == 0) {
                        this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                    }
                }
            } else {
                var paths = []
                if (zbItem.isGroup) {
                    paths = zbItem.arr
                } else {
                    paths = [zbItem.arr]
                }
                var oid = new Date().getTime().toString()
                this.$refs.tianmap.appendFeature('Polygon', oid, paths, false, null)
                this.$refs.tianmap.moveTo(zbItem.arr[0])
            }
        },

        startTDraw() {
            this.$refs.tianmap.drawPolygon(null, null)
        },
        setMapPolygon() {
            var str = this.polygonStr
            if (str.indexOf("MULTIPOLYGON") != -1 || str.indexOf("POLYGON") != -1) {
                var zbItem = this.$refs.tianmap.getTDPathByWKTStr(this.polygonStr)
                if (zbItem.arr.length > 0) {
                    this.$confirm('本次操作会覆盖原先的坐标区域，确定继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.tianmap.clearDraw()
                        if (zbItem.isMulti) {
                            for (var i = 0; i < zbItem.arr.length; i++) {
                                var oids = new Date().getTime().toString() + i
                                this.$refs.tianmap.appendFeature('Polygon', oids, [zbItem.arr[i]], false, null)
                                if (i == 0) {
                                    this.$refs.tianmap.moveTo(zbItem.arr[i][zbItem.arr[i].length - 2])
                                }
                            }
                        } else {

                            var oid = new Date().getTime().toString()
                            this.$refs.tianmap.appendFeature('Polygon', oid, [zbItem.arr], false, null)
                            this.$refs.tianmap.moveTo(zbItem.arr[0])
                        }
                    })
                } else {
                    this.$message.error("请输入正确的坐标字符串")
                }
            } else {
                this.$message.error("请输入正确的坐标字符串")
            }
        },
        delTDraw() {

            var ps = this.$refs.tianmap.getCheckDrawFeatures()
            if (ps.length > 0) {
                this.$confirm('确定删除当前选中的区域？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs.tianmap.delCheckDrawFeatures()
                    this.$message.info("已删除")
                })
            } else {
                this.$message.error("请先选择一个区域")
            }


        },
        submitTianForm() {
            var feas = this.$refs.tianmap.getAllDrawFeatures()
            if (feas.length > 0) {
                this.postInfo = {
                    ELECREGIONSNOWID: this.elecItem.SNOWID,
                    NAME: this.elecItem.NAME,
                    DESC: this.elecItem.DESC,
                    ZB: this.getTDDrawPolygonsWKTStr(),
                    ZBBAIDU: this.elecItem.ZBSTRBAIDU,
                }
                this.sendEdit()
            } else {
                this.$message.error("请先绘制区域")
            }
        },
        getTDDrawPolygonsWKTStr() {
            var list = this.$refs.tianmap.getAllDrawFeatures()
            var pgStr = ""
            var isMutil = false
            if (list.length > 0) {
                if (list.length == 1) {
                    pgStr = "POLYGON"
                } else {
                    pgStr = "MULTIPOLYGON"
                    isMutil = true
                }

                for (var i = 0; i < list.length; i++) {
                    var path = list[i].getGeometry().getCoordinates()
                    var str = this.$refs.tianmap.getWKTByTDPolygon(path[0])
                    if (isMutil) {
                        if (i == 0) {
                            pgStr += "("
                        } else {
                            pgStr += ","
                        }
                        pgStr += str
                    } else {
                        pgStr += str
                    }
                }
                if (isMutil) {
                    pgStr += ")"
                }
            }
            return pgStr
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.nowMachineBox {
    width: 400px;
    height: 600px;
    position: relative;
    float: left;
    overflow: hidden;
    margin-left: 10px;
    background-color: #f4f4f5;
}

.nowMachineBox .mlTitle {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 12px;
}

.nowMachineBox .machineListBox {
    height: 520px;
    overflow-y: scroll;
}

.nowMachineBox .machineListNull p {
    color: #adadad;
    text-align: center;
}

.nowMachineBox .addMachineBox {
    height: 40px;
}

.nowMachineBox .addMachineBox .addBtn {
    width: 100%;
    border-radius: 0;
}

.machineListOut {
    overflow: hidden;
    padding: 20px 0px;
}

.machineListOut .machineItem {
    overflow: hidden;
    height: 40px;
    cursor: pointer;
    border-bottom: 1px solid #ADADAD;
}

.machineListOut .machineItem .machineItemImg {
    width: 30px;
    height: 30px;
    float: left;
    display: block;
    margin: 5px;
}

.machineListOut .machineItem .machineItemTxt {
    width: 150px;
    height: 40px;
    color: #666;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    float: left;
    margin-left: 10px;
}

.machineListOut .machineItem .removeIcon {
    float: left;
    color: #FF0036;
}

.el-tree {
    background: transparent;
}

.custom-tree-node {
    width: 100%;
}</style>