<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">表格导出</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="type" placeholder="表格类型"  filterable   multiple class="iwss">
                        <el-option v-for="item in types" :key="item.value" :label="item.lable" :value="item.value" ></el-option>
                </el-select>
                <el-select v-model="projectId" placeholder="选择项目"  filterable   multiple class="iwss">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="cxtime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  class="iwss"></el-date-picker>
                <el-button type="primary" @click="searchPost">导出</el-button>
            </div>
            <div class="views-main-body" style="display:none;">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <!-- <el-table-column prop="SYSUSERNAME" label="系统管理员"></el-table-column> -->
                    <!-- <el-table-column prop="BACKTIME" label="回滚时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISBACKED==1">{{scope.row.BACKTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="CREATETIME" label="创建日期"></el-table-column> -->
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PeroLogManage",
  data() {
    return {
        canDo:true,
        
        type:[],
        types:[
            {value:"1",lable:"预警信息",},
            // {value:"2",lable:"人员停留超时",},
            // {value:"3",lable:"车辆停留超时",},
            // {value:"4",lable:"车辆加油信息",},
            // {value:"5",lable:"车辆油耗情况",},
            //{value:"6",lable:"车辆运行信息",},
            //{value:"7",lable:"车辆油耗汇总",},
            //{value:"8",lable:"车辆运行汇总",},
            //{value:"9",lable:"车辆停留超时汇总",},
            //{value:"10",lable:"人员停留超时汇总",},
            // {value:"11",lable:"人员工作明细",},
            {value:"12",lable:"人员打卡明细",},
            // {value:"13",lable:"保洁点保洁明细",},
            // {value:"14",lable:"保洁点保洁汇总",},
            //{value:"15",lable:"人员工作时间未在线",},
            //{value:"16",lable:"人员工作时间未在线汇总",},
            {value:"17",lable:"人员信息",},
            {value:"18",lable:"车辆信息",},
            {value:"19",lable:"点位信息",},
            {value:"20",lable:"巡查计划信息",},
        ],

        cxtime:[],
        showPagination:false,
        projectId:"",
        projectList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        url:null,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        if(getQueryData.startDate!= "" && getQueryData.endDate !=""){
            this.cxtime=[new Date(getQueryData.startDate),new Date(getQueryData.endDate)]
        }else{
            this.cxtime=[]
        }
        this.projectId= getQueryData.projectId
        this.type=getQueryData.messageTypeList
    }
    this.showPagination=true
   this.getProject()
  },
  methods: {
    getProject(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Project/GetProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.projectList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.url =null
        this.showLoading("请稍后")
        var  sd=""
        var  ed=""
        if(this.cxtime.length===2){
            sd=this.comjs.getDateStrByDate(this.cxtime[0])
            ed=this.comjs.getDateStrByDate(this.cxtime[1])
        }else{
            sd=this.comjs.getDateStrByDate(new Date())
            ed=this.comjs.getDateStrByDate(new Date())
        }
       

        var params={
            projectId:this.projectId,
            messageTypeList:this.type,
            startDate:sd,
            endDate:ed,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$download.post("ExportExcel/ExportDataOneClick", md5_data,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
            this.hideLoading()
            if (resJson) {
                var namenew=this.getFileName()
                let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                if (window.navigator.msSaveOrOpenBlob) {
                    //兼容ie
                    if (this.form.name) {
                        window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                    } else {
                     window.navigator.msSaveBlob(blob, namenew+".zip")
                    }
                } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href

                    downloadElement.download = namenew+".zip"

                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)

                    document.body.removeChild(downloadElement)
                }

                this.$message.success('操作成功!')
            } else {
                this.$message.error("数据导出失败")
            }
            })
            .catch((error) => {
                this.hideLoading()
            });
    },
    getFileName(){
        
        var name=""
        var  sd=""
        var  ed=""
        var  dd=""
        if(this.cxtime.length===2){
            sd=this.comjs.getDateStrByDate(this.cxtime[0])
            ed=this.comjs.getDateStrByDate(this.cxtime[1])
        }else{
            sd=this.comjs.getDateStrByDate(new Date())
            ed=this.comjs.getDateStrByDate(new Date())
        }

        if(sd == ed){
            dd = sd.split("-")[1]+"-"+sd.split("-")[2]
        }else{
            dd=  sd.split("-")[1]+"-"+sd.split("-")[2]+"至"+ed.split("-")[1]+"-"+ed.split("-")[2] 
        }
        if(this.projectId.length==1){
            var pname=""
            for(var i=0;i<this.projectList.length;i++){
                if(this.projectList[i].SNOWID == this.projectId[0]){
                    pname = this.projectList[i].NAME
                    break;
                }
            }
            name = pname+"("+dd+")"
        }else{
            var nts=this.comjs.getDateStrByDate(new Date()).split(" ")
            console.log(nts)
            var rq=nts[0].split("-")[1]+"-"+nts[0].split("-")[2]
            var sj=nts[1].split(":")[1]+":"+nts[1].split(":")[2]
            name=dd+"("+rq+" "+sj+")"
        }
        return name
    },
    searchPost(){
        // else if(this.projectId.length==0){
        //     this.$message.error("请选择项目")
        // }
        // else if(this.cxtime.length!==2){
        //     this.$message.error("请选择日期范围")
        // }
        if(this.type.length==0){
            this.$message.error("请选择类型")
        }else{
            this.PageIndex=1
            this.getPostList()
        }
    },


    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>