<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">巡查计划管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">

                <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
                <el-select v-model="pid" placeholder="项目" filterable clearable class="iwu">
                    <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
                <el-button type="primary" @click="downloadTemplet" class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet" class="fr mr10" v-if="role.add">导入模板</el-button>

            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="STARTDATE" label="开始日期"></el-table-column>
                    <el-table-column prop="ENDDATE" label="结束日期"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目"></el-table-column>
                    <el-table-column prop="MANAGER" label="组长"></el-table-column>
                    <el-table-column prop="CYCLE" label="执行周期"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column prop="UPDATETIME" label="更新日期"></el-table-column>
                    <el-table-column label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="role.edit">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small" style="color:#F56C6C"
                                v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                    :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" top="30px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="项目" prop="PROJECTID">
                    <el-select v-model="postInfo.PROJECTID" placeholder="项目" filterable style="width:100%"
                        :disabled="doType == '2'" @change="cleanObjectChange">
                        <el-option v-for="item in projectList" :key="item.SNOWID" :label="item.NAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="组长" prop="MANAGERID">
                    <el-select v-model="postInfo.MANAGERID" placeholder="组长" filterable style="width:100%"
                        @change="cleanUserChange">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                            :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行周期" prop="CYCLE">
                    <el-input v-model="postInfo.CYCLE" autocomplete="off" placeholder="几天一次"></el-input>
                </el-form-item>
                <el-form-item label="巡查日期" prop="CLEANDATE">
                    <el-date-picker v-model="postInfo.CLEANDATE" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="关联巡查点" v-if="postInfo.PROJECTID != ''">
                    <div class="planBoxItem">
                        <div class="tit">
                            <span class="str">巡查点</span>
                            <span class="btn" @click="clearCleanSelects" v-if="doType == '1'">重置</span>
                        </div>
                        <div class="searchRow">
                            <el-select v-model="queryType" placeholder="巡查点类型" filterable
                                style="width:48.1%;margin-right: 10px;" size="mini">
                                <el-option v-for="item in cCleanType" :key="item.SNOWID" :label="item.NAME"
                                    :value="item.SNOWID" :disabled="item.disabled"></el-option>
                            </el-select>
                            <el-select v-model="extractType" placeholder="抽取方式" style="width:48.1%;" size="mini"
                                @change="extractTypeChange">
                                <el-option label="自选" value="0"></el-option>
                                <el-option label="按个数抽取" value="1"></el-option>
                                <el-option label="按比例抽取" value="2"></el-option>
                                <el-option label="按面积抽取" value="3"></el-option>
                            </el-select>
                            <el-input v-model="extractName" placeholder="根据名称模糊查询" style="width:78.5%;" size="mini"
                                v-if="extractType == '0'"></el-input>
                            <el-input v-model="extractRatio" placeholder="输入比例" style="width:78.5%;" size="mini"
                                v-if="extractType == '2'"></el-input>
                            <el-input v-model="extractCount" placeholder="输入个数" style="width:78.5%;" size="mini"
                                v-if="extractType == '1'"></el-input>
                            <el-input v-model="extractMinArea" placeholder="最小面积" style="width:38%;margin-right: 10px;"
                                size="mini" v-if="extractType == '3'"></el-input>
                            <el-input v-model="extractMaxArea" placeholder="最大面积" style="width:38%;" size="mini"
                                v-if="extractType == '3'"></el-input>
                            <el-button type="primary" @click="searchPlanCleanList" size="mini" class="fr"
                                style="margin-top:8px;" v-if="extractType == '0'">查询</el-button>
                            <el-button type="primary" @click="sextractPlanCleanList" size="mini" class="fr"
                                style="margin-top:8px;" v-else>抽取</el-button>
                        </div>
                        <div class="searchConResultBox" v-show="showSearchResult">
                            <div class="scsTitle">
                                <el-checkbox v-model="searchCheck" class="cb" @change="searchResultSelecked"></el-checkbox>
                                <span class="str"> 查询结果</span>
                            </div>
                            <div class="close" @click="hideSearchResult">×</div>
                            <div class="scsConBox">
                                <div class="cleanConNull" v-if="planCleanList.length == 0">未查到数据</div>
                                <div class="cleanRow" v-for="item in planCleanList" :key="item.id">
                                    <el-checkbox v-model="item.check" class="cb" @change="planCleanSelected">{{
                                        item.name }}</el-checkbox>
                                </div>
                            </div>
                            <el-button type="primary" @click="submitScsChecked" class="submitScsChecked">确定</el-button>
                        </div>
                        <div class="cleanCon">

                            <div class="cleanConNull" v-if="planCleans.length == 0">暂无数据，请在上方添加</div>
                            <div style="overflow: hidden;" v-else>
                                <div v-for="item in planCleans" :key="item.cleanTypeId" class="planUserRow"
                                    style="background-color: #F8F8F8;">
                                    <div class="planUserRowTitle" @click.stop="setPointTypeRowDown(item.cleanTypeId)">
                                        <el-checkbox v-model="item.check" class="cb"
                                            @change="pointTypeSelected(item.cleanTypeId)"></el-checkbox>
                                        <span>{{ item.cleanTypeName }}({{ item.cleanList.length }})</span>
                                        <i v-if="item.down" class="el-icon-arrow-down fr"></i>
                                        <i v-else class="el-icon-arrow-right fr"></i>

                                    </div>
                                    <div class="pointTypeRowMsg" v-show="item.down">
                                        <span class="str">来源：{{ item.extractTypeName }}</span>
                                        <span class="delPointTypeRow" @click="delPointTypeRow(item.cleanTypeId)"
                                            v-if="doType == '1' || doType == '2' && item.extractType == '0'">删除</span>
                                    </div>
                                    <div class="pointTypeRowMsg" v-show="item.down" style="position:relative;">
                                        <el-input v-model="item.searchName" autocomplete="off" size="mini"
                                            placeholder="输入名称检索巡查点" @input="searchNameInpit(item.cleanTypeId)"></el-input>
                                        <i class="el-icon-circle-close clearSearchName" v-show="item.searchName != ''"
                                            @click="clearSearchName(item.cleanTypeId)"></i>
                                    </div>
                                    <div class="userCleanList" v-show="item.down" style="background-color: #FFFFFF;">
                                        <div class="userCleanItemRow" v-for="clean in item.cleanList" :key="clean.id"
                                            style="height:34px;line-height:34px"
                                            v-show="clean.name.indexOf(item.searchName) != -1">
                                            <div class="name" style="overflow:hidden;max-width:100%;width:100%;">
                                                <el-checkbox v-model="clean.check" class="cb"
                                                    @change="pointTypeItemSelected(item.cleanTypeId, clean.id)"></el-checkbox>
                                                <span>{{ clean.name }}</span>
                                                <el-input v-model="clean.scope" placeholder="范围" size="mini"
                                                    style="width:60px;height:10px;float:right;"></el-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <el-button type="primary" @click="appendToUser" class="appendToUser">添加至人员</el-button>
                        <el-button type="warning" @click="appendToCar" class="appendToCar">添加至车辆</el-button>
                    </div>
                    <div class="planBoxItem">
                        <div class="tit">
                            <el-checkbox v-model="usersCheck" class="cb" @change="planUserAllSelected"></el-checkbox>
                            <span class="str">人员</span>
                            <span class="btn" @click="clearSelectUserCleans">清空</span>
                        </div>
                        <div class="userCon">
                            <div class="cleanConNull" v-if="planUser.length == 0">暂无人员</div>
                            <div style="overflow: hidden;" v-else>
                                <div v-for="item in planUser" :key="item.id" class="planUserRow">
                                    <div class="planUserRowTitle" @click.stop="setUserRowDown(item.id)">
                                        <el-checkbox v-model="item.check" class="cb" @change="planUserSelected">{{ item.name
                                        }}({{ item.cleans.length }})</el-checkbox>
                                        <i v-if="item.down" class="el-icon-arrow-down fr"></i>
                                        <i v-else class="el-icon-arrow-right fr"></i>
                                    </div>
                                    <div class="userCleanList" v-show="item.down">
                                        <div class="userCleanItemRow" v-for="clean in item.cleans" :key="clean.id">
                                            <div class="name">{{ clean.name }}</div>
                                            <div class="del" @click="delUserOneClean(item.id, clean.id)">删除</div>
                                        </div>
                                        <div v-if="item.cleans.length == 0" class="userNoClean">暂未绑定巡查点</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="planBoxItem" style="margin-right: 0;">
                        <div class="tit">
                            <el-checkbox v-model="carsCheck" class="cb" @change="planCarAllSelected"></el-checkbox>
                            <span class="str">车辆</span>
                            <span class="btn" @click="clearSelectCarCleans">清空</span>
                        </div>
                        <div class="userCon">
                            <div class="cleanConNull" v-if="planCars.length == 0">暂无车辆</div>
                            <div style="overflow: hidden;" v-else>
                                <div v-for="item in planCars" :key="item.id" class="planUserRow">
                                    <div class="planUserRowTitle" @click.stop="setCarRowDown(item.id)">
                                        <el-checkbox v-model="item.check" class="cb" @change="planCarSelected">{{ item.name
                                        }}({{ item.cleans.length }})</el-checkbox>
                                        <i v-if="item.down" class="el-icon-arrow-down fr"></i>
                                        <i v-else class="el-icon-arrow-right fr"></i>
                                    </div>
                                    <div class="userCleanList" v-show="item.down">
                                        <div class="userCleanItemRow" v-for="clean in item.cleans" :key="clean.id">
                                            <div class="name">{{ clean.name }}</div>
                                            <div class="del" @click="delCarOneClean(item.id, clean.id)">删除</div>
                                        </div>
                                        <div v-if="item.cleans.length == 0" class="userNoClean">暂未绑定巡查点</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">保存</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px" :append-to-body="true">
            <el-upload ref="upload" class="upload-demo" drag :multiple="false" :limit="1" accept=".xls,.xlsx" action=""
                :http-request="uploadFun">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "CLEANPLAN",
    data() {
        var NAME = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入名称"));
            } else {
                callback();
            }
        };
        var PROJECTID = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请选择项目"));
            } else {
                callback();
            }
        };
        var CLEANDATE = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error("请选择巡查日期"));
            } else {
                callback();
            }
        };
        var MANAGERID = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error("请选择组长"));
            } else {
                callback();
            }
        };
        var CYCLE = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入执行周期'));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('执行周期只能是数字'));
            } else if (!/^\+?(0|[1-9][0-9]*)$/.test(value)) {
                callback(new Error('只能输入大于等于0的整数'));
            } else {
                callback();
            }
        };
        var SCOPE = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入范围'));
            } else if (typeof value != 'number' && isNaN(value)) {
                callback(new Error('范围只能是数字'));
            } else if (!/^\+?(0|[1-9][0-9]*)$/.test(value)) {
                callback(new Error('只能输入大于等于0的整数'));
            } else {
                callback();
            }
        };
        return {
            canDo: true,

            name: "",
            pid: "",

            projectList: [],



            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,


            userList: [],

            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                NAME: [{ validator: NAME, trigger: "blur" }],
                CLEANDATE: [{ validator: CLEANDATE, trigger: "blur" }],
                PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
                MANAGERID: [{ validator: MANAGERID, trigger: "blur" }],
                CYCLE: [{ validator: CYCLE, trigger: "blur" }],
            },

            usersCheck: false,
            planUser: [],
            carsCheck: false,
            planCars: [],



            //保洁类型
            cCleanType: [],
            //查询类型
            queryType: "",
            //查询名称
            extractName: "",
            //抽取方式   0 自选 2 比例 1 个数 3面积区间
            extractType: "0",
            //比例
            extractRatio: "",
            //个数
            extractCount: "",
            //面积区间
            extractMinArea: "",
            extractMaxArea: "",
            //自选查询集合
            showSearchResult: false,
            planCleanList: [],
            searchCheck: false,
            /**
             * 已选择的巡查点
             *  {
             *     cleanTypeId:"",      巡查点类型
             *     cleanTypeName:"", 
             *     extractType:"",    0 自选 1 比例 2 个数 3面积区间
             *     extractTypeName:"", 抽取名称
             *     extractRatio:"",         比例
             *     extractCount:"",   个数
             *     extractMaxArea:"",        最小面积
             *     extractMaxArea:"",        最大面积
             *     cleanList:[{cleanListItem}],      巡查点集合
             *     check:false,  是否全选
             *     down:true,是否展开
             * }
             * 
             *  cleanListItem:{id:"",snowid:"",name:"",check:"", scope: 200,typeid:""}
             */
            planCleans: [],

            role: { add: false, edit: false, del: false },
            chooseFileModal: false,
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.PageIndex = getQueryData.PAGEINDEX
            this.name = getQueryData.NAME
            this.pid = getQueryData.PROJECTID
        }
        this.showPagination = true
        this.loadItem = null
        this.getPostList()
        this.getProjectList()
        this.getCleanType()
        this.role = this.setPageRoles(this.$route.path)
        setTimeout(() => {
            this.role = this.setPageRoles(this.$route.path)
        }, 3000)
    },
    methods: {
        getCleanType() {
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("CleanObjectType/GetList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        var nl = []
                        for (var i = 0; i < response.data.DATA.length; i++) {
                            var d = response.data.DATA[i]
                            d.disabled = false
                            nl.push(d)
                        }
                        this.cCleanType = nl
                    }
                })
        },
        getProjectList() {
            this.canDo = false
            var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Project/GetProjectList", md5_data)
                .then((response) => {
                    ////console.log(response)
                    this.hideLoading()
                    this.canDo = true
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].PARENTID == '0') {
                                this.projectList.push(list[i])
                            }
                        }
                    }
                })
        },

        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PAGEINDEX: this.PageIndex,
                PAGESIZE: this.PageSize,
                PROJECTID: this.pid,
                USERSNOWID: this.uid,
                CARSNOWID: this.cid,
                CLEANOBJECTSNOWID: this.coid,
                NAME: this.name,
            }
            this.setQueryData(this.$route.name, params)
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanPlan/GetCleanPlanList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        var nl = []
                        for (var i = 0; i < list.length; i++) {
                            var d = list[i]
                            // d.USTR = "/"
                            // d.CSTR = "/"
                            // d.COSTR = "/"

                            // for (var j = 0; j < d.USERLIST.length; j++) {
                            //     if (j == 0) {
                            //         d.USTR = d.USERLIST[j].DISPLAYNAME
                            //     } else {
                            //         d.USTR = d.USTR + "、" + d.USERLIST[j].DISPLAYNAME
                            //     }
                            // }
                            // for (var k = 0; k < d.CARLIST.length; k++) {
                            //     if (k == 0) {
                            //         d.CSTR = d.CARLIST[k].PLATENUMBER
                            //     } else {
                            //         d.CSTR = d.CSTR + "、" + d.CARLIST[k].PLATENUMBER
                            //     }
                            // }
                            // for(var g=0;g<d.CLEANOBJECTLIST.length;g++){
                            //     if(g==0){
                            //         d.COSTR=d.CLEANOBJECTLIST[g].NAME
                            //     }else{
                            //         d.COSTR=d.COSTR+"、"+d.CLEANOBJECTLIST[g].NAME
                            //     }
                            // }
                            nl.push(d)
                        }
                        this.postList = nl
                        //console.log(nl)
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },



        resetCleanChecks() {
            this.usersCheck = false
            this.carsCheck = false


            this.planUser = []
            this.planCars = []

            this.planCleans = []
            var list = this.cCleanType
            var nl = []
            for (var i = 0; i < list.length; i++) {
                let d = list[i]
                d.disabled = false
                nl.push(d)
            }
            this.cCleanType = nl
            this.queryType = ""
            this.extractType = "0"
            this.extractName = ""
            this.extractRatio = ""
            this.extractCount = ""
            this.extractMinArea = ""
            this.extractMaxArea = ""
            this.planCleanList = []
            this.showSearchResult = false
            this.searchCheck = false

        },
        //项目切换
        cleanObjectChange(e) {
            this.resetCleanChecks()
            this.getUserListByProject(e)
            this.getCarListByProject(e)

        },
        getUserListByProject(projectId) {
            var md5_data = this.comjs.requestDataEncrypt({ PROJECTID: projectId, ISQUIT: "0" }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ PROJECTID: projectId, ISQUIT: "0" })).toUpperCase())
            this.$http.post("User/GetUserList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.userList = response.data.DATA
                        var nl = []
                        for (var i = 0; i < this.userList.length; i++) {
                            var d = this.userList[i]
                            nl.push({
                                id: d.SNOWID,
                                name: d.DISPLAYNAME,
                                check: false,
                                cleans: [],
                                down: false,
                            })
                        }
                        this.planUser = nl
                    }
                })
        },
        cleanUserChange(e) {
            if (e == "") {
                this.postInfo.MANAGER = ""
            } else {
                for (var i = 0; i < this.userList.length; i++) {
                    if (this.userList[i].SNOWID == e) {
                        this.postInfo.MANAGER = this.userList[i].DISPLAYNAME
                        break;
                    }
                }
            }
        },


        getCarListByProject(projectId) {
            var md5_data = this.comjs.requestDataEncrypt({ PROJECTID: projectId }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ PROJECTID: projectId })).toUpperCase())
            this.$http.post("Car/GetCarList", md5_data)
                .then((response) => {
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        var nl = []
                        for (var i = 0; i < list.length; i++) {
                            var d = list[i]
                            nl.push({
                                id: d.SNOWID,
                                name: d.PLATENUMBER,
                                check: false,
                                cleans: [],
                                down: false,
                            })
                        }
                        this.planCars = nl
                    }
                })
        },


        /**
         * 巡查点
         */
        //通过类型获取名称
        getTypeNameByid(id) {
            var name = ""
            for (var i = 0; i < this.cCleanType.length; i++) {
                if (this.cCleanType[i].SNOWID == id) {
                    name = this.cCleanType[i].NAME
                    break;
                }
            }
            return name
        },
        setCleanTypeDisabled() {
            var cp = {}
            for (var i = 0; i < this.planCleans.length; i++) {
                cp[this.planCleans[i].cleanTypeId] = true
            }

            var cl = []
            for (var j = 0; j < this.cCleanType.length; j++) {
                var d = this.cCleanType[j]
                if (cp[d.SNOWID] != undefined) {
                    d.disabled = true
                } else {
                    d.disabled = false
                }
                cl.push(d)
            }
            this.cCleanType = cl
            this.queryType = ""
        },
        //修改抽取方式
        extractTypeChange() {
            this.planCleanList = []
            this.showSearchResult = false
            this.searchCheck = false
        },
        //判断该类型是否已抽取过
        flagTypeIsAppend(id) {
            var f = false
            for (var i = 0; i < this.planCleans.length; i++) {
                if (this.planCleans[i].cleanTypeId == id) {
                    f = true
                    break;
                }
            }
            return f
        },
        //设置是否能抽取
        setTypeDisabled(id, f) {
            var nl = []
            for (var i = 0; i < this.cCleanType.length; i++) {
                var d = this.cCleanType[i]
                if (d.SNOWID == id) {
                    d.disabled = f
                }
                nl.push(d)
            }
            this.cCleanType = nl
        },
        //查询
        searchPlanCleanList() {
            if (this.queryType == "") {
                this.$message.error("请先选择一个类型")
                return false
            }
            if (this.flagTypeIsAppend(this.queryType)) {
                this.$message.error("该类型已存在")
                return false
            }
            // if (this.queryType == "1656486945020317696") {
            //     this.$message.error("图斑只能抽取，不能查询")
            //     return false
            // }
            this.showLoading("请稍后")
            var params = {
                NAME: this.extractName,
                PROJECTID: this.postInfo.PROJECTID,
                CLEANOBJECTTYPEID: this.queryType,
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.showSearchResult = true
            this.$http.post("CleanObject/GetCleanObjectList", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            var d = list[i]
                            var f =this.flagItemHas(d.SNOWID,this.planCleanList)
                            if(!f){
                                this.planCleanList.push({
                                    id: d.SNOWID,
                                    snowid: "",
                                    name: d.NAME,
                                    check: false,
                                    scope: 200,
                                    typeid: this.queryType,
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                });
        },
        hideSearchResult() {
            this.planCleanList = []
            this.showSearchResult = false
            this.searchCheck = false
        },
        //重置抽取
        clearCleanSelects() {
            this.planCleans = []
            this.sendClearSelectUserCleans()
            this.sendClearSelectCarCleans()
        },
        //选择
        searchResultSelecked(e) {
            var nl = []
            for (var i = 0; i < this.planCleanList.length; i++) {
                var d = this.planCleanList[i]
                d.check = e
                nl.push(d)
            }
            this.planCleanList = nl
        },
        planCleanSelected() {
            var c = 0
            for (var i = 0; i < this.planCleanList.length; i++) {
                var d = this.planCleanList[i]
                if (d.check) {
                    c++
                }
            }
            if (c == this.planCleanList.length) {
                this.searchCheck = true
            } else {
                this.searchCheck = false
            }
        },
        //自选添加至巡查点集合
        submitScsChecked() {
            var c = []
            for (var i = 0; i < this.planCleanList.length; i++) {
                if (this.planCleanList[i].check) {
                    let pcd = this.planCleanList[i]
                    pcd.check = false
                    c.push(pcd)
                }
            }
            if (c.length == 0) {
                this.$message.error("请至少选择一项")
            } else {
                var d = {
                    cleanTypeId: c[0].typeid,
                    cleanTypeName: this.getTypeNameByid(c[0].typeid),
                    extractType: "0",
                    extractTypeName: "自选",
                    extractRatio: "",
                    extractCount: "",
                    extractMinArea: "",
                    extractMaxArea: "",
                    cleanList: c,
                    check: false,
                    down: true,
                    searchName: '',
                }
                this.planCleans.push(d)
                this.hideSearchResult()
                this.setCleanTypeDisabled()
            }
        },

        //抽取
        sextractPlanCleanList() {

            if (this.queryType == "") {
                this.$message.error("请先选择一个类型")
                return false
            }
            if (this.flagTypeIsAppend(this.queryType)) {
                this.$message.error("该类型已存在")
                return false
            }
            if (!this.flagSextractNum()) {
                this.$message.error("抽取参数输入有误")
                return false
            }
            this.showLoading("请稍后")
            var params = {
                RandomType: parseInt(this.extractType),
                ProjectIDList: [this.postInfo.PROJECTID],
                CleanObjectTypeIDList: [this.queryType],
            }
            if (params.RandomType == 1) {
                params.iCount = parseInt(this.extractCount)
            } else if (params.RandomType == 2) {
                params.iPercent = parseFloat(this.extractRatio)
            } else {
                params.MinArea = parseFloat(this.extractMinArea)
                params.MaxArea = parseFloat(this.extractMaxArea)
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanObject/GetCleanObjectListRandom", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        if (list.length > 0) {
                            var c = []
                            for (var i = 0; i < list.length; i++) {
                                var d = list[i]
                                c.push({
                                    id: d.SNOWID,
                                    snowid: "",
                                    name: d.NAME,
                                    check: false,
                                    scope: 200,
                                    typeid: d.CLEANOBJECTTYPEID,
                                })
                            }
                            var dd = {
                                cleanTypeId: c[0].typeid,
                                cleanTypeName: this.getTypeNameByid(c[0].typeid),
                                extractType: this.extractType,
                                extractTypeName: "",
                                extractRatio: 0,
                                extractCount: 0,
                                extractMinArea: 0,
                                extractMaxArea: 0,
                                cleanList: c,
                                check: false,
                                down: true,
                                searchName: '',
                            }
                            if (this.extractType == '1') {
                                dd.extractTypeName = '抽取个数（' + parseInt(this.extractCount) + '）'
                                dd.extractCount = parseInt(this.extractCount)
                            } else if (this.extractType == '2') {
                                dd.extractTypeName = '抽取比例（' + parseFloat(this.extractRatio) + '%）'
                                dd.extractRatio = parseFloat(this.extractRatio)
                            } else if (this.extractType == '3') {
                                dd.extractTypeName = '抽取面积（' + parseFloat(this.extractMinArea) + '~' + parseFloat(this.extractMaxArea) + '）'
                                dd.extractMinArea = parseFloat(this.extractMinArea)
                                dd.extractMaxArea = parseFloat(this.extractMaxArea)
                            }
                            this.planCleans.push(dd)
                            this.setCleanTypeDisabled()
                        } else {
                            this.$message.error('查无数据')
                        }
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                });
        },
        flagSextractNum() {
            var f = false
            var numS = /^[1-9]\d*$/
            var munZ = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
            if (this.extractType == "2") {
                if (munZ.test(this.extractRatio) && parseFloat(this.extractRatio) <= 100) {
                    f = true
                }
            } else if (this.extractType == "1") {
                if (numS.test(this.extractCount)) {
                    f = true
                }
            } else if (this.extractType == "3") {
                if (munZ.test(this.extractMinArea) && munZ.test(this.extractMaxArea) && parseFloat(this.extractMinArea) <= parseFloat(this.extractMaxArea)) {
                    f = true
                }
            }
            return f
        },
        //已选择类型收起和展开
        setPointTypeRowDown(id) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var d = this.planCleans[i]
                if (d.cleanTypeId == id) {
                    d.down = !d.down
                }
                nl.push(d)
            }
            this.planCleans = nl
        },
        delPointTypeRow(id) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var d = this.planCleans[i]
                if (d.cleanTypeId != id) {
                    nl.push(d)
                } else {
                    this.afterDelPointTypeRow(d.cleanList)
                }
            }
            this.planCleans = nl
            this.setCleanTypeDisabled()
        },
        clearSearchName(id) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var d = this.planCleans[i]
                if (d.cleanTypeId == id) {
                    d.searchName = ''
                    d.check = false
                }
                nl.push(d)
            }
            this.planCleans = nl
            this.pointTypeSelected(id)
        },
        searchNameInpit(typeid) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var d = this.planCleans[i]
                if (d.cleanTypeId == typeid) {
                    d.check = false
                }
                nl.push(d)
            }
            this.planCleans = nl
            this.pointTypeSelected(typeid)
        },
        afterDelPointTypeRow(list) {
            var ul = []
            for (let i = 0; i < this.planUser.length; i++) {
                let uitem = this.planUser[i]
                let nuc = []
                for (let j = 0; j < uitem.cleans.length; j++) {
                    let ucitem = uitem.cleans[j]
                    let uhas = this.flagUserAndCarBind(ucitem.id, list)
                    if (!uhas) {
                        nuc.push(ucitem)
                    }
                }
                uitem.cleans = nuc
                ul.push(uitem)
            }
            this.planUser = ul

            var cl = []
            for (let k = 0; k < this.planCars.length; k++) {
                let citem = this.planCars[k]
                let ncc = []
                for (let m = 0; m < citem.cleans.length; m++) {
                    let ccitem = citem.cleans[m]
                    let chas = this.flagUserAndCarBind(ccitem.id, list)
                    if (!chas) {
                        ncc.push(ccitem)
                    }
                }
                citem.cleans = ncc
                cl.push(citem)
            }
            this.planCars = cl
        },
        flagUserAndCarBind(id, list) {
            var f = false
            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    f = true
                    break;
                }
            }
            return f
        },
        pointTypeSelected(typeid) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var pitem = this.planCleans[i]
                if (pitem.cleanTypeId == typeid) {
                    var ppoints = pitem.cleanList
                    var pps = []
                    for (var j = 0; j < ppoints.length; j++) {
                        var citem = ppoints[j]
                        if (pitem.searchName == '') {
                            citem.check = pitem.check
                        } else {
                            if (citem.name.indexOf(pitem.searchName) != -1) {
                                citem.check = pitem.check
                            } else {
                                citem.check = false
                            }
                        }

                        pps.push(citem)
                    }
                    pitem.cleanList = pps
                }
                nl.push(pitem)
            }
            this.planCleans = nl
            console.log(this.planCleans)
        },
        pointTypeItemSelected(typeid, cleanid) {
            var nl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                var pitem = this.planCleans[i]
                if (pitem.cleanTypeId == typeid) {
                    var ppoints = pitem.cleanList
                    var ppointsLength = ppoints.length
                    if (pitem.searchName != '') {
                        ppointsLength = 0
                        for (var k = 0; k < ppoints.length; k++) {
                            if (ppoints[k].name.indexOf(pitem.searchName) != -1) {
                                ppointsLength++
                            }
                        }
                    }
                    var checkNum = 0
                    for (var j = 0; j < ppoints.length; j++) {
                        var citem = ppoints[j]
                        if (citem.check) {
                            checkNum++
                        }
                    }
                    if (checkNum == ppointsLength) {
                        pitem.check = true
                    } else {
                        pitem.check = false
                    }
                }
                nl.push(pitem)
            }
            this.planCleans = nl
        },

        flagScopes() {
            var f = true
            var numS = /^[1-9]\d*$/
            for (var i = 0; i < this.planCleans.length; i++) {
                if (f) {
                    for (var j = 0; j < this.planCleans[i].cleanList.length; j++) {
                        var pjjs = this.planCleans[i].cleanList[j].scope
                        if (!numS.test(pjjs)) {
                            f = false
                            break;
                        }
                    }
                } else {
                    break;
                }

            }
            return f
        },
        //添加到人员
        appendToUser() {
            var sc = []
            for (var i = 0; i < this.planCleans.length; i++) {
                for (var j = 0; j < this.planCleans[i].cleanList.length; j++) {
                    var pjj = this.planCleans[i].cleanList[j]
                    if (pjj.check) {
                        sc.push(pjj)
                    }
                }
            }
            if (sc.length > 0) {
                var uc = []
                for (var k = 0; k < this.planUser.length; k++) {
                    if (this.planUser[k].check) {
                        uc.push(this.planUser[k])
                    }
                }
                if (uc.length > 0) {
                    this.setAppendToUser(sc, uc)
                } else {
                    this.$message.error("请选择要添加的人员")
                }

            } else {
                this.$message.error("请选择要添加的巡查点")
            }
        },
        setAppendToUser(cleans, users) {
            var nl = []
            for (var i = 0; i < this.planUser.length; i++) {
                var d = this.planUser[i]
                if (this.flagItemHas(d.id, users)) {
                    for (var j = 0; j < cleans.length; j++) {
                        var cj = cleans[j]
                        if (!this.flagItemHas(cj.id, d.cleans)) {
                            d.cleans.push(cj)
                        }
                    }
                }

                nl.push(d)
            }
            this.planUser = nl
        },
        //添加到车辆
        appendToCar() {
            var sc = []
            for (var i = 0; i < this.planCleanList.length; i++) {
                if (this.planCleanList[i].check) {
                    sc.push(this.planCleanList[i])
                }
            }
            if (sc.length > 0) {
                var uc = []
                for (var j = 0; j < this.planCars.length; j++) {
                    if (this.planCars[j].check) {
                        uc.push(this.planCars[j])
                    }
                }
                if (uc.length > 0) {
                    this.setAppendToCar(sc, uc)
                } else {
                    this.$message.error("请选择要添加的人员")
                }

            } else {
                this.$message.error("请选择要添加的巡查点")
            }
        },
        setAppendToCar(cleans, cars) {
            var nl = []
            for (var i = 0; i < this.planCars.length; i++) {
                var d = this.planCars[i]
                if (this.flagItemHas(d.id, cars)) {
                    for (var j = 0; j < cleans.length; j++) {
                        var cj = cleans[j]
                        if (!this.flagItemHas(cj.id, d.cleans)) {
                            d.cleans.push(cj)
                        }
                    }
                }
                nl.push(d)
            }
            this.planCars = nl

            this.setPlanCleansList()
        },

        flagItemHas(id, list) {
            var flag = false
            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    flag = true
                    break;
                }
            }
            return flag
        },
        scopeChange(value, id) {
            if (value == 0 || value == "") {
                var nl = []
                for (var i = 0; i < this.planCleans.length; i++) {
                    let d = this.planCleans[i]
                    if (d.id == id) {
                        d.scope = 200
                    }
                    nl.push(d)
                }
                this.planCleans = nl
            }
        },

        /**
         * 人员
         */
        planUserAllSelected(e) {
            var nl = []
            for (var i = 0; i < this.planUser.length; i++) {
                var d = this.planUser[i]
                d.check = e
                nl.push(d)
            }
            this.planUser = nl
        },
        planUserSelected() {
            var c = 0
            for (var i = 0; i < this.planUser.length; i++) {
                var d = this.planUser[i]
                if (d.check) {
                    c++
                }
            }
            if (c == this.planUser.length) {
                this.usersCheck = true
            } else {
                this.usersCheck = false
            }
        },
        //人员下拉显示影藏
        setUserRowDown(id) {
            var nl = []
            for (var i = 0; i < this.planUser.length; i++) {
                var d = this.planUser[i]
                if (d.id == id) {
                    d.down = !d.down
                }
                nl.push(d)
            }
            this.planUser = nl
        },

        //移除人员中的某个巡查点
        delUserOneClean(userid, cleanid) {
            var nl = []
            for (var i = 0; i < this.planUser.length; i++) {
                let d = this.planUser[i]
                if (d.id == userid) {
                    let cl = []
                    for (let j = 0; j < d.cleans.length; j++) {
                        if (d.cleans[j].id != cleanid) {
                            cl.push(d.cleans[j])
                        }
                    }
                    d.cleans = cl
                }
                nl.push(d)
            }
            this.planUser = nl
        },
        //清空
        clearSelectUserCleans() {
            var c = 0
            for (var i = 0; i < this.planUser.length; i++) {
                if (this.planUser[i].check) {
                    c++
                }
            }
            if (c > 0) {
                this.$confirm('确定将已选中人员的巡查点清空？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendClearSelectUserCleans()
                }).catch(() => {
                    //this.$message.info("已取消")
                });
            } else {
                this.$message.error("请先选择要清空的人员")
            }
        },
        sendClearSelectUserCleans() {
            var nl = []
            for (var i = 0; i < this.planUser.length; i++) {
                var d = this.planUser[i]
                if (d.check) {
                    d.cleans = []
                }
                nl.push(d)
            }
            this.planUser = nl
        },
        /**
         * 车辆
         */
        planCarAllSelected(e) {
            var nl = []
            for (var i = 0; i < this.planCars.length; i++) {
                var d = this.planCars[i]
                d.check = e
                nl.push(d)
            }
            this.planCars = nl
        },
        planCarSelected() {
            var c = 0
            for (var i = 0; i < this.planCars.length; i++) {
                var d = this.planCars[i]
                if (d.check) {
                    c++
                }
            }
            if (c == this.planCars.length) {
                this.carsCheck = true
            } else {
                this.carsCheck = false
            }
        },
        //人员下拉显示影藏
        setCarRowDown(id) {
            var nl = []
            for (var i = 0; i < this.carUser.length; i++) {
                var d = this.carUser[i]
                if (d.id == id) {
                    d.down = !d.down
                }
                nl.push(d)
            }
            this.carUser = nl
        },

        //移除人员中的某个巡查点
        delCarOneClean(carid, cleanid) {
            var nl = []
            for (var i = 0; i < this.carUser.length; i++) {
                let d = this.carUser[i]
                if (d.id == carid) {
                    let cl = []
                    for (let j = 0; j < d.cleans.length; j++) {
                        if (d.cleans[j].id != cleanid) {
                            cl.push(d.cleans[j])
                        }
                    }
                    d.cleans = cl
                }
                nl.push(d)
            }
            this.carUser = nl
        },
        //清空
        clearSelectCarCleans() {
            var c = 0
            for (var i = 0; i < this.planCars.length; i++) {
                if (this.planCars[i].check) {
                    c++
                }
            }
            if (c > 0) {
                this.$confirm('确定将已选中车辆的巡查点清空？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendClearSelectCarCleans()
                }).catch(() => {
                    //this.$message.info("已取消")
                });
            } else {
                this.$message.error("请先选择要清空的车辆")
            }
        },
        sendClearSelectCarCleans() {
            var nl = []
            for (var i = 0; i < this.planCars.length; i++) {
                var d = this.planCars[i]
                if (d.check) {
                    d.cleans = []
                }
                nl.push(d)
            }
            this.planCars = nl
        },


        addPost() {
            this.postInfo = {
                NAME: "",
                PROJECTID: "",
                CLEANDATE: [],
                MANAGER: "",
                MANAGERID: "",
                CYCLE: "",
                CLEANPLANCLEANOBJECTLIST: [],
            }
            this.resetCleanChecks()
            this.doTypeStr = "添加巡查计划"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd(params) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanPlan/InsertCleanPlan", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.updataUserAndCarBind(response.data.DATA.SNOWID)
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },
        editPost(item) {
            this.showLoading('请稍后')
            this.postInfo = {
                CLEANPLANSNOWID: item.SNOWID,
                NAME: item.NAME,
                PROJECTID: item.PROJECTID,
                CLEANDATE: [new Date(item.STARTDATE), new Date(item.ENDDATE)],
                MANAGER: item.MANAGER,
                MANAGERID: item.MANAGERID,
                CYCLE: item.CYCLE,
                CLEANPLANCLEANOBJECTLIST: [],
            }
            this.cleanObjectChange(item.PROJECTID)
            var that = this
            setTimeout(function () {
                that.getCleanArrayList(item)
            }, 1000)

        },
        getCleanArrayList(item) {
            var params = {
                CLEANPLANSNOWID: item.SNOWID
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanPlan/GetCleanPlanListDetail", md5_data)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS && response.data.DATA.length > 0) {
                        var dd = response.data.DATA[0]
                        item.CARLIST = dd.CARLIST
                        item.CLEANOBJECTLIST = dd.CLEANOBJECTLIST
                        item.CLEANPLANCARLIST = dd.CLEANPLANCARLIST
                        item.CLEANPLANCLEANOBJECTLIST = dd.CLEANPLANCLEANOBJECTLIST
                        item.CLEANPLANUSERLIST = dd.CLEANPLANUSERLIST
                        item.USERLIST = dd.USERLIST
                        this.initCleanChecks(item)
                        this.doTypeStr = "修改巡查计划"
                        this.doType = "2"
                        this.postModal = true
                    }else{
                        this.hideLoading()
                        this.$message.error("计划查询失败")
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.$message.error("计划查询失败")
                });

        },
        initCleanChecks(item) {
            var cList = item.CLEANOBJECTLIST
            var sList = item.CLEANPLANCLEANOBJECTLIST
            //巡查对象范围
            var pcl = []
            var pco = {}
            for (var i = 0; i < sList.length; i++) {
                let iitem = this.getCleanItemById(sList[i].CLEANOBJECTID, cList)
                if (iitem) {
                    //console.log(iitem)
                    if (pco[iitem.CLEANOBJECTTYPEID] == undefined) {
                        var rt = '0'
                        if (sList[i].RANDOMTYPE) {
                            rt = sList[i].RANDOMTYPE.toString()
                        } else {
                            sList[i].RANDOMTYPE = 0
                        }
                        pco[iitem.CLEANOBJECTTYPEID] = {
                            cleanTypeId: iitem.CLEANOBJECTTYPEID,
                            cleanTypeName: iitem.CLEANOBJECTTYPE.NAME,
                            extractType: rt,
                            extractTypeName: "",
                            extractRatio: 0,
                            extractCount: 0,
                            extractMinArea: 0,
                            extractMaxArea: 0,
                            cleanList: [],
                            check: false,
                            down: false,
                            searchName: '',
                        }
                        if (sList[i].RANDOMTYPE == 0) {
                            pco[iitem.CLEANOBJECTTYPEID].extractTypeName = '自选'
                        } else if (sList[i].RANDOMTYPE == 1) {
                            pco[iitem.CLEANOBJECTTYPEID].extractTypeName = '抽取个数（' + sList[i].POINTCOUNT + '）'
                            pco[iitem.CLEANOBJECTTYPEID].extractCount = sList[i].POINTCOUNT
                        } else if (sList[i].RANDOMTYPE == 2) {
                            pco[iitem.CLEANOBJECTTYPEID].extractTypeName = '抽取比例（' + sList[i].PERCENT + '%）'
                            pco[iitem.CLEANOBJECTTYPEID].extractRatio = sList[i].PERCENT
                        } else if (sList[i].RANDOMTYPE == 3) {
                            pco[iitem.CLEANOBJECTTYPEID].extractTypeName = '抽取面积（' + sList[i].MINAREA + '~' + sList[i].MAXAREA + '）'
                            pco[iitem.CLEANOBJECTTYPEID].extractMinArea = sList[i].MINAREA
                            pco[iitem.CLEANOBJECTTYPEID].extractMaxArea = sList[i].MAXAREA
                        }
                    }

                    pco[iitem.CLEANOBJECTTYPEID].cleanList.push({
                        id: iitem.SNOWID,
                        snowid: sList[i].SNOWID,
                        name: iitem.NAME,
                        check: false,
                        scope: sList[i].SCOPE,
                        typeid: iitem.CLEANOBJECTTYPEID
                    })
                }
            }
            for (var pocItem in pco) {
                pcl.push(pco[pocItem])
            }

            this.planCleans = pcl
            this.setCleanTypeDisabled()
            //人员
            var ucl = {}
            for (var j = 0; j < item.CLEANPLANUSERLIST.length; j++) {
                if (ucl[item.CLEANPLANUSERLIST[j].USERID] == undefined) {
                    ucl[item.CLEANPLANUSERLIST[j].USERID] = []
                }
                let uitem = this.getCleanItemById(item.CLEANPLANUSERLIST[j].CLEANOBJECTID, cList)
                if (uitem) {
                    ucl[item.CLEANPLANUSERLIST[j].USERID].push({
                        id: uitem.SNOWID,
                        name: uitem.NAME,
                        check: false,
                        scope: 200,
                    })
                }
            }

            var nucl = []
            for (var k = 0; k < this.planUser.length; k++) {
                let nuclk = this.planUser[k]
                if (ucl[nuclk.id] != undefined) {
                    nuclk.cleans = ucl[nuclk.id]
                }
                nucl.push(nuclk)
            }
            this.planUser = nucl
            //车辆
            var ccl = {}
            for (var m = 0; m < item.CLEANPLANCARLIST.length; m++) {
                if (ccl[item.CLEANPLANCARLIST[m].USERID] == undefined) {
                    ccl[item.CLEANPLANCARLIST[m].USERID] = []
                }
                let uitem = this.getCleanItemById(item.CLEANPLANCARLIST[m].CLEANOBJECTID, cList)
                if (uitem) {
                    ccl[item.CLEANPLANCARLIST[m].USERID].push({
                        id: uitem.SNOWID,
                        name: uitem.NAME,
                        check: false,
                        //scope: 200,
                    })
                }
            }

            var nccl = []
            for (var n = 0; n < this.planCars.length; n++) {
                let ncclk = this.planCars[n]
                if (ccl[ncclk.id] != undefined) {
                    ncclk.cleans = ccl[ncclk.id]
                }
                nccl.push(ncclk)
            }
            this.planCars = nccl

            this.hideLoading()
        },

        getCleanItemById(id, list) {
            var item = null
            for (var i = 0; i < list.length; i++) {
                if (list[i].SNOWID == id) {
                    item = list[i]
                    break;
                }
            }
            return item
        },

        getScopeById(list, id) {
            var scope = 0
            for (var i = 0; i < list.length; i++) {
                if (list[i].CLEANOBJECTID == id) {
                    scope = list[i].SCOPE
                    break;
                }
            }
            return scope
        },
        sendEdit(params) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanPlan/UpdateCleanPlan", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.updataUserAndCarBind(params.CLEANPLANSNOWID)

                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },

        updataUserAndCarBind(id) {
            var ul = []
            for (var i = 0; i < this.planUser.length; i++) {
                for (var j = 0; j < this.planUser[i].cleans.length; j++) {
                    let cj = this.planUser[i].cleans[j]
                    ul.push({
                        CLEANOBJECTID: cj.id,
                        USERID: this.planUser[i].id
                    })
                }
            }
            var cl = []
            for (var k = 0; k < this.planCars.length; k++) {
                for (var m = 0; m < this.planCars[k].cleans.length; m++) {
                    let cm = this.planCars[k].cleans[m]
                    cl.push({
                        CLEANOBJECTID: cm.id,
                        CARID: this.planCars[k].id
                    })
                }
            }

            var params = {
                CLEANPLANSNOWID: id,
                CLEANPLANUSERLIST: ul,
                CLEANPLANCARLIST: cl
            }
            var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("CleanPlan/UpdateCleanPlanUserOrCar", md5_data)
                .then((response) => {
                    this.postModal = false
                    this.getPostList();
                })


        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        this.setSubmitDatas()
                    } else {
                        return false;
                    }
                });

            }
        },

        setSubmitDatas() {
            if (this.planCleans.length == 0) {
                this.$message.error("请先添加巡查点")
                return false
            }
            if (!this.flagScopes()) {
                this.$message.error("范围输入有误")
                return false
            }
            var sd = this.comjs.getDateMinStrByDate(this.postInfo.CLEANDATE[0])
            var ed = this.comjs.getDateMinStrByDate(this.postInfo.CLEANDATE[1])

            var param = {
                NAME: this.postInfo.NAME,
                PROJECTID: this.postInfo.PROJECTID,
                CYCLE: this.postInfo.CYCLE,
                MANAGERID: this.postInfo.MANAGERID,
                MANAGER: this.postInfo.MANAGER,
                STARTDATE: sd,
                ENDDATE: ed,
                CLEANPLANCLEANOBJECTLIST: [],
            }
            var cl = []
            for (var i = 0; i < this.planCleans.length; i++) {
                let di = this.planCleans[i]
                let dd = this.planCleans[i].cleanList
                var ISRANDOM = 0
                if (di.extractType != "0") {
                    ISRANDOM = 1
                }
                for (var j = 0; j < dd.length; j++) {
                    var d = dd[j]
                    var dp = {
                        CLEANOBJECTID: d.id,
                        SCOPE: d.scope.toString(),
                        ISRANDOM: ISRANDOM,
                        RANDOMTYPE: parseInt(di.extractType),
                        POINTCOUNT: 0,
                        PERCENT: 0,
                        MINAREA: 0,
                        MAXAREA: 0,
                    }

                    if (di.extractType == "1") {
                        dp.POINTCOUNT = parseInt(di.extractCount)
                    } else if (di.extractType == "2") {
                        dp.PERCENT = parseFloat(di.extractRatio)
                    } else if (di.extractType == "3") {
                        dp.MINAREA = parseFloat(di.extractMinArea)
                        dp.MAXAREA = parseFloat(di.extractMaxArea)
                    }
                    cl.push(dp)
                }
                param.CLEANPLANCLEANOBJECTLIST = cl

            }
            if (this.doType == "1") {
                this.sendAdd(param)
            } else if (this.doType == "2") {
                param.CLEANPLANSNOWID = this.postInfo.CLEANPLANSNOWID
                this.sendEdit(param)
            }
        },



        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除巡查计划：' + item.NAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            var md5_data = this.comjs.requestDataEncrypt({ CLEANPLANSNOWID: id }, this.$store.state.token, this.$md5(this.comjs.sortAsc({ CLEANPLANSNOWID: id })).toUpperCase())
            this.$http.post("CleanPlan/DeleteCleanPlan", md5_data)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA, response.data.MSG, true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                });
        },


        downloadTemplet() {
            window.open("file/巡查计划模板.xlsx")
        },
        uploadTemplet() {
            this.chooseFileModal = true
        },
        uploadFun(f) {
            this.showLoading("请稍后...")
            var fd = new FormData();
            fd.append("file", f.file);
            this.$upload.post("CleanPlan/UploadExcel?token=" + this.$store.state.token, fd)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.hideLoading()

                        this.chooseFileModal = false
                        this.PageIndex = 1
                        this.getPostList()
                        if (response.data.DATA && response.data.DATA != "") {
                            this.$confirm('该文件有错误内容, 是否下载查看?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                window.open(response.data.DATA)
                            })
                        } else {
                            this.$message.success("导入完成")
                        }
                    } else {
                        this.hideLoading()
                        this.$message.error("上传失败")
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                });
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.planBoxItem {
    width: 32%;
    height: 450px;
    border-radius: 3px;
    background-color: #F8F8F8;
    float: left;
    margin-right: 2%;
    position: relative;
}

.planBoxItem .tit {
    height: 40px;
    overflow: hidden;
    padding: 0 12px;
}

.planBoxItem .tit .cb {
    float: left;
}

.planBoxItem .tit .str {
    float: left;
    color: #409EFF;
    font-size: 16px;
    font-weight: bold;
}

.planBoxItem .tit .btn {
    float: right;
    font-size: 14px;
    cursor: pointer;
    color: #F56C6C;
}

.searchRow {
    padding: 0 12px;
    height: 80px;
}

.cleanCon {
    position: absolute;
    top: 130px;
    left: 12px;
    right: 12px;
    bottom: 60px;
    background-color: #FFFFFF;
    border-radius: 3px;
    padding: 8px 10px;
    overflow: auto;
}

.saveCleanCon {
    top: 50px !important
}

.cleanConNull {
    text-align: center;
    font-size: 16px;
    color: #ADADAD;
    margin-top: 40%;
}

.cleanRow {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.appendToUser {
    width: 45%;
    position: absolute;
    left: 12px;
    bottom: 10px;
}

.appendToCar {
    width: 45%;
    position: absolute;
    right: 12px;
    bottom: 10px;
}

.savePlanPoints {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 10px;
}

.userCon {
    position: absolute;
    top: 50px;
    left: 12px;
    right: 12px;
    bottom: 10px;
    overflow: auto;
}

.planUserRow {
    background-color: #FFFFFF;
    overflow: hidden;
    margin-top: 10px
}

.planUserRowTitle {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    padding: 0 12px;
    cursor: pointer
}

.fr {
    margin-top: 8px
}

.userCleanList {
    margin: 6px 8px;
    padding: 6px 8px;
    overflow: hidden;
    background-color: #F8F8F8;
}

.userCleanList .userCleanItemRow {
    height: 24px;
    line-height: 24px;
    overflow: hidden;
}

.userCleanList .userCleanItemRow .name {
    float: left;
    max-width: 82%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
}

.userCleanList .userCleanItemRow .del {
    color: #F56C6C;
    float: right;
    cursor: pointer;
}

.userNoClean {
    color: #ADADAD;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

.cleanScopeBox {
    overflow: hidden;
    border-radius: 3px;
    padding: 20px;
    background-color: #F8F8F8;
}

.cleanScopeBox .cleanScope {
    margin-bottom: 12px;
    margin-right: 12px;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 3px;
    float: left;
    padding: 0px 12px;
    overflow: hidden;
}

.cleanScopeBox .cleanScope .str {
    float: left;
    font-size: 14px;
}

.cleanScopeBox .cleanScope .ipt {
    float: left;
    width: 80px;
}

.searchConResultBox {
    position: absolute;
    left: 12px;
    right: 12px;
    top: 130px;
    bottom: 10px;
    overflow: hidden;
    background-color: #FFFFFF;
    z-index: +66;
}

.searchConResultBox .scsTitle {
    height: 40px;
    overflow: hidden;
    margin: 0 10px;
}

.searchConResultBox .scsTitle .cb {
    float: left;
}

.searchConResultBox .scsTitle .str {
    float: left;
    color: #409EFF;
    font-size: 16px;
    font-weight: bold;
}

.searchConResultBox .close {
    position: absolute;
    right: 6px;
    top: -5px;
    cursor: pointer;
    color: #ADADAD;
    font-size: 30px;
}

.scsConBox {
    position: absolute;
    top: 40px;
    left: 12px;
    right: 12px;
    bottom: 60px;
    background-color: #F8F8F8;
    border-radius: 3px;
    padding: 8px 10px;
    overflow: auto;
}

.submitScsChecked {
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 10px;
    overflow: hidden;
}

.delPointTypeRow {
    color: #F56C6C;
    float: right;
    cursor: pointer;
}

.pointTypeRowMsg {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    font-size: 12px;
    color: #555555;
    padding: 0 12px;
}

.clearSearchName {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: 12px;
    cursor: pointer;
    color: #ADADAD;
}</style>