import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import qs from 'qs';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5';
import comjs from '../public/statics/js/common.js'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)
Vue.config.productionTip = false


//上传 ajax
const upload = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 1000 * 6 * 20,
  headers: {
    'Content-Type': 'multipart/form-data' ,
  },
})

Vue.prototype.$upload = upload;

//下载
const download = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  responseType: 'blob',
})

Vue.prototype.$download = download;

//普通ajax
const $axios = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 5000000,
});
$axios.interceptors.request.use(
  function (config) {
    if(store.state.token && store.state.token!=""){
      config.headers['Token'] = store.state.token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
const $newAxios = axios.create({
    baseURL: comjs.baseUrl,
    timeout: 120000,
});
$newAxios.interceptors.request.use(
    function (config) {
        if(store.state.token && store.state.token!=""){
            config.headers['Token'] = store.state.token
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

Vue.prototype.$http = $axios;
Vue.prototype.$newHttp = $newAxios;


//组件
Vue.prototype.$qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.comjs = comjs;
Vue.use(ElementUI);
Vue.use(VueCookies)
VueCookies.config('30d')

//注销
var pcLogout= ()=>{
  store.commit('setToken', "");  
  VueCookies.remove("hb_setToken")
  //console.log()
  if(router.currentRoute.fullPath!="/"){
    router.replace("/")
  }
}
Vue.prototype.logout = pcLogout

//判断权限

var setPageRoles=(link)=>{
  var role={add:false,edit:false,del:false}
  var list =store.state.roleList
  //console.log(list)
  var end=false
  for(var i=0;i<list.length;i++){
    if(!end){
      var navs=list[i].navs
      for(var j=0;j<navs.length;j++){
         if(navs[j].link == link){
            for(var k=0;k<navs[j].role.length;k++){
               var rd=navs[j].role[k]
               //console.log(rd)
               if(rd.NAME =='新增'){
                role.add=true
               }
               if(rd.NAME=='修改'){
                role.edit=true
               }
               if(rd.NAME=='删除'){
                role.del=true
               }
            }
            end=true
            break;
         }
      }
    }
    
  }
  return role
}
Vue.prototype.setPageRoles = setPageRoles

//接口异常处理
var errorRequest=(code,msg,show)=>{
    if(code){
      code=code.toString()
      var  c=code.substring(0, 1);
      if(c=="1"){
        ElementUI.Message.error(msg+",即将回到登录界面")
        setTimeout(function(){
          pcLogout()
        },2000)
      }else{
        if(show){
          ElementUI.Message.error(msg)
        }
      }
    }else{
      if(msg!=""){
        ElementUI.Message.error(msg)
      }
    }
    
}
Vue.prototype.errorRequest = errorRequest

//等待框
var loadItem=null
var showLoading=function(txt){
  loadItem = this.$loading({
    lock: true,
    text: txt,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
}
var hideLoading=function(){
  loadItem.close()
}

Vue.prototype.loadItem = loadItem
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading


var queryData={}
var getQueryData=function(routername){
  return queryData[routername]
}
var setQueryData=function(routername,params){
  queryData[routername]=params
}
Vue.prototype.getQueryData = getQueryData
Vue.prototype.setQueryData = setQueryData


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
