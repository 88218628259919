<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">点位类型</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="danger" @click="delMore" class="fr" v-if="role.del"  v-show="false">批量删除</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="role.add">添加</el-button>
               
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="HAVEWORKICONURL" label="已巡查图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.HAVEWORKICONURL" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="NOWORKICONURL" label="未巡查图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.NOWORKICONURL" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="SCREENICONURL" label="图例显示图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.SCREENICONURL" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="SCREENICONURLB" label="图例影藏图标">
                        <template slot-scope="scope">
                            <el-image style="width: 30px; height: 30px" :src="scope.row.SCREENICONURLB" fit="fill"></el-image>  
                        </template>
                    </el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="INSTAYTIME" label="巡查停留有效时间（分钟）"></el-table-column>
                    
                    <el-table-column prop="ISHAVEZB" label="是否包含区域">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISHAVEZB==1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="role.edit">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="role.del">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1000px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="巡查停留有效时间（分钟）" prop="INSTAYTIME">
                    <el-input v-model="postInfo.INSTAYTIME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否包含区域">
                    <el-radio v-model="postInfo.ISHAVEZB" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISHAVEZB" label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="已巡查图标" prop="ICONA">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunA"
                            :before-upload="beforeAvatarUploadA">
                            <img v-if="imageUrlA" :src="imageUrlA" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item label="未巡查图标" prop="ICONB">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunB"
                            :before-upload="beforeAvatarUploadB">
                            <img v-if="imageUrlB" :src="imageUrlB" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item label="图例显示图标">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunC"
                            :before-upload="beforeAvatarUploadC">
                            <img v-if="imageUrlC" :src="imageUrlC" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item label="图例隐藏图标">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunD"
                            :before-upload="beforeAvatarUploadD">
                            <img v-if="imageUrlD" :src="imageUrlD" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CT",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("字段不能为空"));
      } else {
        callback();
      }
    };
    var ICONA = (rule, value, callback) => {
        if (value == 0) {
            callback(new Error("请选择已巡查图标"));
        } else {
            callback();
        }
      };
      var ICONB = (rule, value, callback) => {
        if (value == 0) {
            callback(new Error("请选择未巡查图标"));
        } else {
            callback();
        }
      }

      var INSTAYTIME=(rule, value, callback) => {
        if(value != '' && typeof value != 'number' && isNaN(value)){
             callback(new Error('字段只能是数字'));
        }else if(value != '' && !/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        showPagination:false,
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            INSTAYTIME: [{ validator: INSTAYTIME, trigger: "blur" }],
            ICONA: [{ validator: ICONA, trigger: "blur" }],
            ICONB: [{ validator: ICONB, trigger: "blur" }],
        },

        imageUrlA:"",
        fileItemA:null,
        imageUrlB:"",
        fileItemB:null,
        imageUrlC:"",
        fileItemC:null,
        imageUrlD:"",
        fileItemD:null,

        doData:null,
        allIds:[],
        role:{add:false,edit:false,del:false}
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.PageIndex= getQueryData.PageIndex
        this.name= getQueryData.NAME
    }
    this.showPagination=true
    this.loadItem=null
    this.getPostList()
    this.role=this.setPageRoles(this.$route.path)
    setTimeout(()=>{
        this.role=this.setPageRoles(this.$route.path)
    },3000)
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            NAME:this.name,
        }
        this.setQueryData(this.$route.name,params)
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CleanObjectType/GetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            ISHAVEZB:"1",
            ICONA:0,
            ICONB:0,
            ICONC:0,
            ICOND:0,
            HAVEWORKICONURL:"",
            NOWORKICONURL:"",
            SCREENICONURL:"",
            SCREENICONURLB:"",
            INSTAYTIME:"",
        }
        this.imageUrlA=""
        this.fileItemA=null
        this.imageUrlB=""
        this.fileItemB=null
        this.imageUrlC=""
        this.fileItemC=null
        this.imageUrlD=""
        this.fileItemD=null

        this.doTypeStr="添加巡查类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CleanObjectType/Insert", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.doData=response.data.DATA
                    this.addImgA()
                    this.addImgB()
                    if(this.fileItemC){
                        this.addImgC()
                    }
                    this.$message.success("已添加")
                } else {
                    this.hideLoading()
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var sc=""
        if(item.SCREENICONURL && item.SCREENICONURL!=""){
            sc=item.SCREENICONURL
        }

        var scb=""
        if(item.SCREENICONURLB && item.SCREENICONURLB!=""){
            scb=item.SCREENICONURLB
        }
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            HAVEWORKICONURL:item.HAVEWORKICONURL,
            NOWORKICONURL:item.NOWORKICONURL,
            SCREENICONURL:sc,
            SCREENICONURLB:scb,
            ISHAVEZB:item.ISHAVEZB.toString(),
            INSTAYTIME:item.INSTAYTIME,
        }
        this.imageUrlA=item.HAVEWORKICONURL
        this.fileItemA=null
        this.imageUrlB=item.NOWORKICONURL
        this.fileItemB=null
        this.imageUrlC=sc
        this.fileItemC=null
        this.imageUrlD=scb
        this.fileItemD=null

        this.doTypeStr="修改巡查类型"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("CleanObjectType/Update", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData=this.postInfo
                    if(this.fileItemA || this.fileItemB || this.fileItemC || this.fileItemD){
                        if(this.fileItemA){
                            this.addImgA()
                        }
                        if(this.fileItemB){
                            this.addImgB()
                        }
                        if(this.fileItemC){
                            this.addImgC()
                        }
                        if(this.fileItemD){
                            this.addImgD()
                        }
                    }else{
                        this.postModal=false
                        this.getPostList()
                    }
                    
                    this.$message.success("已修改")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CleanObjectType/Delete", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    delete this.postInfo.ICONA
                    delete this.postInfo.ICONB
                    delete this.postInfo.ICONC
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    beforeAvatarUploadA(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlA = _URL.createObjectURL(file);
            this.postInfo.ICONA=1
            this.fileItemA=file
        }
        return isJPG && isLt2M;
    },
    uploadFunA(file){
        //console.log(file)
    },
    beforeAvatarUploadB(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlB = _URL.createObjectURL(file);
            this.postInfo.ICONB=1
            this.fileItemB=file
        }
        return isJPG && isLt2M;
    },
    uploadFunB(file){
        //console.log(file)
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlC = _URL.createObjectURL(file);
            this.postInfo.ICONC=1
            this.fileItemC=file
        }
        return isJPG && isLt2M;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlD = _URL.createObjectURL(file);
            this.postInfo.ICOND=1
            this.fileItemD=file
        }
        return isJPG && isLt2M;
    },
    uploadFunD(file){
        //console.log(file)
    },


    addImgA(){
        var fd = new FormData();
        fd.append("file", this.fileItemA);
        var fname=this.fileItemA.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData.HAVEWORKICONURL=response.data.DATA
                    if(this.doData.HAVEWORKICONURL && this.doData.HAVEWORKICONURL!="" && this.doData.NOWORKICONURL && this.doData.NOWORKICONURL!=""){
                        this.updateImgUrl()
                    }
                    
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    addImgB(){
        var fd = new FormData();
        fd.append("file", this.fileItemB);
        var fname=this.fileItemB.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData.NOWORKICONURL= response.data.DATA
                    if(this.doData.HAVEWORKICONURL && this.doData.HAVEWORKICONURL!="" && this.doData.NOWORKICONURL && this.doData.NOWORKICONURL!=""){
                        this.updateImgUrl()
                    }
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    addImgC(){
        var fd = new FormData();
        fd.append("file", this.fileItemC);
        var fname=this.fileItemC.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData.SCREENICONURL=response.data.DATA
                    if(this.doData.HAVEWORKICONURL && this.doData.HAVEWORKICONURL!="" && this.doData.NOWORKICONURL && this.doData.NOWORKICONURL!=""){
                        this.updateImgUrl()
                    }
                    
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    addImgD(){
        var fd = new FormData();
        fd.append("file", this.fileItemD);
        var fname=this.fileItemD.name
        this.$upload.post("Image/UploadImage?NAME="+fname, fd)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.doData.SCREENICONURLB=response.data.DATA
                    if(this.doData.HAVEWORKICONURL && this.doData.HAVEWORKICONURL!="" && this.doData.NOWORKICONURL && this.doData.NOWORKICONURL!=""){
                        this.updateImgUrl()
                    }
                    
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    updateImgUrl(){
        var item=this.doData
        var params={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            ISHAVEZB:item.ISHAVEZB.toString(),
            HAVEWORKICONURL:item.HAVEWORKICONURL,
            NOWORKICONURL:item.NOWORKICONURL,
            SCREENICONURL:item.SCREENICONURL,
            SCREENICONURLB:item.SCREENICONURLB,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CleanObjectType/Update", md5_data)
        .then((response) => {
            //console.log(response)
            this.hideLoading()
            this.canDo=true
            if(response.data.SUCCESS){   
                this.postModal=false
                this.getPostList()
            }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
        })
        .catch((error) => {
            this.$message.error("请求出错")
            this.canDo=true
        });
    },
    handleSelectionChange(e){
        this.allIds =e
    },
    delMore(){
        if(this.allIds.length==0){
            this.$message.error("请至少选择一项")
        }else{
            this.$confirm('确定要删除选中的内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var ids=[]
                for(var i=0;i<this.allIds.length;i++){
                    ids.push(this.allIds[i].SNOWID)
                }
                this.delMoreFun(ids)
            }) 
        }
    },
    delMoreFun(ids){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>