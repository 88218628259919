<template>
  <div id="app" class="appMain">
    <div class="appHead">
      <div class="logoBox" @click="toBigScreen">
        <!-- <img src="statics/img/logo.png" alt=""> -->
      </div>
      <div class="hisNavBox">
        <div class="hisNavBoxIn">
          <div class="hisNavItme" :class="nowCheckName == item ? 'hisNavItmeOn' : ''" v-for="item in historyList" :key="item"
            @click.stop="showNav(item)">
            <p class="text">{{ routerNameStr(item) }}</p>
            <p v-show="historyList.length > 1" class="close" @click.stop="hideNav(item)">×</p>
          </div>

        </div>
      </div>
      <div class="loginInfo">
        <img :src="headImg" class="headImg">
        <div class="ddBox">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="changeUserModal = true">修改资料</el-dropdown-item> -->
              <el-dropdown-item @click.native="downloadsjgz">数据规则下载</el-dropdown-item>
              <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="appLeft">
      <el-menu :default-active="defActive" background-color="#545c64" text-color="#fff" :collapse="isCollapse" router
        active-text-color="#ffd04b" v-show="sysType == '1'">
        <!-- <el-submenu index="Home1">
                <template slot="title">
                  <i class="el-icon-s-custom"></i>
                  <span>角色权限</span>
                </template>
                <el-menu-item index="/AdminManage">系统用户</el-menu-item>    
                <el-menu-item index="/ProjectManage">项目管理</el-menu-item>    
                <el-menu-item index="/MenuManage">菜单管理</el-menu-item>    
                <el-menu-item index="/RoleManage">角色管理</el-menu-item>                         
            </el-submenu>
            <el-submenu index="Home654">
                <template slot="title">
                  <i class="el-icon-s-custom"></i>
                  <span>项目督查</span>
                </template>
                <el-menu-item index="/ProblemType">问题类型</el-menu-item>    
                <el-menu-item index="/ProblemList">问题列表</el-menu-item>  
            </el-submenu> -->
        <el-submenu :index="item.link != '' ? item.link : item.id" v-for="item in menuList" :key="item.id">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item :index="citem.link" v-for="citem in item.navs" :key="citem.id">{{ citem.name }}</el-menu-item>
        </el-submenu>
      </el-menu>
      <el-menu :default-active="defActive" background-color="#545c64" text-color="#fff" :collapse="isCollapse" router
        active-text-color="#ffd04b" v-show="sysType == '2'">
        <el-submenu index="Home91">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span>管理员管理</span>
          </template>
          <el-menu-item index="/AdminUserManage">管理员列表</el-menu-item>
        </el-submenu>
        <el-submenu index="Home92">
          <template slot="title">
            <i class="el-icon-user-solid"></i>
            <span>租户管理</span>
          </template>
          <el-menu-item index="/AdminCompanyManage">租户列表</el-menu-item>
          <el-menu-item index="/AdminCountManage">租户统计</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!-- v-for="item in historyList" :key="item" v-show="nowCheckName == item" -->
    <div class="appRight" v-for="item in historyList" :key="item" v-show="nowCheckName == item">
      <router-view :name="item"></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="changePwdModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      sysType: "",

      isCollapse: false,
      loadpage: false,
      defActive: "",
      userName: "",
      headImg: "statics/img/headImg.png",


      logoutModal: false,
      changePwdModal: false,
      changePwd: {
        oldPass: '',
        newPass: '',
        checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      canDo: true,

      menuList: [],


      historyList: [],
      nowCheckName: "",
      routerNameStr: function (name) {
        var str = ""
        for (var i = 0; i < this.menuList.length; i++) {
          if (str == "") {
            for (var j = 0; j < this.menuList[i].navs.length; j++) {
              var link = this.menuList[i].navs[j].link
              if (link.indexOf(name) != -1) {
                str = this.menuList[i].navs[j].name
                break;
              }
            }
          } else {
            break;
          }
        }
        return str
      }

    };
  },
  mounted() {
    this.getPageHeadName()
    this.sysType = this.$store.state.sysType
    this.loadItem = null
    if (this.sysType == "1") {
      this.getRoleData()
    } else {
      this.isLoginPage(this.$route.name)
    }

  },
  methods: {
    getPageHeadName() {
      var cid = this.$cookies.get("hb_companyId")
      if (cid != '') {
        var params = {
          COMPANYSNOWID: cid,
        }
        var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MainDb/GetCompanyInfo", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              var name = response.data.DATA.NAME
              this.setPageHeadName(name)
            }
          })
      }else{
        this.setPageHeadName('')
      }
    },
    setPageHeadName(name){
      if(name && name!=""){
        document.title = name+'巡逻鹰';
        document.getElementsByTagName("title")[0].innerText = name+'巡逻鹰';
      }
    },
    getRoleData() {
      this.showLoading("请稍后")
      var params = {
        PAGEINDEX: 1,
        PAGESIZE: 10,
        SYSUSERSNOWID: this.$store.state.snowid,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Role/GetRoleList", md5_data)
        .then((response) => {
          this.hideLoading()
          if (response.data.SUCCESS) {
            var d = response.data.DATA
            if (d.length > 0) {
              var mIndex=0
              for(var i=1;i<d.length;i++){
                  if(d[i].MENULIST.length>d[mIndex].MENULIST.length){
                    mIndex=i
                  }
              }
              this.setRoleMenu(d[mIndex].MENULIST)
            }
          }
        })
    },
    setRoleMenu(list) {
      var pnavs = {}
      var cnavs = []
      var menus = []
      for (var i = 0; i < list.length; i++) {
        var d = list[i]
        if (d.ISVALID == 1) {
          if (d.PARENTID == "0") {
            pnavs[d.SNOWID] = {
              id: d.SNOWID,
              name: d.NAME,
              icon: d.IMAGEURL,
              navs: [],
              link: d.LINKURL,
            }
          } else {
            cnavs.push(d)
          }
        }
      }
      for (var j = 0; j < cnavs.length; j++) {
        var dc = cnavs[j]
        if (pnavs[dc.PARENTID] != undefined) {
          pnavs[dc.PARENTID].navs.push({
            id: dc.SNOWID,
            name: dc.NAME,
            link: dc.LINKURL,
            icon: dc.IMAGEURL,
            role: dc.SYSOPERLIST,
          })
        }
      }
      for (var item in pnavs) {
        if (pnavs[item].navs.length > 0) {
          menus.push(pnavs[item])
        }
      }

      this.menuList = menus
      console.log(menus)
      this.$store.commit('setRoleList', menus);
      this.isLoginPage(this.$route.name)
    },
    isLoginPage(name) {
      this.userName = this.$store.state.name
      // if(name =="CheckTempletItem" || name =="CheckTemplet" || name =="CheckTaskManage" || name =="TaskAllot"){
      //    this.defActive='/XJGL'
      // }else if(name =="SCanteen" || name =="CLGL" || name =="JYBXJL" || name =="YZSQSP"){
      //    this.defActive='/HQAQGL'
      // }else if(name =="KHGL"||name =="KHX"||name =="KHXM"||name =="SCJL"||name =="GZZJJGZJH"|| name =="KHLB"|| name =="YZFA"){
      //    this.defActive='/XTPTZH'
      // }else if(name =="TDJH"){
      //    this.defActive='/DDTD'
      // }else{
      //   this.defActive='/'+name
      // }
      this.defActive = '/' + name
      this.setHistoryRouters(name)
      this.loadpage = true
    },
    //退出登录
    logoutSend() {
      this.logoutModal = false
      this.logout()
    },
    //修改密码
    submitPwdForm(formName) {
      if (this.canDo) {
        this.$refs.cpForm.validate((valid) => {
          if (valid) {
            if (this.sysType == "1") {
              this.sendChangePwdA()
            } else {
              this.sendChangePwdB()
            }

          } else {
            return false;
          }
        });
      }
    },
    sendChangePwdA() {
      var params = {
        SYSUSERSNOWID: this.$store.state.snowid,
        PASSWORD: this.changePwd.oldPass,
      }
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("SysUser/ResetPassword", md5_data)
        .then((response) => {
          this.canDo = true
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.changePwdModal = false
            this.$message.success("已修改")
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo = true
        });
    },
    sendChangePwdB() {
      var params = {
        USERSNOWID: this.$store.state.snowid,
        OLDPASSWORD: this.changePwd.oldPass,
        NEWPASSWORD: this.changePwd.newPass,

      }
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("MainDb/EditPassword", md5_data)
        .then((response) => {
          this.canDo = true
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.changePwdModal = false
            this.$message.success("已修改")
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo = true
        });
    },
    downloadsjgz() {
      window.open("file/数据规则.docx")
    },
    toBigScreen() {
      var url = window.location.host;
      window.localStorage.setItem("hq_token", this.$store.state.token);
      window.localStorage.setItem("hq_endtime", this.$store.state.endTime);
      window.localStorage.setItem("hq_snowid", this.$store.state.guid);
      window.localStorage.setItem("hq_loginname", this.$store.state.loginName);
      window.localStorage.setItem("hq_turename", this.$store.state.name);
      window.localStorage.setItem("hq_companyId", this.$cookies.get("hb_companyId"));
      window.open("http://" + url + "/statics/bigScreen/login.html?cid="+this.$cookies.get("hb_companyId"))
    },
    setHistoryRouters(name) {
      this.nowCheckName = name
      if (this.historyList.length == 0) {
        this.historyList.push(name)
      } else {
        var has = false
        for (var i = 0; i < this.historyList.length; i++) {
          if (this.historyList[i] === name) {
            has = true
            break;
          }
        }
        if (!has) {
          this.historyList.push(name)
        }
      }
    },
    showNav(name) {
      // console.log(name)
      // console.log(this.nowCheckName)
      // console.log(this.defActive)
      // this.nowCheckName = name
      // this.defActive="/"+name
      //nowCheckName
      //defActive
      if (this.nowCheckName != name) {
        this.$router.replace("/" + name);
      }
    },
    hideNav(name) {
      if (this.historyList.length > 1) {
        var nl = []
        var next = ""
        for (var i = 0; i < this.historyList.length; i++) {
          if (this.historyList[i] != name) {
            nl.push(this.historyList[i])
          } else {
            if (i > 0) {
              next = this.historyList[i - 1]
            } else {
              next = this.historyList[i + 1]
            }
          }
        }
        this.historyList = nl
        if (this.nowCheckName == name) {
          this.$router.replace("/" + next);
        }
      }
    },
  },
  computed: {

  },
  watch: {
    '$route.name': function (newVal, oldVal) {
      this.setHistoryRouters(newVal)
      this.isLoginPage(newVal)
    }
  },
};
</script>
 
<style>
.appMain {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.appHead {
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: #FFFFFF;
}

.appLeft {
  width: 240px;
  position: absolute;
  left: 0px;
  top: 56px;
  bottom: 0px;
  background-color: #545c64;
}

.appLeft .el-menu::-webkit-scrollbar {
  width: 0 !important
}

.appRight {
  position: absolute;
  left: 240px;
  bottom: 0px;
  right: 0px;
  top: 56px;
  overflow: auto;
  background-color: #F8F8F8;
  border-radius: 4px;
}



.logoBox {
  height: 55px;
  width: 240px;
  float: left;
  background-color: #545c64;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.logoBox img {
  width: 144px;
  display: block;
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.loginInfo {
  overflow: hidden;
  height: 56px;
  position: absolute;
  right: 20px;
  top: 0;
}

.loginInfo .headImg {
  width: 36px;
  float: left;
  margin-top: 10px;
}

.appHead .headCon {
  height: 56px;
  float: left;
  overflow: hidden;
}

.appHead .headCon .btnItem {
  float: left;
  margin-right: 20px;
}

.loginInfo .ddBox {
  float: left;
  margin-left: 16px;
  cursor: pointer;
  margin-top: 15px;
}

.appMain .maxpage {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
}

.el-menu {
  border-right: 0 !important;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .el-menu-item:hover{background-color: #FFFFFF!important;} */


.el-dialog {
  overflow: hidden;
}

.pgItem {
  margin-bottom: 20px;
}

.taskItemBox .el-dialog {
  overflow: hidden;
}

.hisNavBox {
  height: 55px;
  overflow: hidden;
  max-width: calc(100% - 500px);
}

.hisNavBoxIn {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 75px;
}

.hisNavItme {
  height: 32px;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  float: left;
  margin-left: 10px;
  margin-top: 11px;
  cursor: pointer;
  padding: 0 10px;
}

.hisNavItme .text {
  height: 32px;
  color: #333;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  float: left;
}

.hisNavItme .close {
  font-size: 20px;
  margin-top: 2px;
  color: #ADADAD;
  float: left;
  margin-left: 6px;
}

.hisNavItmeOn {
  border: 1px solid #409EFF;
}

.hisNavItmeOn .text {
  color: #409EFF;
}</style>
